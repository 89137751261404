export const BASE_URL = '/semu/api'
export const TIME_OUT = 60 * 1000
export const MAX_FILE_SIZE = 1024 * 1024
export const CONTEXT_PATH=''
export const SERVICE_NAME='관공서 서류민원 대행'

// event
export const SHOW_LOADING='SHOW_LOADING'
export const HIDE_LOADING='HIDE_LOADING'
export const SHOW_ALERT='SHOW_ALERT'
export const HIDE_ALERT='HIDE_ALERT'
export const SHOW_SNACKBAR='SHOW_SNACKBAR'
export const HIDE_SNACKBAR='HIDE_SNACKBAR'
