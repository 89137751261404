import { SET_ACTIVE_SIDE_INDEX } from "@redux/actions/activeSideIndexAction";
const initState = 0

const activeSideIndexReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_ACTIVE_SIDE_INDEX:
      return action.payload
    default:
      return state
  }
}

export default activeSideIndexReducer
