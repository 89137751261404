import * as React from 'react';
import { useMediaQuery } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
// redux
import { useSelector, useDispatch } from 'react-redux';
import CommonDrawer from '@components/CommonDrawer'
import EmptyDrawer from './EmptyDrawer';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: useTheme().spacing(5),
    display: "flex",
  },
  logo: {
    flex: 1,
    cursor: "pointer",
    color:'#fff',
    textDecoration: 'none',
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: useTheme().spacing(5),
    "&:hover": {
      color: "yellow",
      // borderBottom: "1px solid white",
    },
  },
  home: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    // marginLeft: theme.spacing(5),
    // "&:hover": {
    //   color: "yellow",
    // },
  },
}));

const drawerWidth = 240;

export default function PublicDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [pageTitle, set_pageTitle] = React.useState('')
  // redux
  const codeGroups = useSelector(state => state.codeGroupReducer)
  const codes = useSelector(state => state.codeReducer)
  const schools = useSelector(state => state.schoolReducer)
  const account = useSelector(state => state.accountReducer)
  const schola = useSelector(state => state.scholaReducer)

  React.useEffect(() => {
    if(window.location.href.includes('register/company')) {
      set_pageTitle('위탁업체 등록')
    }
    if(window.location.href.includes('register/user')) {
      set_pageTitle('회원 가입')
    }
  },[])

  return (
    <Outlet />
  );
}
