// import { SET_CODES } from "../actions/codeAction";
import { SET_CODES } from "@redux/actions/codeAction"
const initState = []

const codeReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_CODES:
      return action.payload
    default:
      return state
  }
}

export default codeReducer