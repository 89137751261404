import { SET_CODE_GROUPS} from "../actions/codeGroupAction";
const initState = []

const codeGroupReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_CODE_GROUPS:
      return action.payload
    default:
      return state
  }
}

export default codeGroupReducer