import axios from 'axios'
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'

import { FormControl, InputLabel, Input, FormHelperText, Alert, Paper } from '@mui/material'
import WebService from '@util/webService'
import { encodeParams, getAction, isStringNullOrEmpty, printVariable, searchCompany } from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux'
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton'
import useSnackbar from '@hooks/useSnackbar'
import MyTableHead from '@components/MyTableHead'
import useIndicator from '@hooks/useIndicator'
import Indicator from '@components/Indicator'
import MyDialog from '@components/MyDialog'
import Box from '@mui/material/Box'
import useDelegate from '@hooks/useDelegate'
// pagination
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Avatar, Grid, Container } from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { searchSchool } from '@util/helper'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery } from '@mui/material'
// import { navigate } from 'react-big-calendar/lib/utils/constants';
import { useNavigate } from 'react-router'
import { emailRegex, nameRegex, passwordRegex } from '@util/helper'
import useAlert from '@hooks/useAlert'
import ConfirmDialog from '@components/ConfirmDialog'
import { siteTitle } from '@util/string'
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined'
const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

const PublicFindIdPage = () => {
  const [name, set_name] = useState('')
  const [phone, set_phone] = useState('')
  const [sent, set_sent] = useState(false)
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const { alert, renderAlert } = useAlert()
  const navigate = useNavigate()
  const canSubmit = () => {
    return name && phone && name.length >= 2 && phone.length === 11 && !sent
  }

  const onSubmit = async () => {
    const url = encodeURI(`/public/find/loginId`)
    const data = {
      name: name,
      phone: phone,
    }

    const resp = await WebService.post(url, data)

    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    set_sent(true)
    alert('아이디(이메일)을 문자 발송하였습니다.')
  }

  const handleInputPhone = (event) => {
    const value = event.target.value
    // Remove any non-numeric characters from the input
    const numericValue = value.replace(/[^0-9]/g, '')
    event.target.value = numericValue
  }

  return (
    <Container component='main' maxWidth='sm'>
      <div style={{ marginTop: 100 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h5' align='center' style={{ fontWeight: 'bold' }}>
            {siteTitle} 아이디(이메일) 찾기
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper fullWidth elevation={10} sx={{ padding: 5, mt: 5, display: 'flex', alignItems: 'center' }}>
            <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Grid item xs={12}>
                <TextField
                  size='md'
                  required
                  fullWidth
                  label={'이름'}
                  name={'name'}
                  inputProps={{
                    maxLength: 30,
                  }}
                  value={name}
                  onChange={(e) => {
                    set_name(e.target.value.trim())
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  size='md'
                  required
                  fullWidth
                  type='text'
                  label={'휴대폰번호'}
                  name={'phone'}
                  inputProps={{
                    onInput: handleInputPhone,
                    maxLength: 11,
                  }}
                  onChange={(e) => {
                    set_phone(e.target.value.trim())
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <ConfirmDialog
            btn={
              <Button
                variant='contained'
                size='medium'
                fullWidth
                disabled={!canSubmit()}
                sx={{ mt: 2, fontSize: '1em' }}
                color={'primary'}
              >
                찾기
              </Button>
            }
            title={'알림'}
            content={`휴대폰:${phone} 으로 아이디(이메일)을 문자 전송합니다.`}
            bc={'primary'}
            preHandle={async () => {
              return true
            }}
            perform={() => {
              onSubmit()
            }}
          />
        </Grid>

      </Grid>

      <div>
        {renderIndicator()}
        {renderAlert()}
      </div>
    </Container>
  )
}

export default PublicFindIdPage
