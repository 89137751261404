import axios from "axios";
import {BASE_URL} from '@util/constant'
import { getAction } from "./helper";
import { isExpired, decodeToken } from "react-jwt";
import { setLoading } from "@context/LoadingContext";
import { CONTEXT_PATH } from "@util/constant";
import navigationService from "./navigationService";

axios.defaults.baseURL = BASE_URL
axios.defaults.withCredentials = false
axios.defaults.params = {}

const rfrAxiosInstance = axios.create({
  baseURL: BASE_URL,
})

rfrAxiosInstance.interceptors.response.use(
  (response) => {
    // Handle the successful token refresh response here
    // Update your token state with the new access token if needed
    return response;
  },
  (error) => {
    // Handle errors during token refresh here
    return Promise.reject(error);
  }
);


const deviceNetWorkErrorMessage = '단말의 네트워크 커넥션이 없습니다. WI-FI/통신망 연결 후 다시 시도 하세요.'
const checkNetwork = async() => {
  return true
}


const refreshAccessToken = async () => {
  console.log('refreshAccessToken...')
  const url = '/public/refresh'
  const oldDeviceId = localStorage.getItem('deviceId')
  const oldRefreshToken = localStorage.getItem('refreshToken')

  if(!oldRefreshToken) {
    return
  }
  if(!oldDeviceId) {
    return
  }
  const expired = isExpired(oldRefreshToken) 
  if(expired) {
    return
  }

  
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('deviceId')
  //
  const body = {
    refreshToken: oldRefreshToken,
    deviceId: oldDeviceId,
  }

  const resp = await rfrAxiosInstance.post(url, body)
  if(resp.data.repCode !== 'ack') {
    console.log('refresh failed', resp.data.repMessage) 
    return
  }

  const repMessage = JSON.parse(resp.data.repMessage)
  const accessToken = repMessage.accessToken
  localStorage.setItem('accessToken', accessToken )

  const refreshToken = repMessage.refreshToken
  localStorage.setItem('refreshToken', refreshToken)

  const deviceId = repMessage.deviceId
  localStorage.setItem('deviceId', deviceId)

  return accessToken
}


export default class WebService {
  static async post_not_use(action, data) {
    let response = await axios.post(action, data);
    return response.data;
  }

  /*
  static async post(action, params) {
    const response = await axios.post(action, null, {
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      params: params
    })
    return response.data
  }
  */

  static async post(action, data, params={}) {
    setLoading(true) 
    const response = await axios.post(action, data, {params:params})
    setLoading(false)
    return response.data
  }


  static async put(action, data, params={}) {
    setLoading(true) 
    let response = await axios.put(action, data, {params: params});
    setLoading(false)
    return response.data;
  }

  static async get(action, params={}) {
    const netOK = await checkNetwork()
    if(netOK) {
      setLoading(true)
      let response = await axios.get(action, {params: params});
      setLoading(false)
      return response.data;
    }
    else {
      const result = {}
      result.resultCode = '-1'
      result.resultMessage = deviceNetWorkErrorMessage
      return result
    }
  }

  static async delete(url, params={}) {
    let action = url
    if(Object.keys(params).length > 0) {
      action = getAction(url, params)
    }
    setLoading(true)
    let response = await axios.delete(action);
    setLoading(false)
    return response.data;
  }
  static async patch(action, data) {
    let response = await axios.patch(action, data);
    return response.data;
  }
}

axios.interceptors.request.use(
  async config => {
    config.baseURL = BASE_URL;
    // const token = await localStorage.getItem('token')
    const accessToken = localStorage.getItem('accessToken')
    
    config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : ''

    return config;
  },
  error => {
    console.log('axios error while intercepting....')
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  response => {
    setLoading(false)
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if(response.data.repCode === 'reject') {
      window.location.href = `/logout`
      return response
    }
    else {
      return response;
    }
  },
  async error => {
    setLoading(false)
    console.log('=== error happen ===')
    console.log(error)
    console.log('====================')

    const result = {
      data: {
        repCode: 'cnak',
        repMessage: '서버와 통신 중 에러가 발생했습니다.'
      }
    }

    if (error.response && error.response.status === 401) {
      try {
        const newAccessToken = await refreshAccessToken()
        if(!newAccessToken) {
          console.log('newAccessToken error')
          window.location.href = `/logout`
          return Promise.resolve(result)
        }
        else {
          axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

          // Retry the original request
          const originalRequest = error.config;
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        }
      }
      catch(error) {
        // window.location.href = `/logout`
        navigationService.navigate('/logout')
        return Promise.resolve(result)
      }
    }
    else {
      return Promise.resolve(result)
    }
  },
);
