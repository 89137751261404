import * as React from 'react';
import {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { Divider, IconButton, Select, Typography, FormControl, InputLabel, MenuItem} from '@mui/material';
import { printVariable } from '@util/helper';
import WebService from '@util/webService';
import useSnackbar from '@hooks/useSnackbar';
import useIndicator from '@hooks/useIndicator';
import axios from 'axios';
import { Fragment } from 'react';
import moment from 'moment';
import useDelegate from "@hooks/useDelegate";
import useAlert from "@hooks/useAlert";
import RegisterPage from '@pages/RegisterPage';
import parse from "html-react-parser";


const ProfileAgmtPartView = ({record, dialogHandler, callback}) =>  {
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const {alert, renderAlert} = useAlert()
  const [content, set_content] = useState('')

  // redux
  const account = useSelector(state => state.accountReducer);
  const schola = useSelector(state => state.scholaReducer);
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';

  const handleClose = (event, reason)=> {
    if(reason && reason === "backdropClick") {
      return 
    }
    else {
      dialogHandler[1](false)
      if(callback) {
        callback()
      }
    }
  }

  const fetchAgmt = async() => {
    const url = `/public/agmt/${record.id}` 
    openIndicator()
    const resp = await WebService.get(url)
    closeIndicator()
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    const repMessage = JSON.parse(resp.repMessage)
    const content = repMessage.content
    return content
  }

  const onLoad =  async() => {
    const content = await fetchAgmt()
    if(content) {
      set_content(content)
    }
  }

  const validate = async() => {}
  const onSubmit = async() => {}
  const {activeRow, errors, updateState, handleChange, handleSubmit} = useDelegate({
    initalValues: { ...record },
    onSubmit: onSubmit,
    validate: validate,
  });

  useEffect(() => {
    if(dialogHandler[0]) {
      onLoad()
    }
  },[dialogHandler[0]])


  return (
    <Fragment>
      <Dialog record={record} open={dialogHandler[0]} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle>
          <Typography variant='h5' align='center'>
            {record.name}
          </Typography>
          <Divider></Divider>
        </DialogTitle>
        <DialogContent>
          {parse(content)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {renderSnackbar()}
      {renderIndicator()}
      {renderAlert()}
    </Fragment>
  )
}

export default ProfileAgmtPartView
