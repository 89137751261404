export const companyPhone = '010-8179-7941'
export const companyName = '민원인터넷발급'
export const companyEmail = 'e-minwon@naver.com'
export const companyCeo = '손미희'
export const companyNum = '690-46-01187'
export const companyAddr = '경기도 김포시 양촌읍 양곡로 495 3층 305-LV38호'
export const companyFax = '02-6280-7942'
export const companyMosan = '2024-경기김포-3285'
export const siteTitle = '민원인터넷발급'
export const billiard = '※'
export const termsOfService = `
제1조(목적)

이 약관은 민원인터넷발급(이하 본사이트)와 이용고객간의 민원서류대행서비스의 이용에 관한 기본적인 사항을 정하여
상호간의 원활한 거래를 도모함을 목적으로 합니다. 

제2조(효력 및 변경)
① 이 약관은 본사이트의 홈페이지에 게시함으로서 그 효력을 발생합니다.
② 본사이트는 필요에 의해 이 약관을 변경할 수 있으며, 약관이 변경된 후에는 지체없이 홈페이지에 변경하여 등록합니다.

제3조(약관의적용)
① 본사이트는 민원서류대행서비스의 이용에 관하여 이 약관을 적용합니다.
② 이 약관에 명시되지 않은 사항에 관하여는 본사이트 하단의 홈페이지 하단 약관을 적용합니다.

제4조 (신청 및 발급대행)
서비스의 이용을 원하시는 고객은 본사이트의 홈페이지에서 열람, 다운, 메일, 팩스, 우편등기 등의 방법으로 신청하며,
인지료가 포함된 수수료를 결제하면 발급대행서비스가 진행됩니다.
① 본사이트는 관련법령에 저촉됨이 없는 민원서류만을 대행 서비스합니다.
② 본사이트는 불법적인 의도로 신청된 사항은 서비스를 거부할 수 있습니다.
③ 본사이트에 신청하신 서류는 고객이 발급서비스를 위임한것으로서, 필요한 경우 위임장을 요청할 수도 있습니다.
④ 본사이트의 업무시간은 홈페이지에 게재되어있으며, 회사의 필요에 따라 시간을 축소하거나 연장할 수 있습니다.
⑤ 일부서류는 관공서근무시간 이후 혹은 공휴일에 장애로 인하여 발급이 지연되는 경우는 익일영업일에 처리됩니다. 

제5조(책임공지)
① 본사이트의 민원서류대행서비스에 관하여 배송완료 후 민원서류로 인하여 발생하는 모든 책임은 이용자에게 있습니다.
② 본사이트에서 발급대행서비스하는 서류는 열람용(사본)과 제출용이 있으며, 열람용(사본)은 확인용이며, 법적효력이
   없습니다. 열람용(사본), 제출용 모두 서류발급을 대행하는것으로서 이후 어떠한 법적책임도 지지 않습니다.
③ 본사이트의 민원서류대행서비스는 기상, 교통, 배송지의 상황, 온라인장애등의 사유로 인하여 지연되거나,
   우편물분실/파손등이 있을 수 있으며, 이와 관련하여 재발급 하여 드리는것 외에는 어떠한 법적책임도 지지 않습니다.

제6조(환급기준 및 방법)
① 본사이트에서 신청하신 민원서류의 환급취소는 [접수] 단계에서만 가능하며, 반드시 전화로 신청하셔야합니다.
② 본사이트에서 신청하신 서류가 처리중인 상태는 서류가 이미 확보되어 업로드중인 관계로 환급취소가 되지않으며,
   팩스, 메일은 처리완료 후 확인되지 않으면 반드시 바로 재전송요청 하셔야 합니다
③ 본사이트에서 신청하신 서류가 처리완료 상태는 열람다운 유무와 관계없이 환급취소가 되지 않습니다.
④ 부정확한 정보, 건물이 없거나, 미등기 상태는 신청하지 마시기 바랍니다. 환급취소가 되지 않음을 원칙으로 합니다.
   다만 부정확한 정보는 내역을 수정하여 주시면 다시 재발급 진행됩니다. 유효기간은 1개월 1회에 한합니다.
⑤ 본사이트의 업무시간 이후 신청건은 익일영업일 접수 순서대로 처리되며,  업무시간 이후 처리가 되지않는 접수상태의
   환급취소는 온라인고객센터 묻고답하기에서 또는 문자로 내역을 남겨주시면 익일영업일에 처리하여 드립니다.
⑥ 본사이트의 환급취소는 결제수단이 신용카드, 계좌이체, 휴대폰 결제건은 결제승인취소하며, 그외의 결제수단은
   본사이트에서 이용하는 은행으로만 환급됩니다. 다른 은행권은  은행수수료를 공제하고 환급됩니다.
`

export const providingInfo = `
회사는 서비스신청, 상담, 회원가입 등을 위하여 아래와 같이 개인정보를 수집하고 있습니다. 
1) 개인정보의 수집이용 목적
  당사는 다음의 목적을 위하여 개인정보를 수집 및 이용합니다.
  수집된 개인정보는 정해진 목적 이외의 용도로는 이용되지 않으며, 수집목적이 변경될 경우 사전에 알리고 동의를 받을 예정입니다.
2) 수집하는 개인정보의 항목 
   회사는 서비스신청 고객상담 등을 위하여 아래와 같은 개인정보를 수집합니다. 
 - 필수항목: 성명, 휴대폰, 이메일, 비밀번호, 회원가입시 아이디, 
 - 선택항목: 일반전화번호, 팩스번호, 우편물발송주소, 환불시 계좌정보 
 -자동생성정보: 서비스이용기록접속로그, 접속아이피정보, 결제기록, 이용정지기록 등이 수집될 수 있습니다. 
3) 개인정보 수집방법 
   회사는 다음과 같은 방법으로 개인정보를 수집합니다. 
 - 홈페이지 서면양식을 통한 신청서 
 - 전화, 팩스, 고객상담 게시판을 통한 서비스 이용관리 
4) 개인정보의 처리목적 
 - 서비스제공에 관한 계약이행 및 요금정산, 서비스 및 콘덴츠제공 물품배송 또는 청구서 발송 등, 본인인증, 구매 및 
   요금결제, 요금추심. 
5) 개인정보의 보유 및 파기 
   원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 파기합니다. 다만 다른법령에 따라 보존하여야 
   하는경우에는 그러하지 않을 수 있습니다. 이용자가 입력한 정보는 회원탈퇴 등 목적달성 후 내부방침 및 기타 관련 
   법령에 따라 저장된 후 파기됩니다. 
 ○ 회사 내부방침에 의한 정보보유 
 - 서비스이용의 혼선방지, 분쟁해결 및 수사기관의 요청에 따른 협조: 1년 
 ○ 관련 법령에 의한 정보보유 
 - 계약 또는 청약철회 등에 관한기록: 5년 
 - 대금결제 및 재화 등의 공급에 관한기록: 5년 
 - 소비자 불만 및 분쟁처리에 관한기록: 3년 
 - 로그기록: 통신비밀보호법: 3개월 
6) 동의를 거부 할 권리 
   누구나 동의거부 의사를 표시할 수 있으며 동의 거부시 고객으로서 기본활동을 위한 정보부족으로 서비스 제공을 할 수 
   없으며, 이에 따라 회원가입 및 홈페이지를 이용한 민원서비스가 제한됩니다.
`

export const termsOfPrivate = `
(수집항목) 성명, 상호, 주소(사업장), 전화번호 (수집목적) 증명발급, 사후관리 등 (보유기간) 5년
위와 같이 개인정보 수집 및 이용한데 동의하십니까?
`
export const termsOfId = `
고유식별정보 수집 이용 내역(개인정보보호법 제24조)
(수집항목) 주민등록번호, 외국인등록번호, 운전면허번호, 여권번호 (수집목적) 증명발급, 사후관리 등 (보유기간) 5년
위와 같이 개인정보 수집 이용하는데 동의하십니까?
`
export const termsOf14 = `
신청인이 만 14세 미만 아동인 경우 위와 같이 개인 정보 및 고유식별정보를 처리하는데 동의하십니까?(개인정보보호법 제22조)
`
export const termsOfAttroney = `
위 납세자는 아래 위임받은 사람에게 위 민원의 신청 및 수령에 관한 권리와 의무를 위임합니다.
`
export const termsOfPunish = `
다른 사람의 인장 동용 등 허위로 위임장을 작성하여 신청할 경우에는 형법 제231조와 제232조에 따라 사문서 위변조 죄로 
5년 이하의 징역 또는 1천만원 이하의 벌금에 처하게 됩니다.
`

export const attrGuide = `
※ 위임자가 법인 경우에는 대표이사 등의 신분증(사본) 또는 법인인감증명서(사용인감 가능)(사본), 그 외의 경우에는 위임자 본인의 신분증(사본)(본이이 희망하는 경우에는 인감증명서, 본인서명사실확인서, 전자본인서명확인서 가능(사본포함))
※ 인감증명서 등(사본 포함)은 3개월 이내에 발급된 것이어야 합니다.
`

export const prss = {
  am: `당일 PM 14~15시`,
  pm: `익일 AM 10~12시(영업일)`,
  short: `10~30분`,
  long: `10분~4시간`,
}

export const refund = `
·서류처리 순서는 접수 처리중 처리완료 단계로 진행됩니다.
·취소 및 환급은 접수 단계에서만 가능하며, 일처리가 진행된 처리중 이후는 취소및 환급되지 않습니다.
·고객님이 작성한 신청서 기재오류로 처리불가 시에는 취소 및 환급되지 않으며, 처리불가 된 내역은 수정하여 주시면 1회에 한하여 재발급 진행됩니다.
·내용을 확인하신 후 타당하다고 판단하시면 반드시 전화로 신청바랍니다. 전화로 신청하지 않은 건은 환급(취소)되지 않습니다.
·근무시간 이후 신청건은 전화주시지 않아도 됩니다. 하단 결제승인취소를 기재하여 신청하십시오.
`
