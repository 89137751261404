import React, {useState, useEffect, useMemo, Fragment, useRef} from 'react';
import {Grid, Typography, Button, Container, useMediaQuery, useTheme } from '@mui/material'
import {GlobalStyle as gs}  from '@src/GlobalStyle'
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import {companyPhone} from '@util/string'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
const CustomerTermsPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return(
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
          paddingBottom: 50,
          minHeight:'75vh'
        }}
      >
        <Container>
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid item xs={12} style={{backgroundColor:gs.colors.cardBg, color:'#000', alignItems:'center', paddingBottom:16}} >
              <Typography >
                준비 중입니다.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        
      </div>
    </>
  )
}

export default CustomerTermsPage
