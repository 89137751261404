import React, { useState, useEffect, useMemo, Fragment, useRef, forwardRef, useImperativeHandle } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
} from '@mui/material'
import DaumPostcodeEmbed, { useDaumPostcodePopup } from 'react-daum-postcode'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Agmt from '@components/Agmt'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import Labeller from '@components/Labeller'
import { alignProperty } from '@mui/material/styles/cssUtils'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import MinwonInput from '@components/MinwonInput'
import AgmtOffice from '@components/AgmtOffice'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { take, takeRight, drop } from 'lodash'
import { showSnackbar } from '@context/SnackbarContext'
import moment from 'moment'
import { description } from '../description'
import RestPartEdit from './rest.part.edit'
import { useSelector, useDispatch } from 'react-redux'
import { createDoc } from '@pages/common/common'
import WebService from '@util/webService'
const alignCenter = {
  display: 'flex',
  alignItems: 'center',
}

const RestPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const agmtRef = useRef()
  const officeRef = useRef()
  const { docCode } = useParams()
  const location = useLocation()
  const account = useSelector((state) => state.accountReducer)
  console.log('account=>', account)
  const doc = location.state?.pack?.data ?? createDoc(docCode, account)
  const curLocationRef = useRef(location)
  const [compKey, set_compKey] = useState(location.key)
  const [allChecked, set_allChecked] = useState(false)
  const [agmtUpdated, set_agmtUpdated] = useState(false)
  const [officeUpdated, set_officeUpdated] = useState(false)
  const navigate = useNavigate()
  // const [record, set_record] = useState(createDoc(docCode) )
  console.log('======== doc ======>', doc)
  const [record, set_record] = useState(doc)

  const checkForAll = () => {
    if (agmtRef.current.isAllChecked()) {
      set_allChecked(true)
    } else {
      set_allChecked(false)
    }
  }

  const validateAddr = () => {
    if (!record.addr1) {
      showSnackbar('기본주소 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (!record.addr2) {
      showSnackbar('상세주소 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    return true
  }

  const validate = () => {
    if (!record.phoneDial) {
      showSnackbar('전화번호 국번 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (record.phone1.length < 4) {
      showSnackbar('전화번호 앞자리를 정확히 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (record.phone2.length < 4) {
      showSnackbar('전화번호 뒷자리를 정확히 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    record.phone = `${record.phoneDial}${record.phone1}${record.phone2}`

    if (docCode === 'DG01' || docCode == 'DG11') {
      if (!record.estCode) {
        showSnackbar('부동산구분 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }

      if (!validateAddr()) {
        return
      }

      if (!record.effYn) {
        showSnackbar('등기유형 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
    } else if (docCode === 'DG02') {
      if (!record.corpCode) {
        showSnackbar('법인구분 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!record.companyName) {
        showSnackbar('법인상호 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!validateAddr()) {
        return
      }
      if (!record.effYn) {
        showSnackbar('등기유형 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
    } else if (docCode === 'DG03') {
      if (!record.dajiYn) {
        showSnackbar('토지구분 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!record.historyYn) {
        showSnackbar('연혁유무 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!validateAddr()) {
        return
      }
    } else if (docCode === 'DG04') {
      if (!record.bldgOneYn) {
        showSnackbar('건물구분 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!record.bldgChongYn) {
        showSnackbar('건물종류 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!validateAddr()) {
        return
      }
    } else if (docCode === 'DG05') {
      if (!validateAddr()) {
        return
      }
    } else if (docCode === 'DG06') {
      if (!validateAddr()) {
        return
      }
    } else if (docCode === 'DG07') {
      if (!validateAddr()) {
        return
      }
    } else if (docCode === 'DG08') {
      if (!record.houseOneYn) {
        showSnackbar('확인서구분 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!validateAddr()) {
        return
      }
    } else if (docCode === 'DG09') {
      if (!record.carNum) {
        showSnackbar('차량등록번호 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!record.lastOwner) {
        showSnackbar('최종소유주 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!record.carCode) {
        showSnackbar('권리구분 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
    }

    if (!record.giveYn) {
      showSnackbar('열람/제출용 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (!record.qty || record.qty <= 0) {
      showSnackbar('수량 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (!allChecked) {
      showSnackbar('모든 약관에 동의해야 서비스 이용이 가능합니다.', theme.palette.warning.dark)
      return
    }

    return record
    //
  }

  const onSubmit = async () => {
    const ret = validate()
    if (!ret) {
      return
    }

    if (!account?.user?.id) {
      navigate('/login', {
        state: {
          doc: record,
          pack: {
            url:window.location.pathname,
            data: record,
            furl: '/order'
          } 
        },
      })
    } else {
      navigate('/order', {
        state: {
          docs: [ret],
          pack: {
            url: window.location.pathname,
            data: ret,
          },
        },
      })
    }
  }

  const onBasket = async () => {
    const ret = validate()
    if (!ret) {
      return
    }
    
    const url = '/basket'
    const data = {
      docs: [ret]
    }
    const resp = await WebService.post(url, data)
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    showSnackbar('민원바구니에 저장되었습니다.' )
  }

  useEffect(() => {
    if (curLocationRef.current.key != location.key) {
      curLocationRef.current = location
      set_compKey(location.key)
      set_record(createDoc(docCode, account))
      window.scrollTo(0, 0)
    }
  }, [location])

  useEffect(() => {
    console.log('updated...')
    if (agmtUpdated || officeUpdated) {
      if (agmtRef.current.isAllChecked() && officeRef.current.isAllChecked()) {
        set_allChecked(true)
      } else {
        set_allChecked(false)
      }
    }
  }, [agmtUpdated, officeUpdated])

  return (
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
        }}
      >
        <Container>
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
            >
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {description.find((r) => r.id === docCode)?.content}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography style={{ fontWeight: 'bold' }}>{description.find((r) => r.id === docCode)?.title}</Typography>
            </Grid>
            <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <Button startIcon={<AddOutlinedIcon />} variant='contained'> */}
              {/*   추가 */}
              {/* </Button> */}
            </Grid>
          </Grid>
        </Container>
        <Divider style={{ margin: 10 }} />
        <RestPartEdit key={compKey} record={record} set_record={set_record} docCode={docCode} />
      </div>

      <Container style={{ marginTop: 30 }}>
        <FormControlLabel
          label={<Typography style={{ fontWeight: 'bold' }}>아래 모든 약관에 동의합니다.</Typography>}
          control={
            <Checkbox
              checked={allChecked}
              color='primary'
              onChange={(e) => {
                agmtRef.current.toggle(e.target.checked)
                set_allChecked(e.target.checked)
              }}
            />
          }
        />
      </Container>

      <Container style={{ marginTop: 30 }}>
        <Agmt ref={agmtRef} checkForAll={checkForAll} key={compKey} />
      </Container>

      <Container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button variant='contained' style={{ margin: 5 }} onClick={onSubmit}>
          주문하기 
        </Button>
        <Button variant='contained' style={{ margin: 5 }}
          disabled={!account?.user?.id  || account?.user?.statCode === 'N001' }
          onClick={onBasket}
        >
          민원바구니 담기
        </Button>
      </Container>
    </>
  )
}

export default RestPage
