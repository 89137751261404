import { useState, useEffect, Fragment, useRef } from "react";
import { useSelector } from "react-redux";


const useAmount = (record ) => {
  const codes = useSelector((state) => state.codeReducer)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const bizCodes = codes.filter((r) => r.codeGroupId === 'D004')
  const estCodes = codes.filter((r) => r.codeGroupId === 'D006')
  const [price, set_price] = useState(record.price)
  const [amount, set_amount] = useState(record.amount)
  const getPrice = (code, giveYn) => {
    let price = 0
    if(code && giveYn) {
      if(giveYn === 'Y') {
        price = code.note2
      }
      else if(giveYn === 'N') {
        price = code.note1
      }
    }
    return  price
  }

  useEffect(() => {
    let code
    if(record.docCode === 'DS07') {
      code =  bizCodes.find(r => r.code === record.bizCode)
    }

    else if(record.docCode === 'DG01') {
      code =  estCodes.find(r => r.code === record.estCode)
    }

    else {
      code = docCodes.find(r => r.code === record.docCode)
    }

    const price = getPrice(code, record.giveYn) 
    const amount = price * record.qty

    record.price = price
    record.amount = amount

    set_price(price)
    set_amount(amount)

  }, [record.docCode, record.price, record.qty, record.bizCode, record.estCode])
  
  return {price: price, amount: amount}
}

export default useAmount
