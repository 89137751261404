import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined'
import MyDialog2 from '@components/MyDialog2'
import { isAdmin } from '@util/helper'

const boldFont = {
  fontWeight: 'bold',
}

const AskDownloader = ({ doc }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()

  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const { alert, renderAlert } = useAlert()

  const downloadTmpl = async (docCode) => {
    const url = `/doc/download/tmpl/${docCode}`
    const response = await axios.get(url, { responseType: 'arraybuffer' })
    const buffer = response.data
    return buffer
  }

  const onDownload = async () => {
    const buffer = await downloadTmpl(doc.docCode)
    const workbook = new ExcelJS.Workbook()
    await workbook.xlsx.load(new Uint8Array(buffer))

    const url = `/doc/data/map/${doc.id}`
    const resp = await WebService.get(url)
    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    const repMessage = JSON.parse(resp.repMessage)
    const dataMap = JSON.parse(repMessage.dataMap)
    const signature = repMessage.signature
    // const sigImage = dataUrlToFile(signature, 'signature')

    console.log('dataMap=>', dataMap)
    const imageId1 = workbook.addImage({
      base64: signature,
      extension: 'png',
    })
    const imageId2 = workbook.addImage({
      base64: signature,
      extension: 'png',
    })

    workbook.eachSheet((worksheet, sheetId) => {
      if (doc.docCode == 'DS10') {
        worksheet.addImage(imageId1, 'Z25:AE26')
        worksheet.addImage(imageId2, 'Y32:AE34')
      } else if (doc.docCode == 'DS11') {
        worksheet.addImage(imageId1, 'BW66:CG69')
        worksheet.addImage(imageId2, 'BW97:CF97')
      } else {
        worksheet.addImage(imageId1, 'AV42:BF42')
        worksheet.addImage(imageId2, 'AV68:BE68')
      }
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell((cell, colNumber) => {
          if (cell.value && typeof cell.value === 'string') {
            if (cell.value.includes('x_')) {
              const oldValue = cell.value
              const newValue = cell.value.split(' ').reduce((acc, cur) => {
                const key = cur.trim()
                if (key.startsWith('x_')) {
                  const v = dataMap[key]
                  if (v === undefined) {
                    console.log(`key:${key} not found`)
                  }

                  return acc + ' ' + dataMap[key] ?? ''
                } else {
                  return acc + ' ' + key
                }
              }, '')
              // console.log('oldValue=>', oldValue)
              // console.log('newValue=>', newValue)
              cell.value = newValue
            }
          }
        })
      })
    })

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const docName = docCodes.find((r) => r.code === doc.docCode).name
      let filename = `${doc.name}_${docName}.xlsx`
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  return isAdmin(account) ? (
    <Button variant='contained' startIcon=<FileDownloadOutlined /> onClick={() => onDownload()}>
      신청문서 다운로드
    </Button>
  ) : null
}

export default AskDownloader
