import { useState, useEffect, Fragment, useRef} from "react";
import { Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Indicator from "../components/Indicator";
import { useLoading } from "@context/LoadingContext";
import {publish} from '@util/event'
import { SHOW_LOADING, HIDE_LOADING } from "@util/constant";
const useIndicator = () => {

  const openIndicator = () => {
    publish(SHOW_LOADING)
  }

  const closeIndicator = () =>{
    publish(HIDE_LOADING)
  }

  const renderIndicator = () => {
    return(
      <></>
    )
  }
  
  return [openIndicator, closeIndicator, renderIndicator]
}

export default useIndicator
