import axios from 'axios';
import React, {useState, useEffect, useMemo, Fragment, useRef} from 'react';

import { FormControl, InputLabel, Input, FormHelperText, Alert } from '@mui/material';
import WebService from '../util/webService'
import {encodeParams, getAction} from '../util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux';
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Checkbox, FormGroup, FormControlLabel, Typography, Snackbar, TablePagination, TextField,withStyles,InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '../components/FlatButton';
import useSnackbar from '../hooks/useSnackbar';
import MyTableHead from '../components/MyTableHead';
import useIndicator from '../hooks/useIndicator';
import Indicator from '../components/Indicator';
import MyDialog from '../components/MyDialog';
import useForm from '../hooks/useForm';
import Box from '@mui/material/Box';

// pagination
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { searchSchool, searchCompany } from '../util/helper';
import useAlert from "@hooks/useAlert";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  Avatar,
  Grid,
  Container,
} from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ConfirmDialog from "@components/ConfirmDialog";

const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const Boxs = styled(Box)`
      padding-bottom: 40px !important;
    `;


const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;

const UserPage = () => {
  const fields = [
    {field: 'id', placeholder: 'ID',    type:'text', value: -1, visible: true, canSrch: true, selList:[]},
    {field: 'name', placeholder: '이름',    type:'text', value:'', visible: true, canSrch: true, selList:[]},
    {field: 'statCode', placeholder: '',    type:'text', value: 'S001', visible: false, canSrch: false,selList:[]},
    {field: 'phone', placeholder: '전화', type:'number', value: '', visible: true, canSrch: true, selList:[]},
    {field: 'loginId', placeholder: '로그인ID', type:'text', value: '', visible: true, canSrch: false, selList:[]},
    {field: 'password', placeholder: '비밀번호', type:'text', value: '1', visible: false, canSrch: false, selList:[]},
    {field: 'phoneCertYn', placeholder: '폰인증', type:'text', value: 'N', visible: false , canSrch: false, selList:[]},
    {field: 'emailCertYn', placeholder: '이메일인증', type:'text', value: 'N', visible: true, canSrch: false, selList:[]},
    {field: 'deviceToken', placeholder: '디바이스토큰', type:'text', value: '', visible: false, canSrch: false, selList:[]},
    {field: 'email', placeholder: '이메일', type:'text', value:'', visible: true, canSrch: false, selList:[]},
    {field: 'address', placeholder: '주소', type:'text', value:'', visible: false, canSrch: false, selList:[]},
    {field: 'address2', placeholder: '주소', type:'text', value:'', visible: false, canSrch: false, selList:[]},
    {field: 'zipCode', placeholder: '주소', type:'text', value:'', visible: false, canSrch: false, selList:[]},
    {field: 'regTime', placeholder: '등록일시', type:'text', value:'', visible: false, canSrch: false, selList:[]},
    {field: 'uptTime', placeholder: '수정일시', type:'text', value:'', visible: true, canSrch: false, selList:[]},
  ]

  const visibleColumns = fields.filter(col => col.visible)
  const defRecord = {}
  fields.forEach(d => {
    defRecord[d.field] = d.value
  })
  defRecord.schoolList = []  
  defRecord.companyList = []
  const defTerms = {}
  fields.filter(col => col.canSrch).map(d => d.field).forEach(d => defTerms[d] = '')
  const [record, set_record] = useState(defRecord)
  const [dataList, set_dataList] = useState(null)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const dgHandler = useState(false)
  const [selIdx, set_selIdx] = useState(-1)
  const [pageNum, set_pageNum] = useState(0)
  const [pageSize, set_pageSize] = useState(10)
  const [totalCount, set_totalCount] = useState(0)
  const [pageInfo, set_pageInfo] = useState({pageNum:0, pageSize: 10})
  const termsRef = useRef({})
  const [companyList, set_companyList] = useState([])
  const [companies, set_companies] = useState([])
  const [companySchoolRltnList, set_companySchoolRltnList] = useState([])
  const buttonRef = useRef(null)
  const location = useLocation()
  const curLocationRef = useRef(location)
  // redux
  const codeGroups = useSelector(state => state.codeGroupReducer)
  const codes = useSelector(state => state.codeReducer)
  const schools = useSelector(state => state.schoolReducer)
  const {alert, renderAlert} = useAlert()
  const searchRefMap = useMemo(() => [], [location])

  fields.filter(r => r.canSrch).forEach(f => {
    searchRefMap[f.field] = useRef()
  })

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      buttonRef.current.click();
    }
  };


  const fetchUsers = async() => {
    const url = `/user`
    openIndicator()
    const resp = await WebService.get(url, {pageNum:pageInfo.pageNum, pageSize:pageInfo.pageSize, terms:JSON.stringify(termsRef.current)})
    closeIndicator()

    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    const totalCount = repMessage.totalCount
    set_totalCount(totalCount)
    records.forEach(r => {
      r.isDetail = false
      r.schoolRltnList = []
      r.companyRltnList = []
    })
    return records
  }

  const fetchCompanies = async() => {
    const url = '/company'
    openIndicator()
    const resp = await WebService.get(url)
    closeIndicator()
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    return records
  }

  const fetchRltn = async() => {
    const url = '/common/company/school/rltn'
    openIndicator()
    const resp = await WebService.get(url)
    closeIndicator()

    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    return records
  }

  const onLoad = async() => {

    const users = await fetchUsers()
    if(users) {
      set_dataList(users)
    }
    
  }


  const findCompany = (schoolId) => {
    const rltn = companySchoolRltnList.find(it => +it.schoolId === +schoolId)
    if(rltn) {
      const company = companies.find(it => +it.id === +rltn.companyId)
      if(company) {
        return company
      }
    }
    return undefined
  }
  
  const onDetail = async(id) => {
    const newDataList = [...dataList]
    const user = newDataList.find(d => d.id === id)

    if(!user.isDetail) { 
      const url = `user/rltn`
      const resp = await WebService.get(url, {userId: id})
      
      if(resp.repCode === 'ack') {
        const repMessage = JSON.parse(resp.repMessage)
        const schoolRltnList = JSON.parse(repMessage.schoolRltnList)
        const companyRltnList = JSON.parse(repMessage.companyRltnList)
        user.schoolRltnList = schoolRltnList
        user.companyRltnList = companyRltnList

        // schoolRltnList.forEach(r => {
        //   user.schoolRltnList.push(r)
        // })
      }
    }

    user.isDetail = !user.isDetail 
    set_dataList(newDataList)
    dgHandler[1](true)
  }

  const onLeaveRequest= async(id) => {
    const url = `/user/leave/${id}`
    openIndicator()
    const resp = await WebService.post(url)
    closeIndicator() 
    if(resp.repCode === 'ack') {
      onLoad()
      openSnackbar('처리되었습니다.')
    }
    else {
      alert(resp.repMessage)
    }
  }
  
  const onCreate = () => {
    const now = moment().format(dateFormat)
    const newRecord = {...defRecord, regTime: now, uptTime: now, schoolRltnList:[], companyRltnList:[]}
    set_record(newRecord)
    dgHandler[1](true)
  }

  const onSearch = () => {
    set_pageInfo({ pageNum: 0, pageSize: 10 })
  }

  const onClear = () => {
    termsRef.current = {}
  }

  useEffect(() => {
    onLoad()
  }, [pageInfo])

  useEffect(() => {
    if(curLocationRef.current.key !== location.key) {
      Object.keys(searchRefMap).forEach(k => {
        if(searchRefMap[k].current) {
          searchRefMap[k].current.value = ''
          searchRefMap[k].current.checked = false
        }
      })
      termsRef.current = {}
      set_pageInfo({ pageNum: 0, pageSize: 10 })
      curLocationRef.current = location
    }
  }, [location])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    set_pageInfo({...pageInfo, pageNum: newPage })
  }
  
  const handleChangeRowsPerPage = (event) => {
    
    const pageSize = parseInt(event.target.value, 10)
    set_pageInfo({pageNum:0, pageSize: pageSize})
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  

  const DgContent = () => {
    const onSubmit = async () => {
      let count = activeRow.schoolRltnList.reduce((acc, cur) => cur.schoolId === -1 ? ++acc: acc, 0)
      if(count > 0) {
        alert('학교를 선택하지 않은 데이터가 있습니다.')
        return
      }

      count = activeRow.schoolRltnList.reduce((acc, cur) => cur.rltnCode === undefined ? ++acc: acc, 0)
      if(count > 0) {
        alert('학교가 선택되었으나 관계가 설정되지 않은 데이터가 있습니다.')
        return
      }

      count = activeRow.companyRltnList.reduce((acc, cur) => cur.rltnCode === undefined ? ++acc: acc, 0)
      if(count > 0) {
        alert('위탁업체와 관계가 설정되지 않은 데이터가 있습니다.')
        return
      }

      const url = `/user/school/rltn2`
      const data = {
        user: JSON.stringify(activeRow),
        schoolRltnList: JSON.stringify(activeRow.schoolRltnList),
        companyRltnList: JSON.stringify(activeRow.companyRltnList)
      }
      const resp = await WebService.post(url, data)
      if(resp.repCode === 'ack') {

        const repMessage = JSON.parse(resp.repMessage)
        const newRecord = JSON.parse(repMessage.record)
        
        if(newRecord.id !== undefined) {
          // const event = {target:{name:'id', value:newRecord.id}}
          // handleChange(event)
          activeRow.id = newRecord.idschoolRltnList
          onLoad()
          handleChange()
          
          openSnackbar('저장되었습니다.')
        }
        else {
           openSnackbar('에러 발생')
        }

        // onLoad()
        // handleChange()
        
      }
      else {
        openSnackbar('에러 발생')
      }
    }

    const validate = () => {}
    const [
      activeRow,
      errors,
      formLoading,
      handleChange,
      handleSubmit, 
     ] = useForm({
      initialValues: {...record}, 
      onSubmit: onSubmit,
      validate: validate,
    });
    const [emailError, setEmailError] = useState('');
    const [passwordState, setPasswordState] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [nameError, setNameError] = useState('');
    const [registerError, setRegisterError] = useState('');
    const [schoolList, set_schoolList] = useState([])
    const [companyList, set_companyList] = useState([])
    const [schoolContainer, set_schoolContainer] = useState([])
    
    // const Boxs = styled(Box)`
    //   padding-bottom: 40px !important;
    // `;
    

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const schoolGroup = record.schoolRltnList.reduce((acc, cur) => {
      if(acc[cur.schoolId]) {
        acc[cur.schoolId].push(cur)
      }
      else {
        acc[cur.schoolId] = [cur]
      }
      return acc
    }, {})

    const companyGroup = record.companyRltnList.reduce((acc, cur) => {
      if(acc[cur.companyId]) {
        acc[cur.companyId].push(cur)
      }
      else {
        acc[cur.companyId] = [cur]
      }
      return acc
    }, {})


    // const filterOptions = createFilterOptions({
    //   matchFrom: 'name',
    //   limit: 10,
    // });

    const filterOptions = (options, state) => {
      let newOptions = []
      newOptions = options.filter(o => o.name.includes(state.inputValue)).slice(0, 10)
      return newOptions;
    };
  
    return (
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography component="h1" variant="h5">
            기본정보
          </Typography>
          <Boxs component="form" noValidate sx={{ mt: 3 }}>
            <FormControl component="fieldset" variant="standard" fullWidth>
              <Grid container spacing={2}>
                {
                  fields.map((col, index) => {
                    if(col.visible) {
                      return(
                        <Grid item xs={3} key={col.field}>
                          <TextField
                            size='small'
                            required
                            fullWidth
                            name={col.field}
                            label={col.placeholder}
                            value={activeRow[col.field]}
                            onChange={handleChange}
                          />
                        </Grid>
                      )
                    }
                  })
                }
              </Grid>
            </FormControl>
          <FormHelperTexts>{registerError}</FormHelperTexts>
        </Boxs>

        {/* 관계*/}
        <div className='row-drcn' style={{alignItems:'center', marginTop:20}}>
          <Typography component="h1" variant="h5">
            회원사와의 관계
          </Typography>
        </div>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            options={companyList}
            filterOptions={filterOptions}
            onInputChange={async (event, value, reason) => {
              if(reason === 'input') {
                const records = await searchCompany(value)
                set_companyList(records)
              }
            }}
            onChange = {async (e, v)=> {
              const newRecord = {...activeRow}
              if(!newRecord.companyRltnList.find(it => +it.companyId === +v.id)) {
                newRecord.companyRltnList.push({userId: record.id, companyId: v.id})
                set_record(newRecord)
              }
              else {
                openSnackbar('이미 같은 회사가 선택되어 있습니다.')
              }
            }}
            size='small'
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField  {...params} label="회원사명" />}
          />
          <MUITable>
            <TableHead>
              <TableRow>
                <TableCell>제거</TableCell>
                <TableCell>위탁업체</TableCell>
                <TableCell>관계</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                Object.keys(companyGroup).map(it => {
                  return(
                    <TableRow>
                      <TableCell>
                        <IconButton onClick={()=> {
                          const newRecord = {...activeRow}
                          const rltnList = newRecord.companyRltnList.filter(d => d.companyId !== +it)
                          newRecord.companyRltnList = rltnList
                          set_record(newRecord)
      
                        }}>
                          <RemoveCircleOutlineIcon/>
                        </IconButton>
                       
                      </TableCell>
                      <TableCell>
                      {companies.find(s => +s.id === +it).name}
                      </TableCell>
                      <TableCell>
                        <div className='row-direction'>
                        {
                          codes.filter(code => code.codeGroupId === 'SA14').map(code => {
                            return(
                              <div >
                                <div key={code.id}>
                                  <input type='radio' 
                                    checked={record.companyRltnList.find(r => r.userId === record.id && r.companyId === +it && r.rltnCode === code.code) ? true: false}
                                    onChange={(e) => {
                                      if(it === -1) {
                                        alert('위탁업체를 먼저 선택하세요.')
                                        return
                                      }
                                      const newRecord = {...activeRow}
                                      const idx = newRecord.companyRltnList.findIndex(r => +r.userId === +newRecord.id && +r.companyId === +it)
                                      if(idx >= 0) {
                                        newRecord.companyRltnList.splice(idx, 1)
                                        newRecord.companyRltnList.push({
                                          userId: newRecord.id,
                                          companyId: +it,
                                          rltnCode: code.code,
                                        })
                                        set_record(newRecord)
                                      }
                                    }}
                                  /> 
                                  <span>{code.name}</span>
                                </div>
                              </div>
                            )
                          })
                        }
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </MUITable>
        </Grid>
      </Grid>

        <Button disabled={true} variant='contained' color='primary' size='small' onClick={onSubmit} sx={{mt: 10, width:100}}>저장</Button>
      </Box>
    </Container>
    );
  }
    

  const dg_title = '사용자 정보'
  const memo_DgContent = useMemo(()=> {return <DgContent />}, [record])
  if(dataList != null) {
    return (
      <div >
        <MyDialog title={dg_title} component={memo_DgContent} dialogHandler={dgHandler}/>
        <MyDialog title={dg_title} component={memo_DgContent} dialogHandler={dgHandler}/>
        <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
          <Typography component="h1" variant="h5" align={'center'} >
            사용자 관리
          </Typography>
        </div>
        <div style={{display:'flex', flexDirection:'row'}}>
          <Button disabled={true} variant='contained' color='primary' size='small' onClick={() => {onCreate()}}>생성</Button>
        </div>
        
        <div style={{paddingTop:20}}>
          <MUITable>
            <MyTableHead >
              <TableRow>
                {
                  fields.map((col, index) => {
                    if(col.visible) {
                      return(
                        <TableCell key={index} size='small' >
                          {col.placeholder}
                          {col.canSrch? 
                            ( 
                              col.field === 'emailCertYn' ? (
                                <input type='checkbox' style={{width:20, height:20}}
                                  ref = {searchRefMap[col.field]}
                                  onChange={(e) => {
                                    if(col.field === 'gdnCertYn') {
                                      if(e.target.checked) {
                                        // termsRef.current.emailCertYn= 'Y'
                                      }
                                      else {
                                        // delete termsRef.current.emailCertYn
                                      }
                                    }
                                  }}
                                />
                              ) : (
                                <Fragment>
                                    <br/>
                                    <TextField label="" variant="outlined" size='small' inputProps={{style: {fontSize: '0.875em', padding:5, backgroundColor:'#fff'}}} 
                                      onChange={(e) => {
                                        termsRef.current[col.field] = e.target.value
                                      }}
                                      inputRef = {searchRefMap[col.field]}
                                    />
                                </Fragment>
                              )
                            )
                            : (
                              <Fragment>
                                  <br/>
                                  <br/>
                              </Fragment>
                            )
                          }
                        </TableCell>
                      )
                    }
                  })
                }
                <TableCell size='small'>
                  <br/>
                  <FlatButton ref={buttonRef} size='small' variant='contained' style={{marginRight:5}} color='primary' onClick={onSearch}>검색</FlatButton>
                  {/*<FlatButton size='small' variant='contained'  color='primary' onClick={onClear}>지우기</FlatButton>*/}
                </TableCell>
              </TableRow>
            </MyTableHead>
            <TableBody>
              {
                dataList.map((row, index) => {
                  return(
                    <Fragment key={index}>
                      <TableRow onClick={()=> {
                        set_selIdx(index)
                        set_record(row)
                      }}>
                          {
                            fields.map((col, index2) => {
                              if(col.visible) {
                                return(
                                  <TableCell key={index2} size='small'>
                                    {row[col.field]}
                                  </TableCell>
                                )
                              }
                            })
                          }
                          <TableCell size='small'>
                            <FlatButton variant='outlined' size='small'  style={{marginRight:5}} onClick={() => onDetail(row.id)}>보기</FlatButton>
                            {
                              row.emailCertYn !== 'Y' ? (
                              <ConfirmDialog
                                  btn={<FlatButton color={'error'}>비인증강퇴</FlatButton>}
                                  title={row.name + ' ' + row.phone }
                                  content={row.email + ' 이메일인증을 하지 않은 갸입자입니다. 탈퇴처리 하시겠습니까?'}
                                  bc={'error'}
                                  preHandle={ async () => {
                                    return true
                                  }}
                                  perform={() => {
                                    onLeaveRequest(row.id)
                                  }}
                              />
                              ) : null
                            }
                          </TableCell>
                        </TableRow>
                      </Fragment>
                  )
                })
              }
            </TableBody>
            <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100 ]}
                colSpan={visibleColumns.length + 1}
                count={totalCount}
                rowsPerPage={pageInfo.pageSize}
                page={pageInfo.pageNum}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    // return '' + from + '-' + to + ' 개 ' + count
                    return `${from} - ${to} of ${count}`
                  }
                }
                labelRowsPerPage={'페이지당 갯수'}
              />
            </TableRow>
          </TableFooter>
          </MUITable>

           {renderSnackbar()}
           {renderIndicator()}
           {renderAlert()}
            <dg_content />
          </div>
      </div>
    );
  }
  
}

export default UserPage
