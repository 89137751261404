import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
// redux
import { useSelector, useDispatch } from 'react-redux';
import codeReducer from "../redux/reducers/codeReducer";
import { setCodes } from "../redux/actions/codeAction";
import codeGroupReducer from "../redux/reducers/codeGroupReducer";
import { setCodeGroups } from "../redux/actions/codeGroupAction";
import WebService from "../util/webService";
import { getAction } from "../util/helper";
import { setSchools } from '../redux/actions/schoolAction';

const onLoad = async dispatch => {
  let url
  let resp

  url = '/codeGroup'
  resp = await WebService.get(url)
  if(resp.repCode === 'ack') {
    const repMessage = JSON.parse(resp.repMessage)
    const records  = JSON.parse(repMessage.records)
    dispatch(setCodeGroups(records))
  }

  url = '/code'
  resp = await WebService.get(url)
  if(resp.repCode === 'ack') {
    const repMessage = JSON.parse(resp.repMessage)
    const records  = JSON.parse(repMessage.records)
    // dispatch(setCodes(records))
  }

  url = '/school'
  resp = await WebService.get(url)
  if(resp.repCode === 'ack') {
    const repMessage = JSON.parse(resp.repMessage)
    const records  = JSON.parse(repMessage.records)
    records.forEach(r => {
      r.label = `${r.name}(${r.newZipCode}, ${r.newAddr1})`
    })
    dispatch(setSchools(records))
  }
  
}

const card = (
  <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Word of the Day
      </Typography>
      <Typography variant="h5" component="div">
        서울 초등학교
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        adjective
      </Typography>
      <Typography variant="body2">
        well meaning and kindly.
        <br />
        {'"a benevolent smile"'}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" component={Link} to='/user'>GO</Button>
    </CardActions>
  </React.Fragment>
);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



const HomePage = () => {
  const defUrl = '/school'
  const pageTitle = '학교 관리'
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'


  const fields = [
    {field: 'id', placeholder: '아이디',    type:'text', value:-1, visible: false, disabled: false, selList:[]},
    {field: 'name', placeholder: '학교명',    type:'text', value:'', visible: true, disabled: false,selList:[]},
    {field: 'manager', placeholder: '담당자', type:'text', value:'', visible: true, disabled: false,selList:[]},
    {field: 'phone', placeholder: '대표전화', type:'number', value:'', visible: true, disabled: false,selList:[]},
    {field: 'email', placeholder: '이메일', type:'text', value:'', visible: true, disabled: false,selList:[]},
    {field: 'fax', placeholder: '팩스', type:'number', value:'', visible: true, disabled: false,selList:[]},
    {field: 'remark', placeholder: '비고', type:'text', value:'', visible: true, disabled: false,selList:[]},
    {field: 'regTime', placeholder: '등록일시', type:'text', value:'', visible: false, disabled: false,selList:[]},
    {field: 'uptTime', placeholder: '수정일시', type:'text', value:'', visible: false, disabled: false,selList:[]},
  ]

  const defRecord = {}
  fields.forEach(d => {
    defRecord[d.field] = d.value
  })

  const [record, set_record] = useState(defRecord)
  const [dataList, set_dataList] = useState([])
  const [fieldList, set_fieldList] = useState([fields])

  const dispatch = useDispatch()
  React.useEffect(() => {
    onLoad(dispatch)
  })

  return (
    <Box sx={{minHeight: "100vh", display:'flex',flexGrow: 1, justifyContent:'center', alignItems:'center', }}>
      <Grid container spacing={4} sx={{padding:10}}>
        <Grid item xs={12}>
          <Item>학교 추가하기</Item>
        </Grid>
        <Grid item xs={3}>
          <Card variant="outlined"> {card} </Card>
        </Grid>
        <Grid item xs={3}>
          <Card variant="outlined"> {card} </Card>
        </Grid>
        <Grid item xs={3}>
          <Card variant="outlined"> {card} </Card>
        </Grid>
        <Grid item xs={3}>
          <Card variant="outlined"> {card} </Card>
        </Grid>
      </Grid>
    </Box>
  )
};

export default HomePage;
