

const mainColor = '#1376e3'
const barColor = '#1aa95d'


export const GlobalStyle = {
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },

  colors: {
    main: mainColor,
    bar: barColor,
    cardBg:'#dfeaf8',
    attdncGood:'#e3f2fd',
    attdncBad:'#fffde7',
  }
}

export default GlobalStyle
