import React, { useState, useEffect, useMemo, Fragment, useRef, forwardRef, useImperativeHandle } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import DaumPostcodeEmbed, { useDaumPostcodePopup } from 'react-daum-postcode'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { billiard } from '@util/string'
import { useSelector, useDispatch } from 'react-redux'

const RecvPopup = forwardRef((props, ref) => {
  const theme = useTheme()
  const { title, component, windowWidth, callback } = props
  const [open, set_open] = useState(false)
  const [checkList, _set_checkList] = useState([])
  const checkListRef = useRef(checkList)

  const set_checkList = (data) => {
    checkListRef.current = data
    _set_checkList(data)
  }

  const codes = useSelector((state) => state.codeReducer)
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')

  const handleOpen = () => {
    set_open(true)
  }

  const handleCancel = () => {
    handleClose()
  }

  const handleConfirm = () => {
    if (callback) {
      callback(checkListRef.current)
    }
    handleClose()
  }

  const handleClose = () => {
    set_open(false)
  }

  useImperativeHandle(ref, () => ({
    open: () => {
      handleOpen()
    },
    close: () => {
      handleClose()
    },
  }))

  const handleComplete = (data) => {
    let fullAddress = data.address
    let extraAddress = ''
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    addrRef.current = fullAddress
    handleClose()
  }

  const handleChecked = (e, code) => {
    const new_data = [...checkList]
    const idx = checkList.findIndex((r) => r.code === code.code)
    if (e.target.checked) {
      if (idx < 0) {
        new_data.push(code)
      }
    } else {
      if (idx >= 0) {
        new_data.splice(idx, 1)
      }
    }
    set_checkList(new_data)
  }
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={windowWidth ?? 'sm'}>
        <DialogTitle style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='h5' align='center' style={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Divider sx={{ mt: 1 }} />
        </DialogTitle>
        <DialogContent>
          <Container>
            <Typography color={theme.palette.secondary.main}>
              {billiard} 2가지 이상 선택시 요금이 추가됩니다.
            </Typography>
            <Divider />
            <Grid container>
              <Grid item xs={12}>
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    {recvCodes.map((item, idx) => (
                        <FormControlLabel
                          key={idx}
                          label={item.name}
                          control={
                            <Checkbox
                              color='primary'
                              checked={checkList.find((r) => r.code === item.code)}
                              onChange={(e) => {
                                handleChecked(e, item)
                              }}
                            />
                          }
                        />
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </Container>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button onClick={handleCancel}>취소</Button>
          <Button onClick={handleConfirm} variant='contained'>
            선택
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default RecvPopup
