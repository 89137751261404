import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
const OrderSuccess = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const location = useLocation()
  const curLocationRef = useRef(location)
  const { alert, renderAlert } = useAlert()
  const [paid, set_paid] = useState(false)
  const [searchParams] = useSearchParams()
  const [secretKey, set_secretKey] = useState('')
  console.log('order success')
  console.log('searchParams=>', searchParams)

  for (const [key, value] of searchParams.entries()) {
    console.log(`searchParams => ${key}: ${value}`)
  }

  const onSuccess = async () => {
    const requestData = {
      orderId: searchParams.get('orderId'),
      amount: searchParams.get('amount'),
      paymentKey: searchParams.get('paymentKey'),
    }

    // TODO: 개발자센터에 로그인해서 내 결제위젯 연동 키 > 시크릿 키를 입력하세요. 시크릿 키는 외부에 공개되면 안돼요.
    // @docs https://docs.tosspayments.com/reference/using-api/api-keys
    // const secretKey = 'test_gsk_docs_OaPz8L5KdmQXkzRz3y47BMw6'
    const encryptedSecretKey = `Basic ${btoa(secretKey + ':')}`

    /* axios 를 사용하면 Authorization 충돌
    const url = `https://api.tosspayments.com/v1/payments/confirm`
    const response = await axios.post(url, JSON.stringify(requestData), {
      headers: {
        Authorization: encryptedSecretKey,
        'Content-Type': 'application/json',
      },
    })
    const json = response.data
    console.log('json=>', json)
    */

    const response = await fetch('https://api.tosspayments.com/v1/payments/confirm', {
      method: 'POST',
      headers: {
        Authorization: encryptedSecretKey,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })

    const json = await response.json()
    if (response.ok) {
      set_paid(true)
    }

  }

  const afterSuccess = async () => {
    const url = `/order/after/payment` 
    const data = {
      orderNo: searchParams.get("orderId"),
      paymentKey: searchParams.get("paymentKey"),
    }
    const resp = await axios.post(url, data)
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
    }
  }

  const fetchKey = async() => {
    const url = `/order/pg/key`  
    const resp = await  WebService.get(url)
    if(resp.repCode != 'ack') {
      alert('결제키를 가져오는데 실패하였습니다.')
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const secretKey = repMessage.secretKey
    set_secretKey(secretKey)
  }


  useEffect(()=> {
    if(paid) {
      afterSuccess()
    }
  }, [paid])

  useEffect(() => {
    if(secretKey) {
      onSuccess()
    }
  },[secretKey])

  useEffect(() => {
    // onSuccess()
    fetchKey()
  }, [searchParams])



  return (
    <div style={{ paddingTop: isMobile ? 50 : 100 }}>
      {renderAlert()}
      <Container maxWidth='xs' style={{}}>
        <Grid container spacing={10}>
          {paid ? (
            <>
              <Grid item xs={12}>
                <Typography
                  variant='h5'
                  style={{ fontWeight: 'bold', textAlign: 'center', color: theme.palette.success.main }}
                >
                  결제 완료
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ textAlign: 'center' }}>
                  총 {comma(searchParams.get('amount'))} 원을 결제하였습니다.
                </Typography>
                <Typography style={{ textAlign: 'center', marginTop:5 }}>
                  민원처리결과에서 내용을 확인하실 수 있습니다.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography
                  variant='h5'
                  style={{ fontWeight: 'bold', textAlign: 'center', color: theme.palette.info.main }}
                >
                  결제 중...
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ textAlign: 'center' }}>
                  잠시만 기다려 주세요. <br/>
                  화면이 멈추어 있을 경우 결제를 다시하여주시기 바랍니다.
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  )
}

export default OrderSuccess
