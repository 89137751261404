import React, { useState, useEffect, useMemo, Fragment, useRef, forwardRef, useImperativeHandle } from 'react'
import {
  Grid,
} from '@mui/material'
import Labeller from './Labeller'
import { GlobalStyle as gs } from '@src/GlobalStyle'

const MinwonInput = ({label, must, component}) => {
  return(
    <Grid item xs={12}>
      <Grid container style={gs.alignCenter}>
        <Grid item xs={3}>
          <Labeller label={label} must={must} />
        </Grid>
        <Grid item xs>
          {component}
        </Grid>
      </Grid>
    </Grid>
  )
}
export default MinwonInput

