import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import SemuPartView from '@pages/ask/semu/semu.part.view'
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined'
import RestPartView from '@pages/ask/rest/rest.part.view'
import MinwonView from '@components/MinwonView'
const MyBasketViewPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const location = useLocation()
  const basket = location.state?.basket?? {}
  const docs = basket?.docs ?? []
  const curLocationRef = useRef(location)
  const { alert, renderAlert } = useAlert()
  const docCodes = codes.filter(r => r.codeGroupId === 'D001')
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const [recvAmount, set_recvAmount] = useState(0)
  const [serviceAmount, set_serviceAmount] = useState(0)
  
  const onLoad = async () => {
  }

  useEffect(() => {
    
    const serviceAmount = basket.docs.reduce((acc, cur) => {
      const code = docCodes.find(r => r.code === cur.docCode)
      const price = cur.giveYn ==  'Y' ? code.note2 : code.note1
      console.log('price=>', price)
      return acc + (price * cur.qty)
    }, 0)

    set_serviceAmount(serviceAmount)

  }, [])
  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    onLoad()
  }, [])

  return (
    <div style={{ paddingTop: isMobile ? 50 : 100 }}>
      {renderAlert()}
      <Container maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IconButton onClick={() => navigate(-1)} style={{backgroundColor:'#fafafa'}}>
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {docs.map((doc, idx) => (doc.docCode?.startsWith('DS') ? <SemuPartView doc={doc} /> : <RestPartView doc={doc}/>))}
          </Grid>
          <Grid item xs={12}>
            <MinwonView 
              label='총금액'
              component={
                <Typography>{comma(serviceAmount + recvAmount)} 원</Typography>
              }
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default MyBasketViewPage
