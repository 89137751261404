import axios from 'axios'
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'

import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Alert,
  Paper,
} from '@mui/material'
import WebService from '@util/webService'
import {
  copyProperties,
  encodeParams,
  getAction,
  isStringNullOrEmpty,
  printVariable,
  searchCompany,
} from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux'
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton'
import useSnackbar from '@hooks/useSnackbar'
import MyTableHead from '@components/MyTableHead'
import useIndicator from '@hooks/useIndicator'
import Indicator from '@components/Indicator'
import MyDialog from '@components/MyDialog'
import Box from '@mui/material/Box'
import useAlert from '@hooks/useAlert'
// pagination
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Avatar, Grid, Container } from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { searchSchool } from '@util/helper'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ConfirmDialog from '@components/ConfirmDialog'
import useDelegate from '@hooks/useDelegate'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

const ProfileUserPage = () => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const defRecord = {}
  defRecord.schoolList = []
  const defTerms = {}

  const [dataList, set_dataList] = useState([])
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const { alert, renderAlert } = useAlert()
  const dgHandler = useState(false)
  const [terms, _set_terms] = useState(defTerms)
  const termsRef = useRef(terms)

  // redux
  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const schools = useSelector((state) => state.schoolReducer)
  const account = useSelector((state) => state.accountReducer)

  const [record, set_record] = useState({})

  const navigate = useNavigate()

  const set_terms = (data) => {
    termsRef.current = data
    _set_terms(data)
  }

  useEffect(() => {
    // onLoad()
  }, [])

  const onLoad = async () => {
    openIndicator()

    const url = `/user/id/${account.user.id}`
    const resp = await WebService.get(url, {})
    if (resp.repCode === 'reject') {
      navigate('/login')
    }

    if (resp.repCode === 'ack') {
      const repMessage = JSON.parse(resp.repMessage)

      const newRecord = JSON.parse(repMessage.record)
      newRecord.oldPassword = ''
      newRecord.newPassword = ''
      newRecord.newPassword2 = ''

      set_record(newRecord)
      // activeRow.loginId = newRecord.loginId
      // copyProperties(newRecord, valuesRef.current)
      updateState(newRecord)
    }
    closeIndicator()
  }

  ////
  const onSubmit = async () => {
    const url = `/user/password/new/${account.user.id}`
    const data = {
      password: activeRow.oldPassword,
      newPassword: activeRow.newPassword,
    }

    const resp = await WebService.post(url, data)
    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
    } else {
      alert('수정되었습니다.')
    }
  }

  const validate = (name, value) => {
    let err = ''
    if (name === 'oldPassword') {
      if (isStringNullOrEmpty(value)) {
        err = '기존 비밀번호를 입력해 주세요.'
      }
    }

    if (name === 'newPassword') {
      const passwordRegex =
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
      if (!passwordRegex.test(value)) {
        err = '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요.'
      }
    }

    if (name === 'newPassword2') {
      if (value === '') {
        err = '패스워드가 일치하는지 확인해 주세요.'
      }
      if (factor['newPassword'] !== value) {
        err = '패스워드가 일치하는지 확인해 주세요(2).'
      }
    }

    // if(name === 'phone') {
    //   const phoneRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
    //   if(!phoneRegex.test(value)) {
    //     err = '올바른 휴대폰번호 형식이 아닙니다.'
    //   }
    // }

    return err
  }

  const { activeRow, errors, updateState, handleChange, handleSubmit, factor } =
    useDelegate({
      initialValues: { ...record },
      onSubmit: onSubmit,
      validate: validate,
    })

  return (
    <Container component="main" maxWidth="md">
      {/* 개인정보 */}
      <Grid container style={{ marginTop: 30 }}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {account.user.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Grid container style={{ padding: 10, cursor:'pointer' }}
            onClick={() => navigate('/profile/user/detail')}
          >
            <Grid item xs={6}>
              <Typography>{account.user.loginId}</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ArrowForwardIosOutlinedIcon />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* 이력서 */}
      <Grid container style={{ marginTop: 30 }}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            이력서
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ border: '1px solid #eee', cursor: 'pointer' }}
        >
          <Grid container style={{ padding: 10 }}
            onClick={() => navigate('/profile/user/resume')}
          >
            <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
              <TextSnippetOutlinedIcon />
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ fontWeight: 'bold' }}>
                이력서 정보
              </Typography>
              <Typography>수정일시 2024-03-14 12:33:12</Typography>
              <div style={{ display: 'flex' }}>
                <RemoveRedEyeOutlinedIcon /> &nbsp;{' '}
                <Typography>검색가능</Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ArrowForwardIosOutlinedIcon />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div>
        {renderSnackbar()}
        {renderIndicator()}
        {renderAlert()}
      </div>
    </Container>
  )
}

export default ProfileUserPage
