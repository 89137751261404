import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useNavigate } from "react-router-dom";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TextField from '@mui/material/TextField';
// redux
import { useSelector, useDispatch } from 'react-redux';
import WebService from '@util/webService';
import {emailRegex, getAction, printVariable} from '@util/helper';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

import '@scss/App.scss'
import { isAdmin } from '@util/helper';
import {FormHelperText} from "@mui/material";
import ConfirmDialog from "@components/ConfirmDialog";
import useIndicator from "@hooks/useIndicator";
import useAlert from "@hooks/useAlert";
import { SERVICE_NAME } from '@util/constant';
import {Container} from '@mui/material'
import {setAccount} from '@redux/actions/accountAction'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  borderBottom: '1 solid #ccc',
  boxShadow:{},
  color: theme.palette.text.secondary,
}));

const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;

const WelcomeEcertPage = () => {
  const navigate = useNavigate()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const account = useSelector(state => state.accountReducer)

  const [loginId, set_loginId] = useState(account?.user?.loginId ?? '')
  const [emailError, set_emailError] = useState('')
  const {alert, renderAlert} = useAlert()
  const [certNum, set_certNum] = useState('')
  const [emailCertId, set_emailCertId] = useState('')
  const [loaded, set_loaded] = useState(false)
  const dispatch = useDispatch()

  const handleLoginId = (e) => {
    const v = e.target.value.trim()
    if(!emailRegex.test(v) ){
      set_emailError('올바른 이메일 형식이 아닙니다.')
    }
    else {
      set_emailError('')
    }
    set_loginId(v)
  }

  const canSubmit = () => {
    return emailRegex.test(loginId)
  }

  const canCert = () => {
    return certNum.length === 6
  }

  const canChange = async () =>{
    // TODO
    // const url = encodeURI(`/public/email/${loginId}/${account?.user?.id}`)
    const url = encodeURI(`/public/email/${loginId}/${account.user.id}`)

    openIndicator()
    const resp = await WebService.get(url)
    closeIndicator()

    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return false
    }

    return true
  }

  const onCert = async() => {
    const url = `/user/email/cert/${emailCertId}/${certNum}`
    const resp = await WebService.post(url)
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const account = JSON.parse(repMessage.account)
    dispatch(setAccount(account))
    alert('인증되었습니다.', () => {
      navigate('/welcome/index')
    })
  }

  const onSubmit = async () => {
    const isUnq = await canChange()
    if(!isUnq) {
      return
    }

    const url = encodeURI(`/user/email/cert`)
    const data = {
      userId: account.user.id,
      email: loginId,
    }
    openIndicator()
    const resp = await WebService.post(url, data)
    closeIndicator()
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    closeIndicator()
    const repMessage = JSON.parse(resp.repMessage)
    const emailCertId = repMessage.emailCertId
    set_emailCertId(emailCertId)
    alert('이메일을 발송하였습니다. 메일 수신함을 확인하세요.')
  }


  const fetchEmailCertId = async() => {
    const url = `/user/email/cert/${account.user.loginId}`
    const resp = await WebService.get(url)
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    return repMessage.emailCertId
  }

  const onLoad = async() =>{
    if(account?.user) {
      if(account.user.emailCertYn === 'Y') {
        navigate('/welcome/index')
      }
      else {
        const emailCertId = await fetchEmailCertId()
        if(emailCertId) {
          set_emailCertId(emailCertId)
        }
        
      }
    }
    else {
      navigate('/login')
    }
    set_loaded(true)
  }

  useEffect(() => {
    onLoad()
  },[])

  return (
    <>
    {
      loaded ? (
        <Container component='main' sx={{ display:'flex',flexGrow: 1, justifyContent:'center', alignItems:'center', }} maxWidth='xs'>
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <div>
                <Typography variant="h6" align='center'>
                  가입을 환영합니다.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sx={{mb:1}}>
              <Typography variant="body2" >
                이메일 인증이 완료되지 않았습니다. <br/>
                가입하신 이메일의 받은편지함을 확인하여 인증을 완료해 주세요.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <TextField 
                fullWidth
                label='인증번호'
                size='small'
                value={certNum} 
                inputProps={{
                  maxLength:6,
                  onInput: (e) => {
                    const value = e.target.value;
                    // Remove any non-numeric characters from the input
                    const numericValue = value.replace(/[^0-9]/g, '');
                    e.target.value = numericValue;
                  },
                }}
                onChange ={(e) => {
                  set_certNum(e.target.value)
                 }}
              />  
            </Grid>
              <Grid item xs={12}>
                <Button fullWidth size='medium' color={'primary'} disabled={!canCert()} onClick={onCert}>인증하기</Button>
              </Grid>
            <Grid item xs={12} sx={{display:'flex', mt:5,mb:1 }}>
                <Typography variant='body2'>
                  아래에서 이메일을 주소를 수정하여 재발송 할 수 있으며,<br/>
                  이 후에는 인증된 이메일로만 로그인할 수 있습니다.
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <TextField
                fullWidth
                label='아이디(이메일)'
                value={loginId}
                onChange={(e) => {
                  handleLoginId(e)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ConfirmDialog
                btn={<Button fullWidth size='lg' color={'primary'} disabled={!canSubmit()}>이메일 (재)발송</Button>}
                title={'알림'}
                content={'이메일을 발송 하시겠습니까?'}
                bc={'primary'}
                preHandle={ async () => {
                  return true
                }}
                perform={() => {
                  onSubmit()
                }}
              />

            </Grid>
            <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <FormHelperTexts className='center'>{emailError}</FormHelperTexts>
            </Grid>
          </Grid>
          {renderIndicator()}
          {renderAlert()}

        </Container>

      ) : null
    }
    </>
  )
}

export default WelcomeEcertPage
