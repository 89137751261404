import { SET_ACCOUNT } from "../actions/accountAction"
const initState = {}

const accountReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_ACCOUNT:
      return action.payload
    default:
      return state
  }
}

export default accountReducer