import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import codeGroupReducer from './reducers/codeGroupReducer'
import codeReducer from './reducers/codeReducer'
import schoolReducer from './reducers/schoolReducer'
import accountReducer from './reducers/accountReducer';
import scholaReducer from './reducers/scholaReducer';
import versionReducer from "@redux/reducers/versionReducer";
import activeSideIndexReducer from './reducers/activeSideIndexReducer';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({codeGroupReducer, codeReducer, schoolReducer, accountReducer, scholaReducer, versionReducer, activeSideIndexReducer})
const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const Store = createStore(rootReducer, applyMiddleware(thunk))
export const Store = createStore(persistedReducer, applyMiddleware(thunk))
export const Persistor = persistStore(Store)
