import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import { needPrfTerm } from '@pages/ask/semu/semu.util'

import useAlert from '@hooks/useAlert'
import MinwonView from '@components/MinwonView'
import AskDownloader from '@components/AskDownloader'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Blender, SwipeLeftTwoTone } from '@mui/icons-material'
import StateChanger from '@components/StateChanger'
const SemuPartView = ({ doc, rcodes}) => {
  console.log('doc=>', doc)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const docCodes = codes.filter((r) => r.codeGroupId == 'D001')
  const sepCodes = codes.filter((r) => r.codeGroupId == 'D009')
  const aimCodes = codes.filter((r) => r.codeGroupId == 'D003')
  const mockCodes = codes.filter((r) => r.codeGroupId == 'D010')
  const bizCodes = codes.filter((r) => r.codeGroupId == 'D004')
  const factCodes = codes.filter((r) => r.codeGroupId == 'D005')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const location = useLocation()
  const curLocationRef = useRef(location)
  const { alert, renderAlert } = useAlert()

  const onLoad = async () => {}

  const quotePrice = (doc) => {
    const code = docCodes.find(r => r.code === doc.docCode)
    return doc.giveYn === 'Y' ? code.note2 : code.note1
  }

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    onLoad()
  }, [])

  return (
    <div style={{}}>
      {renderAlert()}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                {docCodes.find((r) => r.code === doc.docCode)?.name}{' '}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <StateChanger record={doc} tbl={'docs'}/>
              <AskDownloader doc={doc} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
        <MinwonView
          label='사업자구분'
          component={<Typography style={{}}>{sepCodes.find((r) => r.code === doc.sepCode)?.name}</Typography>}
        />
        <MinwonView label='성명' component={<Typography style={{}}>{doc.name}</Typography>} />
        <MinwonView
          label='전화번호'
          component={
            <Typography style={[]}>
              {doc.phoneDial} - {doc.phone1} - {doc.phone2}
            </Typography>
          }
        />

        {/* 세무민원 */}
        {doc.docCode.startsWith('DS') ? (
          <>
            <MinwonView
              label='한영구분'
              component={
                doc.hanYn === 'Y' ? <Typography style={[]}>한글</Typography> : <Typography style={[]}>영문</Typography>
              }
            />
            {doc.hanYn === 'N' ? (
              <MinwonView label='영문성명' component={<Typography>{doc.engName}</Typography>} />
            ) : null}
            {doc.docCode === 'DS10' && doc.sepCode === 'S003' ? null : (
              <MinwonView
                label='주민등록번호'
                component={
                  <Typography>
                    {doc.civilNum.take(6)} - {doc.civilNum.takeRight(7)}
                  </Typography>
                }
              />
            )}
            {doc.sepCode === 'S002' || doc.sepCode === 'S003' ? (
              <>
                <MinwonView label='상호명' component={<Typography>{doc.companyName}</Typography>} />
                <MinwonView
                  label='사업자등록번호'
                  component={
                    <Typography>
                      {doc.bizNum.take(3)} - {doc.bizNum.drop(3).take(2)} - {doc.bizNum.takeRight(5)}
                    </Typography>
                  }
                />
              </>
            ) : null}

            <MinwonView
              label='주소지'
              component={
                <Typography>
                  {doc.addr1} {doc.addr2}
                </Typography>
              }
            />

            {needPrfTerm.includes(doc.docCode) ? (
              <MinwonView
                label='증명기간'
                component={
                  <Typography>
                    {doc.prfFrom} ~ {doc.prfTo}
                  </Typography>
                }
              />
            ) : null}
            {doc.docCode === 'DS06' || doc.docCode === 'DS10' ? (
              <MinwonView
                label='발급목적'
                component={<Typography>{mockCodes.find((r) => r.code === doc.mockCode).name}</Typography>}
              />
            ) : null}

            {doc.docCode === 'DS10' ? (
              doc.mockCode === 'KL01' ? (
                <MinwonView label='대금지급자' component={<Typography>{doc.payer}</Typography>} />
              ) : doc.mockCode === 'KL02' ? (
                <MinwonView
                  label='해외이주'
                  component={
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography>이주번호: {doc.migNum}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>신청일자: {doc.migDate}</Typography>
                      </Grid>
                    </Grid>
                  }
                />
              ) : doc.mockCode === 'KL03' ? (
                <MinwonView label='그밖의 목적' component={<Typography>제출용</Typography>} />
              ) : doc.mockCode === 'L001' ? (
                <MinwonView
                  label='신탁부동산 표시'
                  component={
                    <Grid container>
                      <Grid item xs={6}>
                        {doc.trustAddr1}
                      </Grid>
                      <Grid item xs={6}>
                        {doc.trustAddr2}
                      </Grid>
                    </Grid>
                  }
                />
              ) : null
            ) : null}

            {doc.docCode === 'DS11' ? null : (
              <MinwonView
                label='용도'
                component={<Typography>{aimCodes.find((r) => r.code === doc.aimCode).name}</Typography>}
              />
            )}
            {doc.docCode === 'DS07' ? (
              <MinwonView
                label='사업자관련증명'
                component={<Typography>{bizCodes.find((r) => r.code === doc.bizCode).name}</Typography>}
              />
            ) : null}
            {doc.docCode === 'DS09' ? (
              <MinwonView label='사업자등록증 재발급사유' component={<Typography>{doc.bizDocReason}</Typography>} />
            ) : null}
            {doc.docCode === 'DS11' ? (
              <MinwonView
                label='사실증명'
                component={<Typography>{factCodes.find((r) => r.code === doc.factCode).name}</Typography>}
              />
            ) : null}
            <MinwonView
              label='주민등록번호 공개여부'
              component={<Typography>{doc.civilnumYn === 'Y' ? '공개' : '일부공개'}</Typography>}
            />
            <MinwonView
              label='주소 공개여부'
              component={<Typography>{doc.civilnumYn === 'Y' ? '공개' : '일부공개'}</Typography>}
            />
            <MinwonView
              label='열람용/제출용'
              component={<Typography>{doc.giveYn === 'Y' ? '제출용' : '열람용'}</Typography>}
            />
            <MinwonView label='수량' component={<Typography>{doc.qty} 매</Typography>} />
            <MinwonView label='금액' 
              component={
                <>
                <Typography>{comma(quotePrice(doc))} 원</Typography> 
                </>
              } 
            />
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  )
}

export default SemuPartView
