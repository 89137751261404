import * as React from 'react'
import { forwardRef, useRef, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { IconButton, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import DrawerComponent from './Drawer'
import { Outlet } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined'
// redux
import { useSelector, useDispatch } from 'react-redux'
import LogoutIcon from '@mui/icons-material/Logout'
import { isAdmin, isRepManager } from '@util/helper'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined'
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined'
import UpcomingOutlinedIcon from '@mui/icons-material/UpcomingOutlined'
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined'
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined'
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import HailOutlinedIcon from '@mui/icons-material/HailOutlined'
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import ChairAltOutlinedIcon from '@mui/icons-material/ChairAltOutlined'
// import logo from '@images/logo-tns.png'
import logo from '@images/logo.png'
import Avatar from '@mui/material/Avatar'
import useStyles from '@hooks/useStyles'
import LeftDrawer from '@components/LeftDrawer'
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import { Menu, MenuItem, Button, Grid } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import ProfileDrawer from './ProfileDrawer'
import { LogoutOutlined, Person } from '@mui/icons-material'
import BusinessIcon from '@mui/icons-material/Business'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import Footer from './part/Footer'
import { customerMenu, myMenu } from '@src/util/menu'
import { setActiveSideIndex } from '@redux/actions/activeSideIndexAction'
import { genMenu } from '@util/helper'
import EngineeringOutlined from '@mui/icons-material/EngineeringOutlined'
import ArchitectureOutlined from '@mui/icons-material/ArchitectureOutlined';
const mainColor = '#33c058'
const drawerWidth = 240

const PersonButton = ({ text, icon, onClick }) => {
  return (
    <Button
      fullWidth
      variant='text'
      size='large'
      onClick={onClick}
      style={{ justifyContent: 'flex-start', color: '#000', margin: 5 }}
      startIcon={icon}
    >
      <Typography variant='body2' style={{ marginRight: 8 }}>
        {text}
      </Typography>
    </Button>
  )
}

export default function CommonDrawer({ sideMenu, showSideEveryScreen, title, barBgColor, sideBgColor }) {
  console.log('sideMenu=>', sideMenu)
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // redux
  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const schools = useSelector((state) => state.schoolReducer)
  const account = useSelector((state) => state.accountReducer)
  const schola = useSelector((state) => state.scholaReducer)
  const activeSideIndex = useSelector((state) => state.activeSideIndexReducer)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [activeMenu, set_activeMenu] = React.useState({})
  // const [activeIdx, set_activeIdx] = React.useState(-1)

  const [semuMenu, set_semuMenu] = useState([])
  const [restMenu, set_restMenu] = useState([])

  const defIcon = <LabelOutlinedIcon size='small' sx={{ color: '#212121' }} />
  const defSx = { color: '#212121', fontSize: 20 }
  const primaryTypographyProps = (menuId) => {
    return {
      style: {
        fontSize: 14,
        fontFamily: ['JejuGothic', 'sans-serif'],
        // color: activeIdx === menuId ? theme.palette.primary.main : '#212121',
        // fontWeight: activeIdx === menuId ? 'bold' : 'normal',
        color: activeSideIndex === menuId ? theme.palette.primary.main : '#212121',
        fontWeight: activeSideIndex === menuId ? 'bold' : 'normal',
      },
    }
  }
  const reqRef = useRef()
  const [anchorEl, set_anchorEl] = React.useState(null)

  const MenuTypo = ({ menuTitle, menuItems, onClick }) => {
    const dispatch = useDispatch()
    return menuItems && menuItems.length > 0 ? (
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            <Button variant='text' {...bindTrigger(popupState)}>
              <Typography
                variant='h5'
                style={{ color: '#000', cursor: 'pointer', fontFamily: ['JejuGothic', 'sans-serif'] }}
              >
                {menuTitle}
              </Typography>
            </Button>

            <Menu {...bindMenu(popupState)}>
              {menuItems &&
                menuItems.map((item, idx) => (
                  <MenuItem
                    key={idx}
                    onClick={() => {
                      navigate(item.to)
                      // set_activeIdx(item.id)
                      dispatch(setActiveSideIndex(item.id))
                      console.log('MenuTypo menuId=>', item.id)
                      popupState.close()
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    ) : (
      <Button
        variant='text'
        onClick={() => {
          onClick()
        }}
      >
        <Typography variant='h5' style={{ color: '#000', cursor: 'pointer', fontFamily: ['JejuGothic', 'sans-serif'] }}>
          {menuTitle}
        </Typography>
      </Button>
    )
  }

  const handleMenuClick = (event, row) => {
    event.currentTarget.getBoundingClientRect()
    set_anchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    set_anchorEl(null)
  }

  const styledIcon = (menu, activeIdx) => {
    const icon = menu.icon ? menu.icon : defIcon
    const newIcon = React.cloneElement(icon, {
      style: {
        ...icon.props.style,
        // color: menu.id === activeIdx ? theme.palette.primary.main : '#212121',
        color: menu.id === activeSideIndex ? theme.palette.primary.main : '#212121',
      },
    })

    return newIcon
  }

  // React.useEffect(() => {
  //   console.log('useEffect activeIdx=>', activeIdx)
  // }, [activeIdx])

  useEffect(() => {
    const semuMenu = genMenu(codes, 'D001', 'DS', `/ask/semu`)
    const restMenu = genMenu(codes, 'D001', 'DG', `/ask/rest`)
    set_semuMenu(semuMenu)
    set_restMenu(restMenu)
  }, [codes])

  useEffect(() => {
    console.log('useEffect activeSideIdx=>', activeSideIndex)
    // set_activeIdx(activeSideIndex)
  }, [activeSideIndex])

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position='fixed'
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: barBgColor ?? theme.palette.primary.bar,
          borderBottom: '1px solid', // Add border bottom style
          borderBottomColor: '#e0e0e0',
        }}
        elevation={0}
      >
        <Toolbar sx={{ backgroundColor: barBgColor ?? theme.palette.primary.bar }}>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
            {isMobile && sideMenu ? <LeftDrawer sideMenu={sideMenu} /> : null}
            <Link
              to='/welcome'
              className={classes.logo}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <img src={logo} alt='logo' style={{ width: isMobile ? 50 : 70, marginRight: 10, padding: 10 }} />
              <Typography noWrap className={classes.logo} sx={{ fontSize: 18 }} component={Link} to='/welcome'>
                {title ?? ''}
              </Typography>
            </Link>
          </div>
          <div>
            {isAdmin(account) ? (

                <IconButton onClick={() => navigate('/admin')}>
                  <ArchitectureOutlined />
                </IconButton>
            ) : null}
          </div>
          <div>
            {account?.user?.id ? (
              <Typography noWrap className={classes.logo} sx={{ fontSize: 18 }} component={Link} to='/logout'>
                <IconButton>
                  <LogoutOutlined />
                </IconButton>
              </Typography>
            ) : (
              <Typography noWrap className={classes.logo} sx={{ fontSize: 18 }} component={Link} to='/login'>
                로그인
              </Typography>
            )}
          </div>

          {/* {account?.user?.id ? ( */}
          {/*   isMobile ? ( */}
          {/*     <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-end', }} > */}
          {/*       <DrawerComponent /> */}
          {/*     </div> */}
          {/*   ) : ( */}
          {/*     <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} ></div> */}
          {/*   ) */}
          {/* ) : ( */}
          {/*   <div style={{ flex: 1, display: 'flex', justifyContent: 'center', backgroundColor: '#e0e0e0', }} ></div> */}
          {/* )} */}
        </Toolbar>

        {isMobile ? null : (
          <Grid container spacing={2}>
            <Grid item xs></Grid>
            <Grid item xs={2}>
              <MenuTypo menuTitle='세무민원신청' menuItems={semuMenu} />
            </Grid>
            <Grid item xs={2}>
              <MenuTypo menuTitle='일반민원신청' menuItems={restMenu} />
            </Grid>
            <Grid item xs={2}>
              <MenuTypo menuTitle='민원처리결과' onClick={() => navigate('/my')} />
            </Grid>
            {/* <Grid item xs={2}> */}
            {/*   <MenuTypo menuTitle="등기정보" /> */}
            {/* </Grid> */}
            <Grid item xs={2}>
              <MenuTypo menuTitle='고객센터' menuItems={customerMenu} />
            </Grid>
            <Grid item xs={2}>
              <MenuTypo menuTitle='마이페이지' menuItems={myMenu} />
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        )}
      </AppBar>
      {sideMenu && showSideEveryScreen ? (
        isMobile ? null : (
          <Drawer
            variant='permanent'
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: sideBgColor ?? null,
              },
            }}
          >
            <Box sx={{ overflow: 'auto', mt: theme.spacing(14) }}>
              {sideMenu &&
                sideMenu.map((item, idx) => (
                  <div key={idx}>
                    <List style={{ backgroundColor: '#f5f5f5' }}>
                      <ListItem>{item.title}</ListItem>
                    </List>
                    {item.list.map((menu, idx2) =>
                      menu.text === 'divider' ? (
                        <Divider />
                      ) : (
                        <ListItem
                          key={idx2}
                          disablePadding
                          style={{ justifyContent: 'center', alignItems: 'center' }}
                          component={Link}
                          to={menu.to}
                          onClick={() => {
                            console.log('menuId=>', menu.id)
                            // set_activeIdx(menu.id)
                            dispatch(setActiveSideIndex(menu.id))
                          }}
                        >
                          <ListItemButton selected={menu.id === activeSideIndex}>
                            <ListItemIcon sx={{ minWidth: '35px', color: idx2 === activeSideIndex ? 'red' : 'black' }}>
                              {styledIcon(menu, 0)}
                            </ListItemIcon>
                            <ListItemText
                              primaryTypographyProps={primaryTypographyProps(menu.id)}
                              primary={menu.text}
                            />
                          </ListItemButton>
                        </ListItem>
                      )
                    )}
                  </div>
                ))}
              {/* <List style={{ backgroundColor: '#f5f5f5' }}> */}
              {/*   <ListItem> */}
              {/*     <Typography>인터넷전체민원</Typography> */}
              {/*   </ListItem> */}
              {/* </List> */}
              {/* <Divider style={{ width: '100%' }} /> */}
              {/* <List style={{ backgroundColor: '#f5f5f5' }}> */}
              {/*   <ListItem> */}
              {/*     <Typography>자주찾는민원</Typography> */}
              {/*   </ListItem> */}
              {/* </List> */}
              <Divider style={{ width: '100%' }} />
              <List style={{ backgroundColor: '#f5f5f5' }}>
                <ListItem>
                  <Typography>무통장입금 계좌안내</Typography>
                </ListItem>
              </List>
              <Divider />
            </Box>
          </Drawer>
        )
      ) : null}
      <Box component='main' sx={{ flexGrow: 1, p: 0 }}>
        <Toolbar />
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', minHeight: '100vh' }}>
          <Outlet />
        </div>
        <div style={{ display: 'flex', marginTop: 50 }}>
          <Footer />
        </div>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            minWidth: 300,
            padding: 10,
          }}
          onClick={handleMenuClose}
        >
          <Typography fullWidth variant='h7' style={{ textAlign: 'center' }}>
            {account?.user?.loginId}
          </Typography>
          <Divider style={{ width: '100%' }} />
          {/* <PersonButton text={'설정'} icon={<SettingsOutlinedIcon/>} onClick={() => navigate('/profile/interest')} />  */}
          <Divider style={{ width: '100%' }} />
          <PersonButton text={'기업회원'} icon={<BusinessOutlinedIcon />} onClick={() => navigate('/company/index')} />
          <Divider style={{ width: '100%' }} />
          <PersonButton
            text={'로그아웃'}
            icon={null}
            onClick={() => {
              navigate('/logout')
            }}
          />
        </Box>
      </Menu>
    </Box>
  )
}
