import {makeStyles} from "@mui/styles";
import {useTheme} from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: useTheme().spacing(5),
    display: "flex",
    justifyContent:'center',
    alignItems:'center',
    fontFamily: ['JejuGothic', 'Roboto']
  },
  logo: {
    cursor: "pointer",
    color:'#000',
    textDecoration: 'none',
    fontSize:'18px !important',
    "&:hover": {
      opacity: 0.8
    },
    fontSize:'18px',
    fontFamily: "'JejuGothic', 'Roboto' !important"
  },
  logoImg: {
    "&:hover": {
      opacity:0.5
    },
  },
  link: {
    display:'flex',
    textDecoration: "none",
    color: "#000",
    fontSize: "16px",
    marginLeft: useTheme().spacing(5),
    "&:hover": {
      opacity: 0.8
    },
    fontFamily: ['JejuGothic', 'Roboto']
    
  },
  userName: {
    textDecoration: "none",
    color: "#000",
    fontSize: "16px",
    marginLeft: useTheme().spacing(5),
    cursor:'default',
    fontFamily: ['JejuGothic', 'Roboto']
  },
  schoolName: {
    textDecoration: "none",
    color: "#000",
    fontSize: "18px !important",
    cursor:'default',
    fontFamily: ['JejuGothic', 'Roboto']
  },
  home: {
    textDecoration: "none",
    color: "#212121",
    fontSize: "16px",
    fontFamily: ['JejuGothic', 'Roboto']
  },
  sideMenu: {

  }
}));

export default useStyles
