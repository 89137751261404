import React, {useState, useEffect, useMemo, Fragment, useRef, forwardRef, useImperativeHandle} from 'react';
import {
  Grid, Divider, Typography, useMediaQuery, useTheme, Container, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button } from '@mui/material'
import DaumPostcodeEmbed, {useDaumPostcodePopup} from 'react-daum-postcode';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

const AddrPopup = forwardRef((props, ref) => {
  const {title, component, windowWidth, callback} = props
  const [open, set_open] = useState(false)
  const addrRef= useRef({})  
  const handleOpen = () => {
    set_open(true)
  };

  const handleClose = () => {
    if(callback) {
      callback(addrRef.current)
    }
    addrRef.current = {}
    set_open(false)
  }

  useImperativeHandle(ref, () =>({
    open: () => {
      handleOpen()
    },
    close: () => {
      handleClose()
    },
    addr: () => {
      return addrRef.current
    }
  }))

  const handleComplete = (data) => {
    console.log('addr data=>', data)
    /*
    console.log('addr data=>', data)
    let fullAddress = data.address;
    let extraAddress = ''
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    addrRef.current = fullAddress
    */
    addrRef.current = data
    handleClose()
  };
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth= {windowWidth ?? 'sm' }
      >
        <DialogTitle style={{justifyContent:'center', alignItems:'center'}}>
          <Typography variant='h5' align='center' style={{fontWeight:'bold'}}>
            {title}
          </Typography>
          <Divider sx={{mt:1}}/>
        </DialogTitle>
        <DialogContent>
          <DaumPostcodeEmbed onComplete={handleComplete} />
        </DialogContent>
        <DialogActions style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})

export default AddrPopup
