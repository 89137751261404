import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'
import { Grid, Typography, Button, Container, useMediaQuery, useTheme, Divider } from '@mui/material'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined'
import HubOutlinedIcon from '@mui/icons-material/HubOutlined'
import { companyPhone } from '@util/string'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import { useSelector } from 'react-redux'
const CustomerProcessPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  return (
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
          paddingBottom: 50,
          minHeight: '75vh',
        }}
      >
        <Container>
          <Grid container style={{ alignItems: 'center' }} spacing={2}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
            >
              <Typography variant='h5' style={{textAlign:'center',fontWeight:'bold'}}>처리 시간</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography style={{fontWeight:'bold'}}>구분</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{fontWeight:'bold'}}>오전접수</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{fontWeight:'bold'}}>오후접수</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              {semuCodes.map((item, idx) => (
                <Grid container key={idx}>
                  <Grid item xs={4}>
                    <Typography style={{fontWeight:'bold'}}>{item.name}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={[]}>{item.prss1}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={[]}>{item.prss2}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              {restCodes.map((item, idx) => (
                <Grid container key={idx}>
                  <Grid item xs={4}>
                    <Typography style={{fontWeight:'bold'}}>{item.name}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={[]}>{item.prss1}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={[]}>{item.prss2}</Typography>
                  </Grid>
                </Grid>
              ))}
              
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default CustomerProcessPage
