import React, { useState, useEffect, useMemo, Fragment, useRef, forwardRef, useImperativeHandle } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import DaumPostcodeEmbed, { useDaumPostcodePopup } from 'react-daum-postcode'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { nanoid } from 'nanoid'
import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk'
import {comma} from '@util/helper'
// const clientKey = 'test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm'


const PaymentPopup = forwardRef((props, ref) => {
  const { title, component, windowWidth, order, orderTitle, callback, onPrepare, clientKey } = props
  const [open, set_open] = useState(false)
  const widgetRef = useRef(null)
  const handleOpen = () => {
    set_open(true)
    loadWidget()
  }

  const handleClose = () => {
    if (callback) {
      // callback()
    }
    set_open(false)
  }

  useImperativeHandle(ref, () => ({
    open: () => {
      handleOpen()
    },
    close: () => {
      handleClose()
    },
  }))

  const handleComplete = (data) => {
    handleClose()
  }

  const loadWidget = async () => {
    const customerKey = nanoid()
    const price = order.amount
    const paymentWidget = await loadPaymentWidget(clientKey, customerKey)
    paymentWidget.renderPaymentMethods('#payment-widget', price)
    widgetRef.current = paymentWidget
  }

  const succcesUrl = `${window.location.origin}/order/success`
  const failUrl = `${window.location.origin}/order/fail`
  const handlePay = async () => {
    try {
      await widgetRef.current?.requestPayment({
        orderId: order.customId,
        orderName: orderTitle,
        customerName: order.name,
        customerMobilePhone: order.phone,
        succcesUrl: succcesUrl,
        failUrl: failUrl
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={windowWidth ?? 'sm'}>
        <DialogTitle style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='h5' align='center' style={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Divider sx={{ mt: 1 }} />
        </DialogTitle>
         
        <DialogContent style={{ minHeight: '50vh' }}>
          <Typography style={{fontWeight:'bold', textAlign:'center'}}>결제금액:{comma(order.amount)} </Typography>
          <div id='payment-widget'></div>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button onClick={handleClose}>닫기</Button>
          <Button variant='contained' onClick={async () => {
            // await handlePay()

            try {
              const ret = await onPrepare()
              if(!ret) {
                return
              }

              await widgetRef.current?.requestPayment({
                orderId: order.orderNo,
                orderName: orderTitle,
                customerName: order.name,
                customerMobilePhone: order.phone,
                successUrl: `${window.location.origin}/order/success`,
                failUrl: `${window.location.origin}/order/fail`,
              });
            } catch (error) {
              console.error(error);
            }
            
          }}>결제하기</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default PaymentPopup
