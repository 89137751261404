import TextField from "@mui/material/TextField";

import WebService from "../util/webService";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { getRandomInt, printVariable, searchSchool } from "../util/helper";
import {
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  Menu,
  Typography,
} from "@mui/material";
import useDelegate from "@hooks/useDelegate";
import { Fragment, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle  } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FlatButton from "@components/FlatButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import useIndicator from "@hooks/useIndicator"
import Indicator from "@components/Indicator";
import useAlert from '@hooks/useAlert'
import useSnackbar from '@hooks/useSnackbar';
import Dialog from '@mui/material/Dialog';
import ConfirmDialog from "@components/ConfirmDialog";
import { detectBrowser } from "@util/helper";
let mediaRecorder
let recordedBlobs = []

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    display: "inline-flex",
    flexDirection: "row",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    padding: "2px",
  },
  menuItem: {
    padding: "6px 16px",
  },
}));

const getSupportedMimeTypes = () => {
  const possibleTypes = [
    'video/webm;codecs=vp9,opus',
    'video/webm;codecs=vp8,opus',
    'video/webm;codecs=h264,opus',
    'video/mp4;codecs=h264,aac',
  ];
  return possibleTypes.filter(mimeType => {
    return MediaRecorder.isTypeSupported(mimeType);
  });
}

const Dialog2 = forwardRef((props, ref) => {
  const [open, set_open] = useState(false)
  useImperativeHandle(ref, () => ({
    openDialog: () => {
      set_open(true)
    },
    closeDialog: () => {
      set_open(false)
    }
  }))

  return (
    <>
      <Dialog open={open} >
        <Button onClick={() => {
          console.log('clicked.')
          set_open(false)
        }}>
          Close
        </Button>
        <Typography>Hello</Typography>
      </Dialog>
      {/* <Button onClick={() => {openRef.current = true} }>Open Dialog</Button> */}
    </> 
  )
})

const Menu2 = forwardRef((props, ref) => {
  const [anchorEl, set_anchorEl] = useState(null)
  useImperativeHandle(ref, () =>({
    openMenu: (event) => {
      event.currentTarget.getBoundingClientRect()
      set_anchorEl(event.currentTarget)
    },
    closeMenu: () => {
      set_anchorEl(null) 
    }
  }))
  
  const btnRef = useRef()
  return (
    <>
     <ConfirmDialog
        btn={<Button ref={btnRef} color={'error'} style={{display:'none'}}>삭제</Button>}
        title={'주의'}
        content={'하시겠습니까?'}
        bc={'error'}
        preHandle={ async () => {
          return true
        }}
        perform={() => {
          console.log('OK')
          set_anchorEl(null)
        }}
      />    
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => set_anchorEl(null)} 
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }} 
      >
        <Button 
          onClick = {() => {
            btnRef.current.click()
            set_anchorEl(null)
          }} 
        >
          Click to Close
        </Button>
      </Menu>
    </>
  )
})

const ScreenRecording = () => {
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const recorderRef = useRef()
  const chunksRef = useRef([]) 
  const {alert, renderAlert} = useAlert()
  const download = () => {
    const blob = new Blob(recordedBlobs, {type: 'video/webm'})
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = 'test'
    document.body.appendChild(a)
    a.click()
  
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100)
  }

  const startRecording = async () => {
    const availBrowsers = ['Chrome', 'Edge', 'Opera']
    const browser = detectBrowser()
    const emsg = '브라우저 버전이 화면녹화를 지원하지 않습니다.'
    // if(!availBrowsers.includes(browser.name) ) {
    //   alert('현재화면 녹화를 지원하지 않는 브라우저 입니다. Chrome/Edge/Opera 브라우저를 사용하세요')
    //   return
    // }

    if(browser.name === 'Chrome' && +browser.majorVersion < 94) {
      alert(emsg)
      return
    }
    if(browser.name === 'Edge' && +browser.majorVersion < 94) {
      alert(emsg)
      return
    }
    if(browser.name === 'Opera' && +browser.majorVersion < 80) {
      alert(emsg)
      return
    }

    if(mediaRecorder) {
      return
    }
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({preferCurrentTab:true})
      console.log('stream =>', stream)
      setMediaStream(stream);

      const recorder = new MediaRecorder(stream);
      recorderRef.current = recorder
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => {
        chunksRef.current.push(event.data)
      };

      recorder.onstop = (event) => {
        const blob = new Blob(chunksRef.current, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'test'
        document.body.appendChild(a)
        a.click()
      
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 1000)

        chunksRef.current = []
        setMediaStream(null);
        setMediaRecorder(null);
        recorderRef.current = null
      };

      recorder.start();
    } catch (error) {
      alert('화면을 녹화할 수 없습니다. 컴퓨터 설정을 통해 브라우저에 화면 녹화 권한을 주어야 합니다.')
      console.error('Error starting recording:', error);
    }
  }

  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stop()
      // mediaRecorder.stop();
    }
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
  }

  return (
    <div>
      <button disabled={mediaRecorder} onClick={startRecording}>Start Recording</button>
      <button disabled={!mediaRecorder} onClick={stopRecording}>Stop Recording</button>
      {renderAlert()}
    </div>
  )
}

export default function TestPage() {
  console.log('TestPage rendering........')

  const [openSnackbar, renderSnackbar] = useSnackbar()
  const {alert, renderAlert} = useAlert()
	const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const dialogRef = useRef()
  const [count, set_count]  = useState(0)
	const handleKeyPress = (event) => {
		if (event.key === "Escape") {
      closeIndicator()
		}
	}
  const countRef = useRef(0)
  const menuRef = useRef()
  const [person, set_person] = useState({name:'', age:''})


  const initMedia = async(constraints) => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia(constraints);
      window.stream = stream;

    } catch (e) {
      console.error('navigator.getUserMedia error:', e);
    } 
  }

	const { activeRow, errors, updateState, handleChange, handleSubmit, factor } = useDelegate({
		initialValues: { ...person},
		validate: async(n, v) => {}
	})
  
 
  const handleDataAvailable = (event) => {
    recordedBlobs.push(event.data)
  }

  const download = () => {
    const blob = new Blob(recordedBlobs, {type: 'video/webm'})
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = 'test'
    document.body.appendChild(a)
    a.click()
  
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100)
  }

  const startRecording = async () => {
    const constraints = {
      video: {
        width: 1280, height: 720
      }
    }

    await initMedia(constraints)
    recordedBlobs = []
    const mimeType = getSupportedMimeTypes()[0]
    const options = {mimeType}

    try {
      mediaRecorder = new MediaRecorder(window.stream, options);
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      return;
    }
    console.log('Created MediaRecorder', mediaRecorder, 'with options', options);

    mediaRecorder.onstop = (event) => {
      console.log('Recorder stopped: ', event);
      console.log('Recorded Blobs: ', recordedBlobs);
      download()
    }
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start();
    console.log('MediaRecorder started', mediaRecorder);
  }

  const stopRecording = () => {
    if(mediaRecorder) {
      mediaRecorder.stop()
      mediaRecorder = null
    }
  }

  useEffect(() => {
    // setInterval(() => {
    //   countRef.current = countRef.current + 1
    //   set_count(countRef.current)
    // }, 1000)
  },[])

	useEffect(() => {
		document.addEventListener("keydown", handleKeyPress)
		return () => {
			document.removeEventListener("keydown", handleKeyPress)
		}
	}, [])

  const printBrowser = () => {
    console.log('????')
    const browser = detectBrowser()
    console.log('browser=>', browser)
  } 
  
  return (
    <div>
      <div>
        count:{count}
      </div>
      <div>
        <Button onClick={() => openIndicator()}>Open Loading</Button>
        <Button onClick={() => alert('Hello', () => {console.log('hello222')}) }>Open Alert</Button>
        <Button onClick={() => openSnackbar('Hello') }>Open Snakbar</Button>
        <Button onClick={() => dialogRef.current.openDialog()}>Open Dialog</Button>
        <Button onClick={(e) => menuRef.current.openMenu(e)}>Open Menu</Button>
        <ConfirmDialog
            btn={<Button color={'error'} >WHERE AM I</Button>}
            title={'주의'}
            content={'하시겠습니까?'}
            bc={'error'}
            preHandle={ async () => {
              return true
            }}
            perform={() => {
              console.log('OK')
            }}
         />   

        <Button onClick={() => printBrowser() }>detectBrowser</Button>
      </div>
      <div>
        <Button onClick={() => startRecording()}>start record</Button>
        <Button onClick={() => stopRecording()}>stop record</Button>
      </div>
      <div>
        <TextField label = '이름' name='name'
          value={activeRow['name']}
          onChange={async (e) => {
            handleChange(e, () => updateState())
          }}
        />
      </div>
      <ScreenRecording />
      {renderAlert()}
      {renderSnackbar()}
      <Dialog2 ref={dialogRef} />
      <Menu2 ref={menuRef} />
    </div>
    )
    
}
