import React, {Fragment, useEffect} from "react";
import { Outlet, Link } from "react-router-dom";
import { getAction } from "../util/helper";
// redux
import { useSelector, useDispatch } from 'react-redux';
import codeReducer from "../redux/reducers/codeReducer";
import { setCodes } from "../redux/actions/codeAction";
import codeGroupReducer from "../redux/reducers/codeGroupReducer";
import { setCodeGroups } from "../redux/actions/codeGroupAction";
import WebService from "../util/webService";
import { Navigate } from "react-router-dom";
import { setSchools } from '../redux/actions/schoolAction';
import { useNavigate } from "react-router-dom";


const RootPage = () => {
  // redux
  const dispatch = useDispatch()
  const account = useSelector(state => state.accountReducer)

  const navigate = useNavigate()

  const fetchCodes = async() => {
    const url = '/code'
    const resp = await WebService.get(url)
    if(resp.repCode != 'ack') {
      console.log('error=>', resp.repMessage)
    }
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    console.log('records=>', records)
    return records
  }

  const onLoad = async() => { }

  useEffect(() => {
    onLoad()
    navigate('/welcome')
  }, [])

  return (
    <Fragment></Fragment>
  )
};

export default RootPage;
