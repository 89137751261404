import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { useLocation, BrowserRouter, Routes, Route, Link, useNavigate, Navigate, Outlet } from 'react-router-dom'
import Layout from './pages/Layout'
import LoginPage from './pages/LoginPage'
import AdminHomePage from './pages/HomePage'
import UserPage from './pages/UserPage'
import CodePage from './pages/CodePage'
import NoPage from './pages/NoPage'
import TestPage from './pages/TestPage'
import { Store } from './redux/store'
import { Persistor } from './redux/store'
import AdminDrawer from '@components/AdminDrawer'
import ManagerDrawer from './components/ManagerDrawer'
import EmptyDrawer from './components/EmptyDrawer'
import WelcomeDrawer from '@components/WelcomeDrawer'
import RegisterPage from './pages/RegisterPage'
import RootPage from './pages/RootPage'
import { PersistGate } from 'redux-persist/integration/react'
import RegisterUserPage from '@pages/register/register-user.page'
import PublicDrawer from '@components/PublicDrawer'
import PublicDrawer2 from '@components/PublicDrawer2'
import AgmtPage from '@pages/AgmtPage'
import ProfileDrawer from '@components/ProfileDrawer'
import ProfileUserPage from '@pages/profile/profile-user.page'
import ProfileAgmtPage from '@pages/profile/profile-agmt.page'
import WelcomeIndexPage from '@pages/welcome/welcome-index.page'
import WelcomeEcertage from '@pages/welcome/welcome-ecert.page'
import WelcomeEmailPage from '@pages/welcome/welcome-email.page'
import PublicFindIdPage from '@pages/public/public-find.id.page'
import PublicFindPasswordPage from '@pages/public/public-find.password.page'
import PublicNoticePage from '@pages/public/public-notice.page'
import ProfileLeavePage from '@pages/profile/profile-leave.page'
import LogoutPage from '@pages/LogoutPage'
import { createTheme, ThemeProvider, CssBaseline, Container, Typography } from '@mui/material'
import { appVersion } from '@util/appVersion'
import { setVersion } from '@redux/actions/versionActioin'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { CONTEXT_PATH } from '@util/constant'
import { isAdmin, isRepManager, isManager, isDirectManager } from '@util/helper'
import WelcomeEcertPage from '@pages/welcome/welcome-ecert.page'
import ProfileUserPartDetail from '@pages/profile/profile-user.part.detail'
import ProfileUserPartResume from '@pages/profile/profile-user.part.resume'
import ProfileUserPartSummary from '@pages/profile/profile-user.part.summary'
import ProfileUserPartCareer from '@pages/profile/profile-user.part.career'
import ProfileUserPartSchool from '@pages/profile/profile-user.part.school'
import ProfileUserPartSkill from '@pages/profile/profile-user.part.skill'
import ProfileUserPartLicense from '@pages/profile/profile-user.part.license'
import ProfileInterestPage from '@pages/profile/profile-interest.page'
import AskDrawer from '@components/AskDrawer'
import AskSemuDrawer from '@components/AskSemuDrawer'
import AskRestDrawer from '@components/AskRestDrawer'
import CustomerDrawer from '@components/CustomerDrawer'
import CustomerWorktimePage from '@pages/customer/customer-worktime.page'
import CustomerProcessPage from '@pages/customer/customer-process.page'
import CustomerFeePage from '@pages/customer/customer-fee.page'
import CustomerGongjiPage from '@pages/customer/customer-gongji.page'
import CustomerTermsPage from '@pages/customer/customer-terms.page'
import CustomerRefundPage from '@pages/customer/customer-refund.page'
import MyDrawer from '@components/MyDrawer'
import MyIndexPage from '@pages/my/my-index.page'
import SemuPage from '@pages/ask/semu/semu.page'
import navigationService from '@util/navigationService'
import OrderDrawer from '@components/OrderDrawer'
import OrderIndexPage from '@pages/order/order-index.page'
import RestPage from '@pages/ask/rest/rest.page'
import MyOrderViewPage from '@pages/my/my-order.view.page'
import MyBasketViewPage from '@pages/my/my-basket.view.page'
import ScrollToTopOnNavigation from '@components/ScrollToTopNavigation'
import AdminIndexPage from '@pages/admin/admin-index.page'
import AdminOrderViewPage from '@pages/admin/admin-order.view.page'
import OrderSuccess from '@pages/order/order-success'
import OrderFail from '@pages/order/order-fail'
import MyBasketPage from '@pages/my/my-basket.page'

Array.prototype.take = function (count) {
  if (count >= this.length) {
    return this.slice() // Return a copy of the entire array
  } else {
    return this.slice(0, count)
  }
}

Array.prototype.drop = function (count) {
  if (count >= this.length) {
    return []
  } else {
    return this.slice(count)
  }
}

Array.prototype.takeRight = function (count) {
  if (count >= this.length) {
    return this.slice()
  } else {
    return this.slice(-count)
  }
}

String.prototype.take = function (count) {
  if (!Number.isInteger(count) || count < 0) {
    return ''
  }
  return this.slice(0, count)
}

String.prototype.drop = function (count) {
  if (!Number.isInteger(count) || count < 0) {
    return ''
  }
  return this.slice(count)
}

String.prototype.takeRight = function (count) {
  if (!Number.isInteger(count) || count < 0) {
    return ''
  }
  return this.slice(-count)
}

const theme = createTheme({
  typography: {
    fontSize: 14, // Adjust the base font size
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        size: 'small', // Set the default size to "small"
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'middle',
        variant: 'outlined',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // Set elevation to 0
          border: '1px solid silver', // Set border to 1px solid silver
        },
      },
    },
    // MuiGrid: {
    //   styleOverrides: {
    //     root: {
    //       display:'flex',
    //       alignItems:'center',
    //     }
    //   }
    // },
  },
  palette: {
    primary: {
      main: gs.colors.main,
      bar: gs.colors.bar,
    },
  },
  // spacing: 8,
  // You can add more customizations to the theme here
})

const VersionWatcher = () => {
  const version = useSelector((state) => state.versionReducer)
  const dispatch = useDispatch()
  const clearCache = () => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name)
        })
      })
    }
  }

  useEffect(() => {
    console.log(`VersionWatcher mounted appVersion:${appVersion} version:${version}`)

    if (!version) {
      clearCache()
    } else {
      if (version !== appVersion) {
        clearCache()
      }
    }

    dispatch(setVersion(appVersion))
  }, [])

  return <React.Fragment></React.Fragment>
}

const AdminRoute = ({ children }) => {
  const account = useSelector((state) => state.accountReducer)
  return isAdmin(account) ? <Outlet /> : <Navigate to='/logout' />
}

const ManagerRoute = ({ children }) => {
  const schola = useSelector((state) => state.scholaReducer)
  const account = useSelector((state) => state.accountReducer)
  if (isManager(account, schola.id)) {
    return <Outlet />
  }

  if (isRepManager(account, schola.id)) {
    return <Outlet />
  }

  if (isAdmin(account)) {
    return <Outlet />
  }

  if (isDirectManager(account, schola.id)) {
    return <Outlet />
  }

  return <Navigate to='/logout' />
}

const NavigateSetter = () => {
  navigationService.navigate = useNavigate()
  return null
}

const App = () => {
  const account = useSelector((state) => state.accountReducer)
  useEffect(() => {
    console.log(`App mounted`)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <VersionWatcher />
      <BrowserRouter basename={CONTEXT_PATH}>
        <ScrollToTopOnNavigation />
        <NavigateSetter />
        <Routes>
          <Route path='/' element={<RootPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/logout' element={<LogoutPage />} />
          <Route path='/home' element={<NoPage />} />
          <Route path='/notice' element={<PublicNoticePage />} />
          <Route path='/admin' element={<AdminRoute />}>
            <Route path='' element={<AdminDrawer />}>
              <Route index element={<AdminIndexPage/>} />
              <Route path='order/view' element={<AdminOrderViewPage/>} />
            </Route>
          </Route>

          <Route path='/register' element={<PublicDrawer />}>
            <Route path='user' element={<RegisterUserPage />} />
          </Route>
          <Route path='/public' element={<PublicDrawer />}>
            <Route path='find/id' element={<PublicFindIdPage />} />
            <Route path='find/password' element={<PublicFindPasswordPage />} />
          </Route>
          <Route path='/public2' element={<PublicDrawer2 />}>
            <Route path='notice/:noticeId' element={<PublicNoticePage />} />
          </Route>
          <Route path='/profile' element={<WelcomeDrawer />}>
            <Route index element={<ProfileUserPage />} />
            <Route path='user' element={<ProfileUserPage />} />
            <Route path='user/detail' element={<ProfileUserPartDetail />} />
            <Route path='user/resume' element={<ProfileUserPartResume />} />
            <Route path='user/summary' element={<ProfileUserPartSummary />} />
            <Route path='user/career' element={<ProfileUserPartCareer />} />
            <Route path='user/school' element={<ProfileUserPartSchool />} />
            <Route path='user/skill' element={<ProfileUserPartSkill />} />
            <Route path='user/license' element={<ProfileUserPartLicense />} />
            <Route path='interest' element={<ProfileInterestPage />} />
            <Route path='agmt' element={<ProfileAgmtPage />} />
            <Route path='leave' element={<ProfileLeavePage />} />
          </Route>
          <Route path='/welcome' element={<WelcomeDrawer />}>
            <Route index element={<WelcomeIndexPage />} />
            <Route path='index' element={<WelcomeIndexPage />} />
            <Route path='ecert' element={<WelcomeEcertPage />} />
            <Route path='email/:emailCertId/:certNum' element={<WelcomeEmailPage />} />
          </Route>
          <Route path='/ask/semu' element={<AskSemuDrawer />}>
            <Route index element={<SemuPage />} />
            <Route path=':docCode' element={<SemuPage />} />
          </Route>
          <Route path='/ask/rest' element={<AskRestDrawer />}>
            <Route index element={<RestPage/>} />
            <Route path=':docCode' element={<RestPage/>} />
          </Route>
          <Route path='/order' element={<OrderDrawer />}>
            <Route index element={<OrderIndexPage />} />
            <Route path='success' element={<OrderSuccess/>} />
            <Route path='fail' element={<OrderFail/>} />
          </Route>
          <Route path='/customer' element={<CustomerDrawer />}>
            <Route index element={<CustomerWorktimePage />} />
            <Route path='worktime' element={<CustomerWorktimePage />} />
            <Route path='process' element={<CustomerProcessPage />} />
            <Route path='fee' element={<CustomerFeePage />} />
            <Route path='gongji' element={<CustomerGongjiPage />} />
            <Route path='terms' element={<CustomerTermsPage />} />
            <Route path='refund' element={<CustomerRefundPage />} />
          </Route>
          <Route path='/my' element={<MyDrawer />}>
            <Route index element={<MyIndexPage />} />
            <Route path='index' element={<MyIndexPage />} />
            <Route path='basket' element={<MyBasketPage />} />
            <Route path='order/view' element={<MyOrderViewPage />} />
            <Route path='basket/view' element={<MyBasketViewPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
