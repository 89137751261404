import axios from 'axios'
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'

import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Alert,
  Paper,
} from '@mui/material'
import WebService from '@util/webService'
import {
  copyProperties,
  encodeParams,
  getAction,
  isStringNullOrEmpty,
  printVariable,
  searchCompany,
} from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux'
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton'
import useSnackbar from '@hooks/useSnackbar'
import MyTableHead from '@components/MyTableHead'
import useIndicator from '@hooks/useIndicator'
import Indicator from '@components/Indicator'
import MyDialog from '@components/MyDialog'
import Box from '@mui/material/Box'
import useAlert from '@hooks/useAlert'
// pagination
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Avatar, Grid, Container } from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { searchSchool } from '@util/helper'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ConfirmDialog from '@components/ConfirmDialog'
import useDelegate from '@hooks/useDelegate'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'

const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

const ProfileInterestPage = () => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const defRecord = {}
  defRecord.schoolList = []
  const defTerms = {}

  const [dataList, set_dataList] = useState([])
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const { alert, renderAlert } = useAlert()
  const dgHandler = useState(false)
  const [terms, _set_terms] = useState(defTerms)
  const termsRef = useRef(terms)

  // redux
  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const schools = useSelector((state) => state.schoolReducer)
  const account = useSelector((state) => state.accountReducer)

  const [record, set_record] = useState({})
  const [tabValue, set_tabValue] = useState('1')
  const navigate = useNavigate()
  const contentRef = useRef(null)
  const set_terms = (data) => {
    termsRef.current = data
    _set_terms(data)
  }

  useEffect(() => {
    // onLoad()
  }, [])

  const onLoad = async () => {
    openIndicator()

    const url = `/user/id/${account.user.id}`
    const resp = await WebService.get(url, {})
    if (resp.repCode === 'reject') {
      navigate('/login')
    }

    if (resp.repCode === 'ack') {
      const repMessage = JSON.parse(resp.repMessage)

      const newRecord = JSON.parse(repMessage.record)
      newRecord.oldPassword = ''
      newRecord.newPassword = ''
      newRecord.newPassword2 = ''

      set_record(newRecord)
      // activeRow.loginId = newRecord.loginId
      // copyProperties(newRecord, valuesRef.current)
      updateState(newRecord)
    }
    closeIndicator()
  }

  ////
  const onSubmit = async () => {
    const url = `/user/password/new/${account.user.id}`
    const data = {
      password: activeRow.oldPassword,
      newPassword: activeRow.newPassword,
    }

    const resp = await WebService.post(url, data)
    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
    } else {
      alert('수정되었습니다.')
    }
  }

  const handleTabChange = (event, newValue) => {
    set_tabValue(newValue)
    contentRef.current.scrollTop = 0
  }

  const validate = (name, value) => {
    let err = ''
    if (name === 'oldPassword') {
      if (isStringNullOrEmpty(value)) {
        err = '기존 비밀번호를 입력해 주세요.'
      }
    }

    if (name === 'newPassword') {
      const passwordRegex =
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
      if (!passwordRegex.test(value)) {
        err = '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요.'
      }
    }

    if (name === 'newPassword2') {
      if (value === '') {
        err = '패스워드가 일치하는지 확인해 주세요.'
      }
      if (factor['newPassword'] !== value) {
        err = '패스워드가 일치하는지 확인해 주세요(2).'
      }
    }

    // if(name === 'phone') {
    //   const phoneRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
    //   if(!phoneRegex.test(value)) {
    //     err = '올바른 휴대폰번호 형식이 아닙니다.'
    //   }
    // }

    return err
  }

  const { activeRow, errors, updateState, handleChange, handleSubmit, factor } =
    useDelegate({
      initialValues: { ...record },
      onSubmit: onSubmit,
      validate: validate,
    })

  return (
    <Container component="main" maxWidth="md" style={{marginTop:30}}>
      <Typography variant="h4" style={{ fontWeight: 'bold' }}>
        내 채용 정보
      </Typography>
      <TabContext value={tabValue}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            overflowX: 'auto',
          }}
        >
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label="저장됨" value="1" />
              <Tab label="지원완료" value="2" />
              <Tab label="면접" value="3" />
              <Tab label="보관됨" value="4" />
            </TabList>
        </Box>
        <div ref={contentRef}>
          <TabPanel value="1">
            <Typography>저장 목록</Typography>
          </TabPanel>
          <TabPanel value="2">
            <Typography>지원 목록</Typography>
          </TabPanel>
          <TabPanel value="3">
            <Typography>면접 목록</Typography>
          </TabPanel>
          <TabPanel value="4">
            <Typography>보관 목록</Typography>
          </TabPanel>
        </div>
      </TabContext>

      <div>
        {renderSnackbar()}
        {renderIndicator()}
        {renderAlert()}
      </div>
    </Container>
  )
}

export default ProfileInterestPage
