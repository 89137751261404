import axios from 'axios'
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'

import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Alert,
  Paper,
} from '@mui/material'
import WebService from '@util/webService'
import {
  copyProperties,
  encodeParams,
  getAction,
  isStringNullOrEmpty,
  printVariable,
  searchCompany,
} from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux'
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton'
import useSnackbar from '@hooks/useSnackbar'
import MyTableHead from '@components/MyTableHead'
import useIndicator from '@hooks/useIndicator'
import Indicator from '@components/Indicator'
import MyDialog from '@components/MyDialog'
import Box from '@mui/material/Box'
import useAlert from '@hooks/useAlert'
// pagination
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Avatar, Grid, Container } from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import ConfirmDialog from '@components/ConfirmDialog'
import { setAccount } from '@redux/actions/accountAction'
import useDelegate from '@hooks/useDelegate'
const Boxs = styled(Box)`
  padding-bottom: 40px !important;
`
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

const ProfileUserPartLicense= () => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [license, set_license] = useState(location.state?.license ?? {})
  console.log('license=>', license)
  const [dataList, set_dataList] = useState([])
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const { alert, renderAlert } = useAlert()
  const dgHandler = useState(false)
  const [terms, _set_terms] = useState({})
  const termsRef = useRef(terms)

  // redux
  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)

  const [record, set_record] = useState({})

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const set_terms = (data) => {
    termsRef.current = data
    _set_terms(data)
  }

  const onSave = () => {
    if (license.name && license.name.length > 0) {
      const newAccount = { ...account }
      if (!newAccount.user.licenseList) {
        newAccount.user.licenseList= []
      }
      newAccount.user.licenseList.push(license)
      dispatch(setAccount(newAccount))
      console.log('go back....')
      navigate(-1)
    } else {
      openSnackbar('면허증/자격증명이 입력되지 않았습니다.')
    }
  }

  useEffect(() => {
    // onLoad()
  }, [])

  const onLoad = async () => { }

  ////
  const onSubmit = async () => { }

  const validate = (name, value) => { }

  return (
    <Container component="main" maxWidth="md">
      {/* backward */}
      <Grid container spacing={2} style={{marginTop:10}}>
        <Grid item xs={12}>
          <ArrowBackOutlinedIcon
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(-1)}
          />
        </Grid>
      </Grid>
      {/* title */}
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            면허증/자격증 
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            defaultValue={license.name}
            label="면허증/자격증"
            onChange={(e) => {
              license.name= e.target.value
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            defaultValue={license.statCode}
            label="졸업구분"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox onChange={null} color="primary" />}
          label="만료되지 않음"
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid item xs={2}>
              <Typography>시작 년월</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField fullWidth label="년" />
            </Grid>
            <Grid item xs={5}>
              <TextField fullWidth label="월" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid item xs={2}>
              <Typography>종료 년월</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField fullWidth label="년" />
            </Grid>
            <Grid item xs={5}>
              <TextField fullWidth label="월" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* button */}
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button fullWidth size="large" variant="contained" onClick={onSave}>
            저장
          </Button>
        </Grid>
      </Grid>

      <div>
        {renderSnackbar()}
        {renderIndicator()}
        {renderAlert()}
      </div>
    </Container>
  )
}

export default ProfileUserPartLicense
