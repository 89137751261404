import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'
import { Grid, Typography, Button, Container, useMediaQuery, useTheme, Divider } from '@mui/material'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined'
import HubOutlinedIcon from '@mui/icons-material/HubOutlined'
import { billiard, companyPhone } from '@util/string'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import { useSelector } from 'react-redux'
import { refund } from '@util/string'
import { comma } from '@src/util/helper'
const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  spacious: {
    padding: 10,
  },
  x: {
    color: 'red',
  },
})

const CustomerRefundPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const bizCodes = codes.filter((r) => r.codeGroupId === 'D004')
  const estCodes = codes.filter((r) => r.codeGroupId === 'D006')
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  return (
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
          paddingBottom: 50,
          minHeight: '75vh',
        }}
      >
        <Container>
          <Grid container style={{ alignItems: 'center' }} spacing={2}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
            >
              <Typography variant='h5' style={{textAlign:'center',fontWeight:'bold'}}>환불 안내</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography  style={{whiteSpace:'pre-wrap'}}>
                {refund}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default CustomerRefundPage
