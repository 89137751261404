import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import DrawerComponent from './Drawer';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { useTheme } from '@mui/material/styles';
// redux
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: useTheme().spacing(5),
    display: "flex",
  },
  logo: {
    flex: 1,
    cursor: "pointer",
    color:'#fff',
    textDecoration: 'none',
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: useTheme().spacing(5),
    "&:hover": {
      color: "yellow",
      // borderBottom: "1px solid white",
    },
  },
  home: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    // marginLeft: theme.spacing(5),
    // "&:hover": {
    //   color: "yellow",
    // },
  },
}));

const drawerWidth = 240;

export default function EmptyDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  // redux
  const codeGroups = useSelector(state => state.codeGroupReducer)
  const codes = useSelector(state => state.codeReducer)
  const schools = useSelector(state => state.schoolReducer)
  const account = useSelector(state => state.accountReducer)
  const schola = useSelector(state => state.scholaReducer)


  return (
    <Box sx={{ display: 'flex',}}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1,}}>
        <Toolbar sx={{ backgroundColor:'#214499' }}>
          <Typography variant="h6" noWrap component="div" className={classes.logo} component={Link} to='/welcome'>
            블록체인 채용 플랫폼
          </Typography>
          <Typography variant="h6" noWrap component="div" className={classes.logo} style={{textAlign:'center'}}>
            매니저 관리
          </Typography>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div style={{flex:1, display:'flex', justifyContent:'flex-end'}}>
              <div className={classes.logo}></div>
              <div className={classes.navlinks}>
                <Link to="/profile" className={classes.link}>
                  My
                </Link>
                <Link to="/logout" className={classes.link}>
                  로그아웃
                </Link>
              </div>
            </div>
          )}
        </Toolbar>
        
      </AppBar>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
