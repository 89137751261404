import {
  LocationOnOutlined,
  ArticleOutlined,
  LanguageOutlined,
  CorporateFareOutlined,
  AddRoadOutlined,
  OtherHousesOutlined,
  ZoomOutMapOutlined,
  AssessmentOutlined,
  DirectionsCarFilledOutlined,
  PrecisionManufacturingOutlined,
} from '@mui/icons-material'
import navigationService from './navigationService'
const navigate = navigationService.navigate

// prettier-ignore
export const semuMenu= [
  {id:'DS01', label:'소득금액증명', to:'/ask/semu/vat', icon:LocationOnOutlined, onClick: function() {
   navigate(this.to)
 }},
  {id:'DS02', label:'부가가치세과세표준증명', to:'/ask/semu/vat', icon:LocationOnOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS03', label:'면세사업자수입금액증명', to: `/ask/corp`, icon:ArticleOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS04', label:'표준재무제표증명', to:`/ask/toji`, icon:LanguageOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS05', label:'납부내역증명(납세사실증명)', to:'/ask/building', icon:CorporateFareOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS06', label:'납세증명서(국세완납증명)', to:'/ask/cadastral', icon:AddRoadOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS07', label:'사업자증록증명', to:'/ask/frequent', icon:LocationOnOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS08', label:'휴업사실증명', to:'/ask/plan', icon:ZoomOutMapOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS09', label:'폐업사실증명', to:'/ask/jiga', icon:AssessmentOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS10', label:'폐업사실증명', to:'/ask/jiga', icon:AssessmentOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS11', label:'사업자등록증재발급', to:'/ask/house', icon:OtherHousesOutlined, onClick: function() {
   navigate(this.to)
 }},
 {id:'DS12', label:'지방세납세증명서(지방세완납증명)', to:'/ask/car', icon:DirectionsCarFilledOutlined, onClick: function() {
   navigate(this.to)
 }},
]

// prettier-ignore
export const restMenu = [
  {id:10, label:'자주찾는민원일괄신청', to:'/ask/frequent', icon:LocationOnOutlined, onClick: function() {
    navigate(this.to)
  }},
  
  {id:11, label:'부동산등기사항증명서', to:'/ask/realty', icon:ArticleOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:12, label:'법인등기사항증명서', to:'/ask/corp', icon:ArticleOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:13, label:'토지(임야)대장', to:'/ask/toji', icon:LanguageOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:14, label:'건축물대장', to:'/ask/building', icon:CorporateFareOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:15, label:'지적(임야)도', to:'/ask/cadastral', icon:AddRoadOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:16, label:'전체민원',to:'/ask/frequent',  icon:LocationOnOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:17, label:'토지이용계획확인서',to:'/ask/plan', icon:ZoomOutMapOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:18, label:'개별공시지가확인서',to:'/ask/jiga',  icon:AssessmentOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:19, label:'주택가격확인서',to:'/ask/house',  icon:OtherHousesOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:20, label:'자동차증록원부',to:'/ask/car',  icon:DirectionsCarFilledOutlined, onClick: function() {
    navigate(this.to)
  }},
  {id:21, label:'건설기계등록원부', to:'/ask/machine', icon:PrecisionManufacturingOutlined, onClick: function() {
    navigate(this.to)
  }},
]

export const adminMenu = [
  {id:0, label: '민원처리', to: `/admin` },
  {id:1, label: '사용자', to: `/admin` },
  // {id:2, label: '묻고답하기', to: `/my` },
]

export const myMenu = [
  {id:0, label: '나의민원처리 결과', to: `/my` },
  {id:1, label: '민원바구니', to: `/my/basket` },
  // {id:2, label: '묻고답하기', to: `/my` },
]
export const customerMenu = [
  {
    id:0,
    label: '업무시간',
    to: '/customer/worktime',
  },
  {
    id:1,
    label: '배송 및 처리 시간',
    to: '/customer/process',
  },
  {
    id:2,
    label: '요금',
    to: '/customer/fee',
  },
  {
    id:3,
    label: '환불',
    to: '/customer/refund',
  },
  // {
  //   id:3,
  //   label: '공지사항',
  //   to: '/customer/gongji',
  // },
  // {
  //   id:4,
  //   label: '이용약관/개인정보처리방침',
  //   to: '/customer/terms',
  // },
]

export const welcomeMenu = [
  {id:0, label: '세무민원신청', visible: true, to: `/ask/semu` },
  {id:1, label: '일반빈원신청', visible: true, to: `/ask/rest` },
  {id:2, label: '민원처리결과', visible: true, to: `/my` },
  {id:3, label: '고객센터', visible: true, to: `/customer` },
  {id:4, label: '마이페이지', visible: true, to: `/my` },
]
