import { SET_VERSION } from "@redux/actions/versionActioin";

const initState = ''

const versionReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_VERSION:
      return action.payload
    default:
      return state
  }
}

export default versionReducer