import TextField from '@mui/material/TextField';

const NumericTextField = ({ value, onChange, maxLength, ...otherProps }) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    // Regex to allow only numeric input
    const numericValue = inputValue.replace(/\D/g, '');
    // Invoke the provided onChange function with the numeric value
    onChange(numericValue);
  };

  return (
    <TextField
      value={value}
      onChange={handleChange}
      inputProps={{
        maxLength: maxLength?? null,
        inputMode: 'numeric', // Set input mode to numeric
        pattern: '[0-9]*',    // Allow only numeric characters
      }}
      {...otherProps}
    />
  );
};

export default NumericTextField;
