import React, { useState, useEffect, useMemo, Fragment, useRef, forwardRef, useImperativeHandle } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
} from '@mui/material'
import DaumPostcodeEmbed, { useDaumPostcodePopup } from 'react-daum-postcode'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Agmt from '@components/Agmt'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import Labeller from '@components/Labeller'
import { alignProperty } from '@mui/material/styles/cssUtils'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import MinwonInput from '@components/MinwonInput'
import AgmtOffice from '@components/AgmtOffice'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import SemuPartEdit from '@pages/ask/semu/semu.part.edit'
import { take, takeRight, drop } from 'lodash'
import { showSnackbar } from '@context/SnackbarContext'
import { needPrfTerm } from './semu.util'
import moment from 'moment'
import { description } from '../description'
import { createDoc } from '@pages/common/common'
import { useSelector, useDispatch } from 'react-redux'
import { engRegex } from '@util/helper'
import accountReducer from '@redux/reducers/accountReducer'
const alignCenter = {
  display: 'flex',
  alignItems: 'center',
}

const SemuPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const agmtRef = useRef()
  const officeRef = useRef()
  const { docCode } = useParams()
  const location = useLocation()
  const curLocationRef = useRef(location)
  const account = useSelector((state) => state.accountReducer)
  const doc  = location.state?.pack?.data??  createDoc(docCode, account)
  const [record, set_record] = useState(doc)
  const [compKey, set_compKey] = useState(location.key)
  const [allChecked, set_allChecked] = useState(false)
  const [agmtUpdated, set_agmtUpdated] = useState(false)
  const [officeUpdated, set_officeUpdated] = useState(false)
  const navigate = useNavigate()

  const checkForAll = () => {
    if (agmtRef.current.isAllChecked() && officeRef.current.isAllChecked()) {
      set_allChecked(true)
    } else {
      set_allChecked(false)
    }
  }

  const validate = () => {
    if (docCode === 'DS07') {
      if (!record.bizCode) {
        showSnackbar('사업자관련증명 정보 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
    }

    // 영문
    if(record.hanYn == 'N') {
      if(!engRegex.test(record.engName))  {
        showSnackbar('영문성명 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if(!engRegex.test(record.addr1))  {
        showSnackbar('영문 기본주소 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }

      if(!engRegex.test(record.addr2))  {
        showSnackbar('영문 상세주소 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }

      if(record.sepCode == 'S002' || record.sepCode == 'S003') {
        if(!engRegex.test(record.companyName))  {
          showSnackbar('영문상호 를(을) 입력해 주세요.', theme.palette.warning.dark)
          return
        }
      }
    }

    if (docCode === 'DS09') {
      if (!record.bizDocReason) {
        showSnackbar('사업자등록증 재발급 교부사유 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
    }

    if (docCode === 'DS11') {
      if (!record.factCode) {
        showSnackbar('사실확인 정보 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
    }

    // common
    if (record.hanYn == 'Y' && !record.name) {
      showSnackbar('성명 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }

    if (record.hanYn == 'N' && !record.engName) {
      showSnackbar('영문성명 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }

    //

    if (record.docCode === 'DS10' && record.sepCode === 'S003') {
      const corpNum = `${record.corpNum1}${record.corpNum2}`
      if (corpNum.length !== 13) {
        showSnackbar('법인등록번호 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      else {
        record.corpNum = corpNum
      }
    } else {
      if (!record.civilNum1) {
        showSnackbar('주민등록번호 앞자리 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!record.civilNum2) {
        showSnackbar('주민등록번호 뒷자리 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      const civilNum = `${record.civilNum1}${record.civilNum2}`
      if (civilNum.length != 13) {
        showSnackbar('주민등록번호 자릿수를 정확히 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      record.civilNum = civilNum
    }

    //

    if (!record.phoneDial) {
      showSnackbar('전화번호 국번 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (record.phone1.length < 4) {
      showSnackbar('전화번호 앞자리를 정확히 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (record.phone2.length < 4) {
      showSnackbar('전화번호 뒷자리를 정확히 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    record.phone = `${record.phoneDial}${record.phone1}${record.phone2}`

    if (record.sepCode == 'S002' || record.sepCode === 'S003') {
      //
      if (!record.companyName) {
        showSnackbar('상호 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }

      //
      const bizNum = `${record.bizNum1}${record.bizNum2}${record.bizNum3}`
      if (!bizNum) {
        showSnackbar('사업자등록번호 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }

      if (bizNum.length != 10) {
        showSnackbar('사업자등록번호를 정확히 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      record.bizNum = bizNum

      //
      if (!record.addr1) {
        showSnackbar('기본주주소 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!record.addr2) {
        showSnackbar('상세 주소 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
    }

    if (record.docCode === 'DS06' || record.docCode === 'DS10') {
      if (!record.mockCode) {
        showSnackbar('발급목적 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
    }

    if (record.docCode === 'DS10') {
      if (record.mockCode === 'KL01') {
        if (!record.payer) {
          showSnackbar('대금지급자 를(을) 입력해 주세요.', theme.palette.warning.dark)
          return
        }
      }
      if (record.mockCode === 'KL02') {
        if (!record.migNum) {
          showSnackbar('이주번호 를(을) 입력해 주세요.', theme.palette.warning.dark)
          return
        }
        if (!record.migDate) {
          showSnackbar('해외이주 신고일 를(을) 입력해 주세요.', theme.palette.warning.dark)
          return
        }
      }
      if (record.mockCode === 'L001') {
        if (!record.trustAddr1) {
          showSnackbar('신탁부동산 기본주소 를(을) 입력해 주세요.', theme.palette.warning.dark)
          return
        }
        if (!record.trustAddr2) {
          showSnackbar('신탁부동산 상세주소 를(을) 입력해 주세요.', theme.palette.warning.dark)
          return
        }
      }
    }

    if(record.docCode !== 'DS11') {
      if (!record.aimCode) {
        showSnackbar('용도 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
    }

    if (!record.giveYn) {
      showSnackbar('열람/제출 를(을) 선택해 주세요.', theme.palette.warning.dark)
      return
    }

    if (!record.hanYn) {
      showSnackbar('한영선택 를(을) 해 주세요.', theme.palette.warning.dark)
      return
    }
    if (!record.civilnumYn) {
      showSnackbar('주민등록번호 공개여부 를(을) 선택해 주세요.', theme.palette.warning.dark)
      return
    }
    if (!record.addrYn) {
      showSnackbar('주소 공개여부 를(을) 선택해 주세요.', theme.palette.warning.dark)
      return
    }

    if (needPrfTerm.includes(docCode)) {
      if (!record.prfFrom) {
        showSnackbar('증경기간 시작일 를(을) 선택해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!record.prfTo) {
        showSnackbar('증경기간 종료일 를(을) 선택해 주세요.', theme.palette.warning.dark)
        return
      }
      if (record.prfFrom && record.prfTo) {
        const prfFrom = moment(record.prfFrom, 'YYYY-MM-DD')
        const prfTo = moment(record.prfTo, 'YYYY-MM-DD')
        if (!prfFrom.isBefore(prfTo)) {
          showSnackbar('증영기간 시작일은 종료일보다 전이어야 합니다.', theme.palette.warning.dark)
          return
        }
      }
    }


    // lastly
    /*
    if (!record.file) {
      showSnackbar('신분증등 사본 를(을) 첨부해 주세요.', theme.palette.warning.dark)
      return
    }

    if (!record.signature) {
      showSnackbar('위임인 서명 를(을) 해 주세요.', theme.palette.warning.dark)
      return
    }
    */
    if (!allChecked) {
      showSnackbar('모든 약관에 동의해야 서비스 이용이 가능합니다.', theme.palette.warning.dark)
      return
    }

    const new_data = { ...record }
    new_data.phone = `${record.phoneDial}${record.phone1}${record.phone2}`
    if (record.prfFrom) {
      new_data.prfFrom = moment(record.prfFrom, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')
    }
    if (record.prfTo) {
      new_data.prfTo = moment(record.prfTo, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')
    }

    if (record.migDate) {
      new_data.migDate = moment(record.migDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')
    }

    return new_data
    //
  }

  const onSubmitOld = async () => {
    const ret = validate()
    console.log('ok...')
    if (ret) {
      navigate('/order', {
        state: {
          docs: [ret],
          pack: {
            url: window.location.pathname,
            data: ret
          }
        }
      })
      // navigate('/order', {
      //   state: {
      //     docs: [ret],
      //   },
      // })
    }
  }

  const onOrder = async () => {
    const ret = validate()
    if (!ret) {
      return
    }

    if (!account?.user?.id) {
      navigate('/login', {
        state: {
          doc: record,
          pack: {
            url:window.location.pathname,
            data: record,
            furl: '/order'
          } 
        },
      })
    } else {
      navigate('/order', {
        state: {
          docs: [ret],
          pack: {
            url: window.location.pathname,
            data: ret,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (curLocationRef.current.key != location.key) {
      curLocationRef.current = location
      set_compKey(location.key)
      set_record(createDoc(docCode, account))
      window.scrollTo(0, 0)
    }
  }, [location])

  useEffect(() => {
    if (agmtUpdated || officeUpdated) {
      if (agmtRef.current.isAllChecked() && officeRef.current.isAllChecked()) {
        set_allChecked(true)
      } else {
        set_allChecked(false)
      }
    }
  }, [agmtUpdated, officeUpdated])

  return (
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
        }}
      >
        <Container>
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
            >
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {description.find((r) => r.id === docCode)?.content}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography style={{ fontWeight: 'bold' }}>{description.find((r) => r.id === docCode)?.title}</Typography>
            </Grid>
            <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <Button startIcon={<AddOutlinedIcon />} variant='contained'> */}
              {/*   추가 */}
              {/* </Button> */}
            </Grid>
          </Grid>
        </Container>
        <Divider style={{ margin: 10 }} />
        <SemuPartEdit key={compKey} record={record} set_record={set_record} docCode={docCode} />
      </div>

      <Container style={{ marginTop: 30 }}>
        <FormControlLabel
          label={<Typography style={{ fontWeight: 'bold' }}>아래 모든 약관에 동의합니다.</Typography>}
          control={
            <Checkbox
              checked={allChecked}
              color='primary'
              onChange={(e) => {
                agmtRef.current.toggle(e.target.checked)
                officeRef.current.toggle(e.target.checked)
                set_allChecked(e.target.checked)
              }}
            />
          }
        />
      </Container>

      <Container style={{ marginTop: 30 }}>
        <Agmt ref={agmtRef} checkForAll={checkForAll} key={compKey} />
      </Container>

      <Container style={{ marginTop: 30 }}>
        <AgmtOffice ref={officeRef} checkForAll={checkForAll} key={compKey} />
      </Container>

      <Container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button variant='contained' style={{ margin: 5 }} onClick={onOrder}>
          주문하기
        </Button>
        <Button variant='contained' style={{ margin: 5 }}
          disabled={!account?.user?.id  || account?.user?.statCode === 'N001' }
        >
          민원바구니 담기
        </Button>
      </Container>
    </>
  )
}

export default SemuPage
