import axios from 'axios'
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'

import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Alert,
  Paper,
} from '@mui/material'
import WebService from '@util/webService'
import {
  copyProperties,
  encodeParams,
  getAction,
  isStringNullOrEmpty,
  printVariable,
  searchCompany,
} from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux'
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton'
import useSnackbar from '@hooks/useSnackbar'
import MyTableHead from '@components/MyTableHead'
import useIndicator from '@hooks/useIndicator'
import Indicator from '@components/Indicator'
import MyDialog from '@components/MyDialog'
import Box from '@mui/material/Box'
import useAlert from '@hooks/useAlert'
// pagination
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Avatar, Grid, Container } from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { searchSchool } from '@util/helper'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ConfirmDialog from '@components/ConfirmDialog'
import useDelegate from '@hooks/useDelegate'
const Boxs = styled(Box)`
  padding-bottom: 40px !important;
`
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

const ProfileUserPartDetail = () => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'
  const fields = [
    {
      field: 'id',
      placeholder: 'ID',
      type: 'text',
      value: -1,
      visible: false,
      canSrch: false,
      selList: [],
    },
    {
      field: 'name',
      placeholder: '이름',
      type: 'text',
      value: '',
      visible: true,
      canSrch: true,
      selList: [],
    },
    {
      field: 'statCode',
      placeholder: '',
      type: 'text',
      value: 'S001',
      visible: false,
      canSrch: false,
      selList: [],
    },
    {
      field: 'phone',
      placeholder: '전화',
      type: 'number',
      value: '',
      visible: true,
      canSrch: true,
      selList: [],
    },
    {
      field: 'loginId',
      placeholder: '로그인ID',
      type: 'text',
      value: '',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'password',
      placeholder: '비밀번호',
      type: 'text',
      value: '',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'password2',
      placeholder: '비밀번호확인',
      type: 'text',
      value: '',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'phoneCertYn',
      placeholder: '폰인증여부',
      type: 'text',
      value: 'N',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'deviceToken',
      placeholder: '디바이스토큰',
      type: 'text',
      value: '',
      visible: false,
      canSrch: false,
      selList: [],
    },
    {
      field: 'email',
      placeholder: '이메일',
      type: 'text',
      value: '',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'emailCode',
      placeholder: '이메일 인증코드',
      type: 'text',
      value: '',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'address',
      placeholder: '주소',
      type: 'text',
      value: '',
      visible: false,
      canSrch: false,
      selList: [],
    },
    {
      field: 'address2',
      placeholder: '주소',
      type: 'text',
      value: '',
      visible: false,
      canSrch: false,
      selList: [],
    },
    {
      field: 'zipCode',
      placeholder: '주소',
      type: 'text',
      value: '',
      visible: false,
      canSrch: false,
      selList: [],
    },
    {
      field: 'phone',
      placeholder: '휴대폰',
      type: 'text',
      value: '',
      visible: false,
      canSrch: false,
      selList: [],
    },
    {
      field: 'regTime',
      placeholder: '등록일시',
      type: 'text',
      value: '',
      visible: false,
      canSrch: false,
      selList: [],
    },
    {
      field: 'uptTime',
      placeholder: '수정일시',
      type: 'text',
      value: '',
      visible: false,
      canSrch: false,
      selList: [],
    },
  ]

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const visibleColumns = fields.filter((col) => col.visible)
  const defRecord = {}
  fields.forEach((d) => {
    defRecord[d.field] = d.value
  })
  defRecord.schoolList = []
  const defTerms = {}
  fields
    .filter((col) => col.canSrch)
    .map((d) => d.field)
    .forEach((d) => (defTerms[d] = ''))

  const [dataList, set_dataList] = useState([])
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const { alert, renderAlert } = useAlert()
  const dgHandler = useState(false)
  const [terms, _set_terms] = useState(defTerms)
  const termsRef = useRef(terms)

  // redux
  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const schools = useSelector((state) => state.schoolReducer)
  const account = useSelector((state) => state.accountReducer)

  const [record, set_record] = useState({})

  const navigate = useNavigate()
  const set_terms = (data) => {
    termsRef.current = data
    _set_terms(data)
  }

  useEffect(() => {
    // onLoad()
  }, [])

  const onLoad = async () => {
  }

  ////
  const onSubmit = async () => {
  }

  const validate = (name, value) => {
  }

  return (
    <Container component="main" maxWidth="md">
      {/* backward */}
      <Grid container spacing={2} style={{marginTop:10}}>
        <Grid item xs={12 }>
          <ArrowBackOutlinedIcon  onClick={() => navigate(-1)}/>
        </Grid>
      </Grid>
      {/* 연락처 */}
      <Grid container spacing={2} style={{marginTop:10}}>
        <Grid item xs={12}>
          <Typography variant='h5' style={{fontWeight:'bold'}}>연락처</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth defaultValue={account.user.name}  label='이름'/>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography>
                {account.user.loginId}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{display:'flex', justifyContent:'flex-end'}}>
              수정 &nbsp; <ArrowForwardOutlinedIcon/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth defaultValue={account.user.phone} 
            label='전화번호'
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel control={<Checkbox defaultChecked />} label="내전화번호 표시" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>
            이 확인란을 선택하여 양식을 제출함으로써 귀하는 입력하신 전화번호의 주요 사용자이며 가입자임을 확인하며 위에 제공한 전화번호로 본 회사와 본 회사를 이용하는 채용기업의 통화(음성 합성 또는 사전 녹음 음성 포함), 문자 및 카카오톡메시지를 받는 것에 동의합니다.
          </Typography>
        </Grid>
      </Grid>

      {/* 주소 */}
      <Grid container spacing={2} style={{marginTop:20}}>
        <Grid item xs={12}>
          <Typography variant='h5' style={{fontWeight:'bold'}}>주소</Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField fullWidth defaultValue={account.user.address}  label='주소'/>
        </Grid>
        <Grid item xs={2} style={{display:'flex', justifyContent:'flex-end'}}>
          <Button size='middle'>검색</Button>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth defaultValue={account.user.address2}  label='상세주소'/>
        </Grid>
      </Grid>

      {/* 저장 */}
      <Grid container spacing={2} style={{marginTop:20}}>
        <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
          <Button fullWidth size='large' variant='contained'>저장</Button>
        </Grid>
      </Grid>

      <div>
        {renderSnackbar()}
        {renderIndicator()}
        {renderAlert()}
      </div>
    </Container>
  )
}

export default ProfileUserPartDetail
