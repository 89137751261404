import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import {comma} from '@src/util/helper'
import useAlert from "@hooks/useAlert";
import { useSearchParams } from 'react-router-dom'
const OrderFail = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const location = useLocation()
  const curLocationRef = useRef(location)
  const {alert, renderAlert} = useAlert()

  const onLoad = async () => {}

  const [searchParams] = useSearchParams()
  console.log('failed')
  console.log('searchParams=>', searchParams)
  for (const [key, value] of searchParams.entries()) {
    console.log(`searchParams => ${key}: ${value}`)
  }

  return (
    <div style={{ paddingTop: isMobile ? 50 : 100 }}>
      {renderAlert()}
      <Container maxWidth='xs' style={{}}>
        <Grid container spacing={10}>
          <Grid item xs={12} >
            <Typography variant='h5' style={{fontWeight:'bold', textAlign:'center', color:theme.palette.error.light}}>
              결제 에러
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography style={{textAlign:'center'}}>
              결제가 실패하였습니다. 잠시 후 다시 시도해 주세요
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default OrderFail
