import axios from 'axios';
import React, {useState, useEffect, useMemo, Fragment, useRef} from 'react';

import { FormControl, InputLabel, Input, FormHelperText, Alert } from '@mui/material';
import WebService from '../util/webService'
import {encodeParams, getAction} from '../util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux';
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Checkbox, FormGroup, FormControlLabel, Typography, Snackbar, TablePagination, TextField,withStyles,InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '../components/FlatButton';
import useSnackbar from '../hooks/useSnackbar';
import MyTableHead from '../components/MyTableHead';
import useIndicator from '../hooks/useIndicator';
import Indicator from '../components/Indicator';
import MyDialog from '../components/MyDialog';
import useForm from '../hooks/useForm';
import Box from '@mui/material/Box';

// pagination
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import moment from 'moment';
import {
  Avatar,
  Grid,
  Container,
} from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Boxs = styled(Box)`
      padding-bottom: 40px !important;
    `;


const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;

const AgmtPage = () => {
  const defUrl = '/agmt'
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'

  const fields = [
    {field: 'id', placeholder: '아이디',    type:'text', value:-1, visible: true, disabled: false, canSrch:false, selList:[]},
    {field: 'name', placeholder: '약관제목',    type:'text', value:'', visible: true, disabled: false, canSrch:false,selList:[]},
    {field: 'file', placeholder: '파일', type:'text', value:'', visible: true, disabled: false, canSrch:false,selList:[]},
    {field: 'mandYn', placeholder: '필수여부', type:'select', value:'', visible: true, disabled: false, canSrch:false,selList:['Y', 'N']},
    {field: 'priority', placeholder: '우선순위', type:'text', value:'', visible: true, disabled: false, canSrch:false,selList:[]},
    {field: 'statCode', placeholder: '상태', type:'text', value:'', visible: true, disabled: false, canSrch:false,selList:[]},
    {field: 'remark', placeholder: '비고', type:'text', value:'', visible: true, disabled: false, canSrch:false,selList:[]},
    {field: 'uptTime', placeholder: '수정일시', type:'text', value:'', visible: false, disabled: false, canSrch:false,selList:[]},
    {field: 'regTime', placeholder: '등록일시', type:'text', value:'', visible: false, disabled: false, canSrch:false,selList:[]},
  ]

  const visibleColumns = fields.filter(col => col.visible)
  const defRecord = {}
  fields.forEach(d => {
    defRecord[d.field] = d.value
  })
  defRecord.schoolList = []  
  const defTerms = {}
  fields.filter(col => col.canSrch).map(d => d.field).forEach(d => defTerms[d] = '')
  const [record, set_record] = useState(defRecord)
  const [dataList, set_dataList] = useState(null)
  const [fieldList, set_fieldList] = useState([])
  const [schoolList, set_schoolList] = useState([])
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const dgHandler = useState(false)
  const [selIdx, set_selIdx] = useState(-1)
  const [pageNum, set_pageNum] = useState(0)
  const [pageSize, set_pageSize] = useState(10)
  const [totalCount, set_totalCount] = useState(0)
  const [pageInfo, set_pageInfo] = useState({pageNum:0, pageSize: 10})
  const [terms, _set_terms] = useState(defTerms)
  const termsRef = useRef(terms)

  // redux
  const codeGroups = useSelector(state => state.codeGroupReducer)
  const codes = useSelector(state => state.codeReducer)

  const set_terms = data => {
    termsRef.current = data
    _set_terms(data)
  }

  useEffect(() => {
    onLoad()
  }, [pageInfo])

  const onLoad = async() => {
    
    openIndicator()
    let url = defUrl
    const newTerms = {}
    Object.keys(termsRef.current).forEach(key => {
      if(termsRef.current[key] !== '') {
        newTerms[key] = termsRef.current[key]
      }
    })
    
    let resp = await WebService.get(url, {pageNum:pageInfo.pageNum, pageSize:pageInfo.pageSize, terms:JSON.stringify(newTerms)})

    if(resp.repCode === 'ack') {
      const repMessage = JSON.parse(resp.repMessage)
      const records = JSON.parse(repMessage.records)
      const totalCount = repMessage.totalCount
      set_totalCount(totalCount)
      records.forEach(r => {
        r.isDetail = false
        r.schoolRltnList = []
      })

      set_dataList(records)
    }

    const newFields = [...fields]
    set_fieldList(newFields)
    closeIndicator()
  }


  const onDetail = async(id) => {
    // const newDataList = [...dataList]
    // const user = newDataList.find(d => d.id === id)

    // if(!user.isDetail) { 
    //   const url = `${defUrl}/school/rltn`
    //   const resp = await WebService.get(url, {userId: id})
      
    //   if(resp.repCode === 'ack') {
    //     const resultMessage = JSON.parse(resp.repMessage)
    //     const records = JSON.parse(resultMessage.records)
    //     user.schoolRltnList = []
    //     records.forEach(r => {
    //       user.schoolRltnList.push(r)
    //     })
    //   }
    // }

    // user.isDetail = !user.isDetail 
    // set_dataList(newDataList)

    // TODO
    dgHandler[1](true)
  }

  const onDelete = async(id) => {
    const url = defUrl
    const resp = await WebService.delete(url, {id: id})
    if(resp.repCode === 'ack') {
      onLoad()
      openSnackbar('삭제되었습니다.')
    }
    else {
      openSnackbar(resp.repMessage)
    }
    
  }
  
  const onCreate = () => {
    const now = moment().format(dateFormat)
    const newRecord = {...defRecord, regTime: now, uptTime: now, schoolRltnList:[]}
    set_record(newRecord)
    dgHandler[1](true)
  }

  const onSearch = () => {
    onLoad()
  }

  const onClear = () => {
    const obj = {}
    Object.keys(terms).forEach(k => {
      obj[k] = ''
    })
    set_terms(obj)
  }

  const handleChangePage = (event, newPage) => {
    set_pageInfo({...pageInfo, pageNum: newPage })
  }
  
  const handleChangeRowsPerPage = (event) => {
    
    const pageSize = parseInt(event.target.value, 10)
    set_pageInfo({pageNum:0, pageSize: pageSize})
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  

  const DgContent = () => {

    const onSubmit = async () => {
      const url = `${defUrl}`
      const data = activeRow
      const resp = await WebService.post(url, data)

      
      if(resp.repCode === 'ack') {
        const repMessage = JSON.parse(resp.repMessage)
        const newRecord = JSON.parse(repMessage.record)

        if(newRecord.id !== undefined) {
          const event = {target:{name:'id', value:newRecord.id}}
          onLoad()
          activeRow.id = newRecord.id
          // handleChange(event)
           handleChange()
          openSnackbar('저장되었습니다.')
        }
        else {
          openSnackbar('에러 발생')
        }
       
      }
      else {
        openSnackbar('에러 발생')
      }
    }

    const validate = () => {}

    
    const [
      activeRow,
      errors,
      formLoading,
      handleChange,
      handleSubmit, 
     ] = useForm({
      initialValues: {...record}, 
      onSubmit: onSubmit,
      validate: validate,
    });
    const [emailError, setEmailError] = useState('');
    const [passwordState, setPasswordState] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [nameError, setNameError] = useState('');
    const [registerError, setRegisterError] = useState('');
    
    // const Boxs = styled(Box)`
    //   padding-bottom: 40px !important;
    // `;
    

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    return (
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography component="h1" variant="h5">
            기본정보
          </Typography>
          <Boxs component="form" noValidate sx={{ mt: 3 }}>
            <FormControl component="fieldset" variant="standard">
              <Grid container spacing={2}>
                {
                  fieldList.map((col, index) => {
                    if(col.visible) {
                      return(
                        <Grid item xs={3} key={col.field}>
                          <TextField
                            size='small'
                            required
                            fullWidth
                            name={col.field}
                            label={col.placeholder}
                            value={activeRow[col.field]}
                            onChange={handleChange}
                          />
                        </Grid>
                      )
                    }
                  })
                }
              </Grid>
            </FormControl>
          <FormHelperTexts>{registerError}</FormHelperTexts>
        </Boxs>
        
        {/* <Button disabled={true} variant='contained' color='primary' size='small' onClick={onSubmit} sx={{mt: 10}}>저장</Button> */}
        <Button variant='contained' color='primary' size='small' onClick={onSubmit} sx={{mt: 10}}>저장</Button>
      </Box>
    </Container>
    );
  }
    

  const dg_title = '약관 정보'
  const memo_DgContent = useMemo(()=> {return <DgContent />}, [record])
  if(dataList != null) {
    return (
      <div >
        <MyDialog title={dg_title} component={memo_DgContent} dialogHandler={dgHandler}/>
        <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
          <Typography component="h1" variant="h5" align={'center'} >
            약관 관리
          </Typography>
        </div>
        <div style={{display:'flex', flexDirection:'row'}}>
          {/* <Button disabled={true} variant='contained' color='primary' size='small' onClick={() => {onCreate()}}>생성</Button> */}
          <Button variant='contained' color='primary' size='small' onClick={() => {onCreate()}}>생성</Button>
        </div>
        
        <div style={{paddingTop:20}}>
          <MUITable>
            <MyTableHead >
              <TableRow>
                {
                  fieldList.map((col, index) => {
                    if(col.visible) {
                      return(
                        <TableCell key={index} size='small' key={col.field}>
                          {col.placeholder}
                          {col.canSrch? 
                            ( 
                              <Fragment>
                                  <br/>
                                  <TextField label="" variant="outlined" size='small' inputProps={{style: {fontSize: '0.875em', padding:5, backgroundColor:'#fff'}}} 
                                    value={terms[col.field]}
                                    onChange={(e) => {
                                      const newTerms = {...terms}
                                      newTerms[col.field] = e.target.value
                                      set_terms(newTerms)
                                    }}
                                  />
                              </Fragment>
                            )
                            : (
                              <Fragment>
                              </Fragment>
                            )
                          }
                        </TableCell>
                      )
                    }
                  })
                }
                <TableCell size='small'>
                  {/*<br/>*/}
                  {/*<FlatButton size='small' variant='contained' style={{marginRight:5}} color='primary' onClick={onSearch}>검색</FlatButton>*/}
                  {/*<FlatButton size='small' variant='contained'  color='primary' onClick={onClear}>지우기</FlatButton>*/}
                </TableCell>
              </TableRow>
            </MyTableHead>
            <TableBody>
              {
                dataList.map((row, index) => {
                  return(
                    <Fragment key={index}>
                      <TableRow onClick={()=> {
                        set_selIdx(index)
                        set_record(dataList[index])
                      }}>
                          {
                            fieldList.map((col, index2) => {
                              if(col.visible) {
                                return(
                                  <TableCell key={index2} size='small'>
                                    {row[col.field]}
                                  </TableCell>
                                )
                              }
                            })
                          }
                          <TableCell size='small'>
                            <FlatButton variant='outlined' size='small'  style={{marginRight:5}} onClick={() => onDetail(row.id)}>보기</FlatButton>
                            {/* <FlatButton disabled={true} variant='outlined'  color='error' size='small' onClick={() => onDelete(row.id)}>삭제</FlatButton> */}
                            <FlatButton variant='outlined'  color='error' size='small' onClick={() => onDelete(row.id)}>삭제</FlatButton>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                  )
                })
              }
            </TableBody>
            <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                colSpan={visibleColumns.length + 1}
                count={totalCount}
                rowsPerPage={pageInfo.pageSize}
                page={pageInfo.pageNum}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    // return '' + from + '-' + to + ' 개 ' + count
                    return `${from} - ${to} of ${count}`
                  }
                }
                labelRowsPerPage={'페이지당 갯수'}
              />
            </TableRow>
          </TableFooter>
          </MUITable>

           {renderSnackbar()}
           {renderIndicator()}
            <dg_content />
          </div>
      </div>
    );
  }
  
}

export default AgmtPage
