import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'

const boldFont = {
  fontWeight: 'bold',
}

const MyIndexPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const curLocationRef = useRef(location)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const statCodes = codes.filter(r => r.codeGroupId=== 'C002')
  const { alert, renderAlert } = useAlert()
  const dgRef = useRef()
  const [html, set_html] = useState('')
  const fetchOrders = async () => {
    const url = `/order/1`
    const resp = await WebService.get(url)
    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    console.log('resp=>>>>>>>>>>>>>>>>>>>>>>>', resp)
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    return records
  }

  const downloadTmpl = async (docCode) => {
    const url = `/doc/download/tmpl/${docCode}`
    const response = await axios.get(url, { responseType: 'arraybuffer' })
    const buffer = response.data
    return buffer
  }

  const generateHtmlFromWorksheet = (worksheet) => {
    let html = '<table>'

    worksheet.eachRow((row, rowNumber) => {
      html += '<tr>'
      row.eachCell((cell, colNumber) => {
        html += `<td>${cell.value}</td>`
      })
      html += '</tr>'
    })

    html += '</table>'

    return html
  }

  const dataUrlToFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const onDownload = async (doc) => {
    const buffer = await downloadTmpl(doc.docCode)
    const workbook = new ExcelJS.Workbook()
    await workbook.xlsx.load(new Uint8Array(buffer))

    const url = `/doc/data/map/${doc.id}`
    const resp = await WebService.get(url)
    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    const repMessage = JSON.parse(resp.repMessage)
    const dataMap = JSON.parse(repMessage.dataMap)
    const signature = repMessage.signature
    // const sigImage = dataUrlToFile(signature, 'signature')

    console.log('dataMap=>', dataMap)
    const imageId1 = workbook.addImage({
      base64: signature,
      extension: 'png',
    })
    const imageId2 = workbook.addImage({
      base64: signature,
      extension: 'png',
    })

    workbook.eachSheet((worksheet, sheetId) => {
      if (doc.docCode == 'DS10') {
        worksheet.addImage(imageId1, 'Z25:AE26')
        worksheet.addImage(imageId2, 'Y32:AE34')
      } else if (doc.docCode == 'DS11') {
        worksheet.addImage(imageId1, 'BW66:CG69')
        worksheet.addImage(imageId2, 'BW97:CF97')
      } else {
        worksheet.addImage(imageId1, 'AV42:BF42')
        worksheet.addImage(imageId2, 'AV68:BE68')
      }
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell((cell, colNumber) => {
          if (cell.value && typeof cell.value === 'string') {
            if (cell.value.includes('x_')) {
              const oldValue = cell.value
              const newValue = cell.value.split(' ').reduce((acc, cur) => {
                const key = cur.trim()
                if (key.startsWith('x_')) {
                  const v = dataMap[key]
                  if (v === undefined) {
                    console.log(`key:${key} not found`)
                  }

                  return acc + ' ' + dataMap[key] ?? ''
                } else {
                  return acc + ' ' + key
                }
              }, '')
              // console.log('oldValue=>', oldValue)
              // console.log('newValue=>', newValue)
              cell.value = newValue
            }
          }
        })
      })
    })

    // const html = generateHtmlFromWorksheet(workbook.getWorksheet(1))
    // console.log('html=>', html)
    // set_html(html)
    // dgRef.current.open()

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      let filename = `${doc.name}_${doc.docCode}.xlsx`
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  const Minwon = () => {
    return (
      <div>
        <div style={{ height: '100vh' }} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    )
  }

  const onLoad = async () => {
    const records = await fetchOrders()
    if(!records) {
      return
    }
    console.log('records=>', records)
    set_records(records)
  }


  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    if (!account?.user?.id) {
      navigate('/login')
    } else {
      onLoad()
    }
  }, [])

  return (
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
          paddingBottom: 50,
          minHeight: '75vh',
        }}
      >
        {renderAlert()}
        <MyDialog2 title={'Test'} ref={dgRef} component={<Minwon />} windowWidth='md' />
        <Container>
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
            >
              <Typography style={boldFont}>민원처리</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs md>
                  <Typography style={boldFont}>주문번호</Typography>
                </Grid>
                <Grid item xs md>
                  <Typography style={boldFont}>민원종류</Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Typography style={boldFont}>수량</Typography>
                </Grid>
                <Grid item xs md>
                  <Typography style={boldFont}>금액</Typography>
                </Grid>
                <Grid item xs md>
                  <Typography style={boldFont}>신청인</Typography>
                </Grid>
                <Grid item xs md>
                  <Typography style={boldFont}>수신방법</Typography>
                </Grid>
                <Grid item xs={3} md>
                  <Typography style={boldFont}>신청일시</Typography>
                </Grid>
                <Grid item xs md>
                  <Typography style={boldFont}>최종수정일시</Typography>
                </Grid>
                <Grid item xs md>
                  <Typography style={boldFont}>상태</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {records.length > 0
              ? records.map((item, idx) => (
                  <>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        key={idx}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Grid item xs md>
                          <Typography variant='body2'>{item.orderNo}</Typography>
                        </Grid>
                        <Grid item xs md>
                          <Grid
                            container
                            spacing={1}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <Grid item xs={12}>
                              {item.docs.map((doc, idx2) => (
                                <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant='body2'
                                      style={{cursor:'pointer', fontWeight:'bold'}}
                                      onClick={() => {
                                        navigate('/my/order/view', {
                                          state: {
                                            order: item,
                                          },
                                        })
                                      }}
                                    >
                                      {docCodes.find((r) => r.code === doc.docCode).name}
                                    </Typography>
                                  </Grid>
                                  {/* <Grid item xs={6}> */}
                                  {/*   <FlatButton onClick={() => onDownload(doc)}> */}
                                  {/*     <Typography style={{ fontSize: '0.8em' }}>다운</Typography> */}
                                  {/*   </FlatButton> */}
                                  {/* </Grid> */}
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1} md={1}>
                          <Typography variant='body2'>{item.docs.length}</Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>{comma(item.amount)}</Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>
                            {item.name}
                            <br />
                            {item.phone}
                          </Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>
                            {item.recvCodes.split(',').map((it) => recvCodes.find((r) => r.code === it).name)}
                          </Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>
                            {moment(item.regTime).format('YYYY-MM-DD')} <br />
                            {moment(item.regTime).format('HH:mm:ss')}
                          </Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>
                            {moment(item.uptTime).format('YYYY-MM-DD')} <br />
                            {moment(item.uptTime).format('HH:mm:ss')}
                          </Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>{statCodes.find(r => r.code === item.statCode).name}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                ))
              : null}
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default MyIndexPage
