import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'
import { Grid, Typography, Button, Container, useMediaQuery, useTheme, Divider } from '@mui/material'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined'
import HubOutlinedIcon from '@mui/icons-material/HubOutlined'
import { billiard, companyPhone } from '@util/string'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import { useSelector } from 'react-redux'
import { comma } from '@src/util/helper'
const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  spacious: {
    padding: 10,
  },
  x: {
    color: 'red',
  },
})

const CustomerFeePage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const bizCodes = codes.filter((r) => r.codeGroupId === 'D004')
  const estCodes = codes.filter((r) => r.codeGroupId === 'D006')
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  return (
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
          paddingBottom: 50,
          minHeight: '75vh',
        }}
      >
        <Container>
          <Grid container style={{ alignItems: 'center' }} spacing={2}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
            >
              <Typography variant='h5' style={{textAlign:'center',fontWeight:'bold'}}>요금 안내</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography varant='h6' style={{fontWeight:'bold'}} >{billiard} 민원서비스 요금</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography style={{fontWeight:'bold'}}>구분</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{fontWeight:'bold'}}>열람용(원)</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{fontWeight:'bold'}}>제출용(원)</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  {semuCodes.map((item, idx) => (
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography style={{fontWeight:'bold'}}>{item.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        {
                          item.code === 'DS07' ? (
                            bizCodes.map((bc,idx) => (
                              <Typography style={{}}>{comma(bc.note1)}({bc.name})</Typography>
                            ))
                          ) : (
                            <Typography style={[]}>{comma(item.note1)}</Typography>
                          )
                        }
                      </Grid>
                      <Grid item xs={4}>
                        {
                          item.code === 'DS07' ? (
                            bizCodes.map((bc,idx) => (
                              <Typography style={[]}>{comma(bc.note2)}({bc.name})</Typography>
                            ))
                          ) : (
                            <Typography style={[]}>{comma(item.note2)}</Typography>
                          )
                        }
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{marginTop:10, marginBottom:10}}/>
                </Grid>
                <Grid item xs={12}>
                  {restCodes.map((item, idx) => (
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography style={{fontWeight:'bold'}}>{item.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        {
                          item.code === 'DG01' ? (
                            estCodes.map((bc,idx) => (
                              <Typography style={[]}>{comma(bc.note1)}({bc.name})</Typography>
                            ))
                          ) : (
                            <Typography style={[]}>{comma(item.note1)}</Typography>
                          )
                        }
                      </Grid>
                      <Grid item xs={4}>
                        {
                          item.code === 'DG01' ? (
                            estCodes.map((bc,idx) => (
                              <Typography style={[]}>{comma(bc.note2)}({bc.name})</Typography>
                            ))
                          ) : (
                            <Typography style={[]}>{comma(item.note2)}</Typography>
                          )
                        }

                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{marginTop:10, marginBottom:10}}/>
            </Grid>
            <Grid item xs={12}>
              <Typography varant='h6' style={{fontWeight:'bold'}} >{billiard} 발송요금</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
              <Grid item xs={6}>
                <Typography style={{fontWeight:'bold'}}>구분</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{fontWeight:'bold'}}>금액(원)</Typography>
              </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12}>
              {recvCodes.map((item, idx) => (
                <Grid container>
                  <Grid item xs={6}>
                    <Typography style={[]}>{item.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={[]}>{comma(item.note1)}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default CustomerFeePage
