import { useState, useEffect, Fragment, useRef } from "react";
import { Button } from "@mui/material";
import MySnackbar from "@components/MySnackbar";
import { publish } from "@util/event";
import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "@util/constant";

const useSnackbar = () => {
  const messageRef = useRef('')

  const openSnackbar = (msg) => {
    if(msg && msg.length > 0) {
      messageRef.current = msg
      publish(SHOW_SNACKBAR)
    }
  }

  const closeSnackbar = () =>{
    publish(HIDE_SNACKBAR)
  }


  const renderSnackbar = () => {
    return(
      <MySnackbar messageRef={messageRef} />
    )
  }

  // return {open, message, openSnackbar, closeAction}
  return [openSnackbar, renderSnackbar]
}

export default useSnackbar
