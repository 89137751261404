import { useState, useEffect, Fragment, useRef } from "react";
import AlertDialog from "@components/AlertDialog";
import { SHOW_ALERT, HIDE_ALERT } from "@util/constant";
import { publish } from "@util/event";

const useAlert = () => {
  const contentRef = useRef('')
  const callbackRef = useRef(null)

  const alert = (message, newCallback) => {
    contentRef.current = message

    if(newCallback) {
      callbackRef.current = newCallback
    }

    publish(SHOW_ALERT)

  }

  const renderAlert = () => {
    return(
       <AlertDialog contentRef={contentRef} callbackRef={callbackRef}/>
    )
  }

  return {alert: alert, renderAlert: renderAlert}
}

export default useAlert
