import React, { forwardRef, useImperativeHandle, useState, useEffect, useMemo, Fragment, useRef } from 'react'
import {
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  FormControlLabel,
  Radio,
  Checkbox,
  Divider,
  TextField,
} from '@mui/material'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { termsOfPrivate, termsOfId, termsOf14, termsOfAttroney, termsOfPunish } from '@util/string'

const AgmtOffice = forwardRef((props, ref) => {
  const { checkForAll} = props
  const [checkList, set_checkList] = useState([
    {
      id: 0,
      title: '※ 국가 및 지방자치단체의 개인정보 수집 이용 내역(개인정보보호법 제15조)',
      content: termsOfPrivate.replace(/\n/g, '<br/>'),
      checked: false,
      phrase: '위 개인정보 수집 및 이용에 동의합니다.',
    },
    {
      id: 1,
      title: '※ 국가 및 지방자치단체의 개인정보 이용 및 수집안내',
      content: termsOfId.replace(/\n/g, '<br/>'),
      checked: false,
      phrase: '위 개인정보 수집 및 이용에 동의합니다.',
    },
    {
      id: 2,
      title: '※ 위임장',
      content: termsOfAttroney.replace(/\n/g, '<br/>'),
      checked: false,
      phrase: '위임장 제출에 동의합니다.',
    },
  ])

  useImperativeHandle(ref, () => ({
    toggle: (checked) => {
      const new_data = [...checkList]
      new_data.forEach((item) => {
        item.checked = checked
      })
      set_checkList(new_data)
    },
    isAllChecked: () => {
      const checked = checkList.filter(r => r.checked).length
      if(checked === checkList.length) {
        return true
      }
      else {
        return false
      }
    }
  }))

  const onChange = (e, item) => {
    const new_data = [...checkList]
    new_data.find((r) => r.id == item.id).checked = e.target.checked
    set_checkList(new_data)
  }

  useEffect(() => {
    checkForAll()
  }, [checkList])

  return (
    <div>
      <div>
        {checkList.map((item, idx) => (
          <div>
            <div>{item.title}</div>
            {item.content ? (
              <div style={{ border: '1px solid silver', padding: 5 }}>
                <div style={{ maxHeight: 100, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            ) : null}
            <FormControlLabel
              label={item.phrase}
              control={
                <Checkbox
                  checked={checkList.find((r) => r.id === item.id)?.checked}
                  color='primary'
                  onChange={(e) => {
                    onChange(e, item)
                  }}
                />
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
})

export default AgmtOffice
