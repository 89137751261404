import axios from 'axios'
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'

import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Alert,
  Paper,
} from '@mui/material'
import WebService from '@util/webService'
import {
  copyProperties,
  encodeParams,
  getAction,
  isStringNullOrEmpty,
  printVariable,
  searchCompany,
} from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux'
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton'
import useSnackbar from '@hooks/useSnackbar'
import MyTableHead from '@components/MyTableHead'
import useIndicator from '@hooks/useIndicator'
import Indicator from '@components/Indicator'
import MyDialog from '@components/MyDialog'
import Box from '@mui/material/Box'
import useAlert from '@hooks/useAlert'
// pagination
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Avatar, Grid, Container } from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { searchSchool } from '@util/helper'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { setAccount } from '@redux/actions/accountAction';
import ConfirmDialog from '@components/ConfirmDialog'
import useDelegate from '@hooks/useDelegate'
const Boxs = styled(Box)`
  padding-bottom: 40px !important;
`
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { setArgs } from '@craco/craco/dist/lib/args'

const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

const ProfileUserPartSummary= () => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const defTerms = {}
  const [dataList, set_dataList] = useState([])
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const { alert, renderAlert } = useAlert()
  const dgHandler = useState(false)
  const [terms, _set_terms] = useState(defTerms)
  const termsRef = useRef(terms)

  // redux
  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const schools = useSelector((state) => state.schoolReducer)
  const account = useSelector((state) => state.accountReducer)

  const [record, set_record] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [summary, set_summary] = useState(account.user.summary ?? '')
  const set_terms = (data) => {
    termsRef.current = data
    _set_terms(data)
  }

  useEffect(() => {
    // onLoad()
  }, [])

  const onLoad = async () => {
  }

  ////
  const onSubmit = async () => {
  }

  const validate = (name, value) => {
  }

  const onSave = () => {
    if(summary.length > 0)  {
      const newAccount = {...account}
      newAccount.user.summary = summary
      dispatch(setAccount((newAccount)))
      navigate(-1)
    }
    else {
      openSnackbar('데이터가 입력되지 않았습니다.')
    }
  }

  return (
    <Container component="main" maxWidth="md">
      {/* backward */}
      <Grid container spacing={2} style={{marginTop:10}} >
        <Grid item xs={12 }>
          <ArrowBackOutlinedIcon 
            onClick={() => navigate(-1)}/>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{marginTop:10}}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          내 정보 요약
        </Typography>
      </Grid>
      {/* 요약 */}
      <Grid container spacing={2} style={{marginTop:10}}>
        <Grid item xs={12}>
          <TextareaAutosize 
            style={{width:'100%'}}
            minRows={10}
            value={summary}
            onChange={(e) => {
              set_summary(e.target.value)
            }}
          /> 
        </Grid>
      </Grid>

      {/* 저장 */}
      <Grid container spacing={2} style={{marginTop:20}}>
        <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
          <Button fullWidth size='large' variant='contained'
            onClick={onSave}
          >저장</Button>
        </Grid>
      </Grid>

      <div>
        {renderSnackbar()}
        {renderIndicator()}
        {renderAlert()}
      </div>
    </Container>
  )
}

export default ProfileUserPartSummary
