import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  IconButton,
  Select,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Container
} from '@mui/material';
import {findCode, fullCopy, printVariable,download} from '@util/helper';
import WebService from '@util/webService';
import useSnackbar from '@hooks/useSnackbar';
import useIndicator from '@hooks/useIndicator';
import axios from 'axios';
import { Fragment } from 'react';
import moment from 'moment';
import {Grid} from "@mui/material/";
import useDelegate from "@hooks/useDelegate";
import {styled} from "@mui/material/styles";
import useAlert from "@hooks/useAlert";
import { useParams } from 'react-router-dom';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
// quill
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import Hashids from 'hashids';
import { setSchola } from '@redux/actions/scholaAction';

const hashids = new Hashids('', 5)
const PublicNoticePage = () =>  {
  const {noticeId} = useParams()
  const orgId= hashids.decode(noticeId)[0]  
  const {alert, renderAlert} = useAlert()
  const [notice, set_notice] = useState({})
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const dispatch = useDispatch() 
  const fetchNotice = async() => {
    if(!orgId) {
      alert('데이터를 가져올 수 없습니다.')
      return
    }
    const url = `/public/notice/${orgId}`
    openIndicator()
    const resp = await WebService.get(url)
    closeIndicator()
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage) 
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const report = JSON.parse(repMessage.report)
    return report
  }
  const onLoad = async() => {
    const report = await fetchNotice()
    if(report) {
      const schola = report.school
      dispatch(setSchola(schola))
      set_notice(report)
    }
  }

  const handleDownload = async(item) => {
    const url = `/public/notice/download/attch/${item.id}`
    await download(url)
  }
  useEffect(() => {
    onLoad()
  }, [])

  return (
    <Container maxWidth='sm'>
      <Grid container spacing={2} >
        <Grid item xs={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>{notice.title}</Grid>
        <Grid item xs={12}>
          <ReactQuill
            theme="bubble"
            value={notice.content ?? ''}
            readOnly={true}
            name={'content'}
          />
        </Grid>
      </Grid>

      <div>
        { 
          notice.attchs && notice.attchs.length > 0 ? (
            <Typography variant='body2' style={{marginTop:10, marginBottom:10}}> ※ 첨부</Typography>
          ) : null
        }

        {
          notice.attchs ? notice.attchs.map((item, index) =>(
            <div key={index} style={{cursor:'pointer'}} onClick={() => handleDownload(item)}>
              <Typography variant='body2' >
                - {item.name}
              </Typography>
            </div>
          )) : null
        }
      </div>
      {renderSnackbar()}
      {renderIndicator()}
      {renderAlert()}
    </Container>
  )
}

export default PublicNoticePage
