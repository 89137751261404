import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { useTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const MyDialog2 = forwardRef((props, ref) => {
  const { title, component, windowWidth } = props
  const [open, set_open] = useState(false)

  const handleOpen = () => {
    set_open(true)
  }

  const handleClose = () => {
    set_open(false)
  }

  useImperativeHandle(ref, () => ({
    open: () => {
      handleOpen()
    },
    close: () => {
      handleClose()
    },
  }))

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={windowWidth ?? 'xl'}>
        <DialogTitle style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item xs={8}>
              <Typography variant='h5' align='center' style={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => handleClose()}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 1 }} />
        </DialogTitle>
        <DialogContent>{component}</DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  )
})

export default MyDialog2
