import React from 'react';
// import TableHead from '@material-ui/core/TableHead'
// import { withStyles } from '@material-ui/core';
import { TableHead } from '@mui/material';
import { withStyles } from '@mui/styles';

const MyTableHead = withStyles(theme => ({
  root: {
    backgroundColor: '#fafafa'
  }
}))(TableHead);

export default MyTableHead