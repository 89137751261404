import React, {useState, useEffect, useMemo, Fragment, useRef} from 'react';
import {Typography, useMediaQuery, useTheme } from '@mui/material'

const Labeller = ({label, must}) => {
  const theme = useTheme()
  return(
    <div style={{display:'flex'}}>
      <Typography style={{fontWeight:'bold'}}>{label}</Typography>
      {
        must ? (
          <Typography color={theme.palette.secondary.main}>*</Typography>
        ) : null
      } 
    </div>
  )
}

export default Labeller
