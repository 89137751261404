import * as React from "react";
import {useState, useEffect } from "react";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { subscribe, unsubscribe } from "@util/event";
import { SHOW_ALERT, HIDE_ALERT } from "@util/constant";

const AlertDialog = ({contentRef, callbackRef}) => {

  const handleClose = (event, reason) => {
    if(reason && reason === "backdropClick") {
      return
    }
    else {
      if(callbackRef && callbackRef.current) {
        callbackRef.current()
      }
      hideAlert()
    }
  };

  const [open, set_open]  = useState(false) 

  const showAlert = () => {
    if(contentRef.current && contentRef.current.length > 0) {
      set_open(true)
    }
  }

  const hideAlert = () => {
    set_open(false)
    setTimeout(() => {
      contentRef.current = ''
      callbackRef.current = null
    },100)
  }

  useEffect(() => {
    subscribe(SHOW_ALERT, showAlert )
    subscribe(HIDE_ALERT, hideAlert)

    return () => {
      unsubscribe(SHOW_ALERT, showAlert)
      unsubscribe(HIDE_ALERT, hideAlert)
    }
  },[]) 

  return (
    <Dialog
      // open={dialogHandler[0]}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'xs'}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        알림
        <Divider></Divider>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentRef.current}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
