import axios from 'axios';
import React, {useState, useEffect, useMemo, Fragment, useRef} from 'react';

import { FormControl, InputLabel, Input, FormHelperText, Alert, Divider} from '@mui/material';
import WebService from '@util/webService'
import {encodeParams, getAction} from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux';
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Checkbox, FormGroup, FormControlLabel, Typography, Snackbar, TablePagination, TextField,withStyles,InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton';
import useSnackbar from '@hooks/useSnackbar';
import MyTableHead from '@components/MyTableHead';
import useIndicator from '@hooks/useIndicator';
import Indicator from '@components/Indicator';
import MyDialog from '@components/MyDialog';
import useForm from '@hooks/useForm';
import Box from '@mui/material/Box';

// pagination
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import moment from 'moment';
import {
  Avatar,
  Grid,
  Container,
} from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom";
import useAlert from "@hooks/useAlert";
import ProfileAgmtPartView from './profile-agmt.part.view';

const Boxs = styled(Box)`
      padding-bottom: 40px !important;
    `;


const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;

const ProfileAgmtPage = () => {
  // redux
  const codeGroups = useSelector(state => state.codeGroupReducer)
  const codes = useSelector(state => state.codeReducer)
  const schools = useSelector(state => state.schoolReducer)
  const account = useSelector(state => state.accountReducer)

  const navigate = useNavigate()
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'
  const {more} = useParams()
  const fields = [
    {field: 'id', placeholder: 'ID',    type:'text', value:-1, visible: more && (more === 'more'), disabled: false, canSrch:false, selList:[]},
    {field: 'agmtYn', placeholder: '동의여부',    type:'text', value:'', visible: true, disabled: false, canSrch:false,selList:[]},
    {field: 'mandYn', placeholder: '필수여부',    type:'text', value:'', visible: true, disabled: false, canSrch:false,selList:[]},
    {field: 'name', placeholder: '약관명',    type:'text', value:'', visible: true, disabled: false, canSrch:false,selList:[]},
    {field: 'agmtTime', placeholder: '동의일시',    type:'text', value:'', visible: true, disabled: false, canSrch:false,selList:[]},
  ]

  const visibleColumns = fields.filter(col => col.visible)
  const defRecord = {}
  fields.forEach(d => {
    defRecord[d.field] = d.value
  })
  defRecord.schoolList = []  
  const defTerms = {}
  fields.filter(col => col.canSrch).map(d => d.field).forEach(d => defTerms[d] = '')
  const [record, set_record] = useState(defRecord)
  const [dataList, set_dataList] = useState(null)
  const [fieldList, set_fieldList] = useState([])
  const [companies, set_companies] = useState([])
  const [schoolList, set_schoolList] = useState([])
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const dgHandler = useState(false)
  const [selIdx, set_selIdx] = useState(-1)
  const [pageNum, set_pageNum] = useState(0)
  const [pageSize, set_pageSize] = useState(10)
  const [totalCount, set_totalCount] = useState(0)
  const [pageInfo, set_pageInfo] = useState({pageNum:0, pageSize: 10})
  const [terms, _set_terms] = useState(defTerms)
  const termsRef = useRef(terms)
  const {alert, renderAlert} = useAlert()

  const set_terms = data => {
    termsRef.current = data
    _set_terms(data)
  }

  const agmtRef = useRef()
  useEffect(() => {
    onLoad()
  }, [pageInfo])

  const onLoad = async() => {
    const agmtInfo = await fetchAgmtInfo()
    if(agmtInfo) {
      const agmtArray = agmtInfo.agmtArray
      const agmtRltnArray = agmtInfo.agmtRltnArray
      for(const agmt of agmtArray) {
        const found = agmtRltnArray.find(r => agmt.id === r.agmtId  && r.agmtYn === 'Y') 
        if(found) {
          agmt.agmtYn = 'Y' 
          agmt.agmtTime = found.uptTime
          
        }
        else {
          agmt.agmtYn = 'N'
          agmt.agmtTime = ''
        } 
      }
      set_dataList(agmtArray)
    }
  }

  const fetchAgmtInfo = async() => {
    const url = `/agmt/user/${account.user.id}`
    openIndicator()
    const resp = await WebService.get(url)
    closeIndicator()

    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    const repMessage = JSON.parse(resp.repMessage)
    const agmtInfo = JSON.parse(repMessage.report)
    return agmtInfo
  }

  const onSave = async () => {
    const disagree = dataList.filter(r => r.mandYn === 'Y'  && r.agmtYn === 'N') 
    if(disagree.length > 0) {
      alert('필수 항목에 동의하지 않으면 서비스 이용이 제한됩니다.')
      return
    }

    const url = `/agmt/user/${account.user.id}`
    const checkedList = dataList.filter(r => r.agmtYn === 'Y')
    const data = {agmtList: JSON.stringify(checkedList)}
    const resp = await WebService.post(url, data)
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    alert('저장되었습니다.')

  }

  if(dataList != null) {
    return (
      <div >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <div style={{ paddingRight: 10 }}>
            <h1>약관 목록</h1>
          </div>
        </div>
        
        <div style={{paddingTop:20}}>
          <MUITable>
            <MyTableHead >
              <TableRow>
                {
                  fields.map((col, index) => {
                    if(col.visible) {
                      return(
                        <TableCell key={index} size='small' key={col.field}>
                          {col.placeholder}
                          {col.canSrch? 
                            ( 
                              <Fragment>
                                  <TextField label="" variant="outlined" size='small' inputProps={{style: {fontSize: '0.875em', padding:5, backgroundColor:'#fff'}}} 
                                    value={terms[col.field]}
                                    onChange={(e) => {
                                      const newTerms = {...terms}
                                      newTerms[col.field] = e.target.value
                                      set_terms(newTerms)
                                    }}
                                  />
                              </Fragment>
                            )
                            : (
                              <Fragment>
                              </Fragment>
                            )
                          }
                        </TableCell>
                      )
                    }
                  })
                }
                <TableCell size='small'>
                </TableCell>
              </TableRow>
            </MyTableHead>
            <TableBody>
              {
                dataList.map((row, index) => {
                  return(
                    <Fragment key={index}>
                      <TableRow 
                        onClick={()=> {
                          set_record(row)
                        }}

                        onMouseEnter={() => {
                          agmtRef.current = row  
                        }}
                      >
                        {
                            fields.map((col, index2) => {
                              if(col.visible) {
                                if(col.field=== 'agmtYn') {
                                  return(
                                    <TableCell key={index2} size='small'>
                                      <input type='checkbox' 
                                        checked = {row[col.field] === 'Y'}
                                        disabled = {row.mandYn === 'Y' && row.agmtYn === 'Y'}
                                        onChange = {(e) => {
                                          const newDataList = [...dataList]
                                          const found = newDataList.find(r => r.id  === row.id)
                                          found.agmtYn = e.target.checked ? 'Y' : 'N'
                                          set_dataList(newDataList)
                                        }}
                                      /> 
                                    </TableCell>
                                  )
                                }
                                else {
                                  return(
                                    <TableCell key={index2} size='small'
                                      onClick = {() => {
                                        if(col.field === 'name') {
                                          if(row.file && row.file.length > 0) {
                                            dgHandler[1](true)
                                          }
                                        }
                                      }}
                                      style = {{
                                        cursor: col.field === 'name' && row.file && row.file.length > 0 ? 'pointer': null
                                      }}
                                    >
                                      {row[col.field]}
                                    </TableCell>
                                  )
                                }
                              }
                            })
                          }
                        </TableRow>
                      </Fragment>
                  )
                })
              }
            </TableBody>
            <TableFooter>
              <TableRow style={{display:'flex', justifyContent:'center'}}>
              </TableRow>
          </TableFooter>
          </MUITable>

          <div style={{display:'flex', justifyContent:'center', marginTop:10 }} >
            <Button variant='contained'
              onClick={onSave}
            >
              저장
            </Button> 
          </div>

            {dgHandler[0] ? <ProfileAgmtPartView record={agmtRef.current} dialogHandler={dgHandler} /> : null}
            {renderSnackbar()}
            {renderIndicator()}
            {renderAlert()}
          </div>
      </div>
    );
  }
  
}

export default ProfileAgmtPage
