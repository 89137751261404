export const description = [
  {id:'DS01', title:'소득금액증명', content:`사업자나 근로자가 소득세를 신고하거나 결정되어 소득금액이 있음을 확인하는 증명입니다.`},
  {id:'DS02', title:'부가가치세과세표준증명', content:`사업자가 신고한 부가가치세 매출과세표준과 납부세액을 증명하는 민원서류입니다.`},
  {id:'DS03', title:'면세사업자수입금액증명', content:`사업장현황 신고한 개인 면세사업자의 수입금액을 증명하는 민원서류입니다.`},
  {id:'DS04', title:'표준재무제표증명', content:`종합소득세·법인세 신고 시 부속서류로 제출한 표준재무제표를 증명하는 민원서류입니다.`},
  {id:'DS05', title:'납부내역증명(납세사실증명)', content:`납세자의 국세 납부 내역을 확인하는 증명입니다.`},
  {id:'DS06', title:'납세증명서(국세완납증명)', content:`발급일 현재 납부기한등연장, 압류매각유예, 부가가치세법에 의한 물적납세의무와 관련된 체납액을 제외하고는 다른 국세 체납액이 없음을 확인하는 증명입니다.`},
  {id:'DS07', title:'사업자관련증명', content:`- 사업자등록증명: 세무서에 사업자등록 후 현재 계속 사업을 하고 있음을 증명하는 민원서류입니다.
- 휴업사실증명: 휴업한 사실이 있음을 증명하는 민원서류입니다.
- 폐업사실증명: 폐업한 사실이 있음을 증명하는 민원서류입니다.
`},
  {id:`DS08`, title:`연금보험료등소득 세액공제확인서`, content:`연금수령, 연금계좌를 인출하려는 납세자가 과세제외금액이 있어 이를 확인받기 위한 서류입니다.`},
  {id:`DS09`, title:`사업자등록증재발급`, content:`사업자등록증을 재발급받습니다.`},
  {id:`DS10`, title:`지방세납세증명서(지방세완납증명)`, content:`발급일 현재 납부기한등연장, 압류매각유예, 부가가치세법에 의한 물적납세의무와 관련된 체납액을 제외하고는 다른 지방세 체납액이 없음을 확인하는 증명입니다.`},
  {id:`DS11`, title:`사실증명`, content:`- 신고사실없음: 종합소득세 신고사실, 근로(사업,연금,종교인)소득으로 연말정산한 사실 등이 없음을 확인받고자 하는 경우 이용합니다. 종합소득세 신고를 했거나 근로(사업,연금,종교인)소득으로 연말정산한 사실이 있는 경우, 「소득금액증명」을 신청해야 합니다.
- 체납내역확인: 체납 내역을 확인하고자 하는 경우 이용합니다. 체납이 없음을 확인받고자 하는 경우,「납세증명서」를 신청해야 합니다.
- 사업자등록사실여부: 사업자등록한 사실이 없음을 확인받고자 하는 경우 이용합니다.
`},
  {id:'DG01', title:'부동산등기사항증명서', content:` 등기부등본은 등기사항증명서로 명칭이 변경됨.
포제부(건물의표시), 갑구(소유권에관한사항), 을구(소유권이외 권리에 관한사항)로 되어 있음.
`},
  {id:`DG02`, title:`법인등기사항증명서`, content:`법인등기부등본은 등기사항증명서로 명칭이 변경됨.
법인의 상호, 주소, 설립일, 설립목적, 임원진현황, 발행주식, 자본총액, 등기년월일, 지점현황 등이 기재되어 있음.
`},
  {id:`DG03`, title:`토지(임야대장)`, content:`토지(임야)대장은 토지의 소재, 지번, 지목, 면적, 소유자의 주소, 성명 또는 명칭 등을 등록하여 
토지의 상황을 명확하게 한 장부로 토지에 관한 사실을 명확하게 한다는 점에서 토지에 대한 권리관계를 공시하는 토지등기부와 구별됨. 
`},
  {id:`DG04`, title:`건축물대장`, content:`건축물 대장은 건축물의 소재, 번호, 종류, 구조, 건평, 소유자의 현황 등을 등록하여
그 상황을 명확하게 기록한 것으로 준공날짜, 건물의 용도, 위법여부와 정화조의용량, 등을 확인할 수 있어 영업을 하시려는 분은 꼭 확인해야할 서류
`},
  {id:`DG05`, title:`지적(임야)도`, content:`지적(임야)도는 토지의 소재, 지번, 지목, 경계따위를 나타내기 위하여 국가에서 만든 평면지도로
일반번지는 지적도, 산번지는 임야도로 표시함.
`},
  {id:`DG06`, title:`토지이용계획확인서`, content:`토지(임야)대장은 토지의 소재, 지번, 지목, 면적, 소유자의 주소, 성명 또는 명칭 등을 등록하여
토지의 상황을 명확하게 한 장부로 토지에 관한 사실을 명확하게 한다는 점에서 토지에 대한 권리관계를 공시하는 토지등기부와 구별됨. 
`},
  {id:`DG07`, title:`개별공시지가확인서`, content:` 개별공시지가는 표준지의 단위 면적당 가격인 공시 지가를 기준으로 산정한 개별 토지의 단위 면적당 가격으로
토지관련 국세 및 지방세의 부과 기준이 됨.
`},
  {id:`DG08`, title:`주택가격확인서`, content:`공동주택(아파트, 빌라, 다세대주택)은 국토해양부에서 매년 1월1일 기준일로 조사 산정 공시함. 
개별주택(일반, 다가구주택)은 국토부가 제시하는 주택가격비준표를 적용하여 감정평가업의 검증 및 부동산평가위원회의 심의절차를 거쳐 시/군/구가 결정
`},
  {id:`DG09`, title:`자동차등록원부`, content:`자동차등록원부는 갑(소유권), 을(저당권)에 관한 사항으로 구분됨
갑: 소유자성명, 사용본거지, 압류사항(체납, 도로교통법)저당 기재
을: 저당권 설정내역이 기재
`},
  {id:`DG10`, title:`건설기계등록원부`, content:`건설기계등록원부는 갑(소유권), 을(저당권)에 관한 사항으로 구분됨
갑: 소유자성명, 사용본거지, 압류사항(체납, 도로교통법)저당 기재 
을: 저당권 설정내역이 기재 
`},
  {id:`DG11`, title:`신탁원부`, content:`위탁자, 수탁자, 수익자와 신탁인의 성명 및 주소, 신탁의 목적, 신탁재산의 관리 방법, 신탁 종료의 사유, 그 밖의 신탁의 조항, 
신청인의 기명날인을 포함한 신청서
`},
  // {id:``, title:``, content:``},
  // {id:``, title:``, content:``},
  // {id:``, title:``, content:``},
  // {id:``, title:``, content:``},
]
