import { SET_SCHOOLS } from "../actions/schoolAction"
const initState = []

const schoolReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_SCHOOLS:
      return action.payload
    default:
      return state
  }
}

export default schoolReducer