import axios from 'axios'
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'

import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Alert,
  Paper,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from '@mui/material'
import WebService from '@util/webService'
import {
  copyProperties,
  encodeParams,
  getAction,
  isStringNullOrEmpty,
  printVariable,
  searchCompany,
} from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux'
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton'
import useSnackbar from '@hooks/useSnackbar'
import MyTableHead from '@components/MyTableHead'
import useIndicator from '@hooks/useIndicator'
import Indicator from '@components/Indicator'
import Box from '@mui/material/Box'
import useAlert from '@hooks/useAlert'
// pagination
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Avatar, Grid, Container } from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { searchSchool } from '@util/helper'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ConfirmDialog from '@components/ConfirmDialog'
import useDelegate from '@hooks/useDelegate'
const Boxs = styled(Box)`
  padding-bottom: 40px !important;
`
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import RadioButtonCheckedOutlined from '@mui/icons-material/RadioButtonCheckedOutlined'
import RadioButtonUncheckedOutlined from '@mui/icons-material/RadioButtonUncheckedOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { setAccount } from '@redux/actions/accountAction'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const sections = [
  {name:'수상 경력', exps:[]},
]

const FormHelperTexts = styled(FormHelperText)`

  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`
const ResumeHeader = ({title, url}) => {
  const navigate = useNavigate()
  return(
    <Grid container spacing={2} style={{ marginTop: 20 }}>
      <Grid item xs={6}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Grid>

      <Grid
        item
        xs={6}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          <AddOutlinedIcon
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(url)}
          />
        </Typography>
      </Grid>
    </Grid>
  )
}
const ProfileUserPartResume = () => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const defTerms = {}

  const [dataList, set_dataList] = useState([])
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const { alert, renderAlert } = useAlert()
  const dgHandler = useState(false)
  const dgHandler2 = useState(false)
  const [terms, _set_terms] = useState(defTerms)
  const [folded, set_folded] = useState(true)
  const termsRef = useRef(terms)

  // redux
  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const schools = useSelector((state) => state.schoolReducer)
  const account = useSelector((state) => state.accountReducer)

  const [record, set_record] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const set_terms = (data) => {
    termsRef.current = data
    _set_terms(data)
  }

  useEffect(() => {
    // onLoad()
  }, [])

  const onLoad = async () => { }

  ////
  const onSubmit = async () => { }

  const validate = (name, value) => { }

  const onDeleteSummary = () => {
    const newAccount = { ...account }
    newAccount.user.summary = ''
    dispatch(setAccount(newAccount))
  }
  
  const onDeleteCareer= (career) => {
    const newAccount = { ...account }
    const idx = newAccount.user.careerList.findIndex(r => r.companyName === career.companyName)
    if(idx >= 0) {
      newAccount.user.careerList.splice(idx, 1 )
      dispatch(setAccount(newAccount))
    }
  }

  const onDeleteSchool= (school) => {
    const newAccount = { ...account }
    const idx = newAccount.user.schoolList.findIndex(r => r.name === school.name)
    if(idx >= 0) {
      newAccount.user.schoolList.splice(idx, 1 )
      dispatch(setAccount(newAccount))
    }
  }

  const onDeleteSkill = (skill) => {
    const newAccount = { ...account }
    const idx = newAccount.user.skillList.findIndex(r => r.name=== skill.name)
    if(idx >= 0) {
      newAccount.user.skillList.splice(idx, 1 )
      dispatch(setAccount(newAccount))
    }
  }

  const onDeleteLicense = (license) => {
    const newAccount = { ...account }
    const idx = newAccount.user.licenseList.findIndex(r => r.liceneName === license.name)
    if(idx >= 0) {
      newAccount.user.licenseList.splice(idx, 1 )
      dispatch(setAccount(newAccount))
    }
  }

  const Dtitle = ({ title, handleClose }) => {
    return (
      <DialogTitle
        id="responsive-dialog-title"
        style={{ borderBottom: '1px solid #eee' }}
      >
        <Grid container>
          <Grid item xs={6}>
            {title}
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <IconButton onClick={handleClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
    )
  }

  const Dactions = ({ handleClose }) => {
    return (
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ display: 'felx' }}>
            <Button fullWidth onClick={handleClose}>
              닫기
            </Button>
          </Grid>
          <Grid item xs={12} style={{ display: 'felx' }}>
            <Button fullWidth onClick={handleClose} variant="contained">
              저장
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    )
  }

  const Popup = () => {
    return (
      <Dialog open={dgHandler[0]} fullWidth maxWidth={'sm'}>
        <Dtitle
          title={'개인정보 설정'}
          handleClose={() => dgHandler[1](false)}
        />
        <DialogContent style={{ borderBottom: '1px solid #eee' }}>
          <Grid
            container
            spacing={2}
            style={{ border: '2px solid #eee', padding: 10, marginTop: 10 }}
          >
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <RemoveRedEyeOutlinedIcon />
              &nbsp;{' '}
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                검색가능
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <RadioButtonCheckedOutlined />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                채용기업은 약관에 따라 본 사이트를 통해 검색하여 귀하의 이력서를
                찾을 수 있습니다.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ border: '2px solid #eee', padding: 10, marginTop: 10 }}
          >
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <VisibilityOffOutlinedIcon />
              &nbsp;{' '}
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                검색불가
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <RadioButtonUncheckedOutlined />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                채용기업은 본 사이트를 검색을 통해 귀하에게 연락할 수 없습니다.
                <br />
                그거나 귀하가 연락했던 채용기업은 귀하에게 연락할 수 있습니다.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <Dactions handleClose={() => dgHandler[1](false)} />
      </Dialog>
    )
  }

  const Popup2 = () => {
    return (
      <Dialog open={dgHandler2[0]} fullWidth maxWidth={'sm'}>
        <Dtitle
          title={'파일 이력서 관리'}
          handleClose={() => dgHandler2[1](false)}
        />
        <DialogContent style={{ borderBottom: '1px solid #eee' }}>
          <Grid
            container
            spacing={2}
            style={{ border: '2px solid #eee', padding: 10, marginTop: 10 }}
          >
            <Grid item xs={12}>
              <Button
                startIcon={<FileUploadOutlinedIcon />}
                variant="contained"
                fullWidth
              >
                업로드
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<FileDownloadOutlinedIcon />}
                variant="contained"
                fullWidth
              >
                다운로드
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  // main
  return (
    <Container component="main" maxWidth="md" style={{paddingBottom:100}}>
      {dgHandler[0] ? <Popup /> : null}
      {dgHandler2[0] ? <Popup2 /> : null}
      {/* backward */}
      <Grid container spacing={2} style={{marginTop:10}} >
        <Grid item xs={2}>
          <ArrowBackOutlinedIcon
            onClick={() => navigate(-1)}
          />
        </Grid>
        <Grid
          item
          xs={10}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <ButtonGroup>
            <Button
              startIcon={<RemoveRedEyeOutlinedIcon />}
              onClick={() => dgHandler[1](true)}
            >
              검색가능
            </Button>
            <Button
              startIcon={<MoreVertOutlinedIcon />}
              onClick={() => dgHandler2[1](true)}
            >
              이력서 업/다운로드
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      {/* userinfo */}
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {account.user.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            <EditOutlinedIcon
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/profile/user/detail')}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography>{account.user.loginId}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {account.user.phone}
          </Typography>
        </Grid>
      </Grid>

      {/* summary */}
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            내 정보 요약
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {!account.user.summary ? (
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              <AddOutlinedIcon
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/profile/user/summary')}
              />
            </Typography>
          ) : null}
        </Grid>
        {account.user.summary ? (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography>{account.user.summary}</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <EditOutlinedIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate('/profile/user/career')}
                />
                &nbsp;
                <DeleteForeverOutlinedIcon
                  style={{ cursor: 'pointer' }}
                  onClick={onDeleteSummary}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        
      </Grid>

      {/* career */}
      <ResumeHeader title='경력' url='/profile/user/career' />
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        {
          account.user.careerList && account.user.careerList.length > 0  ? (
            <Grid item xs={12}> 
              <Grid container spacing={2} style={{padding:10}} >
              {
                account.user.careerList.map((item, idx) => (
                  <div key={idx} style={{display:'flex', alignItems:'center', width:'100%', border:'1px solid #eee', padding:10, marginTop:10}}>
                    <Grid item xs={6} >
                      <Typography>{item.companyName}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <EditOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/profile/user/career', {
                          state: {
                            career: item
                          }
                        })}
                      />
                      &nbsp;
                      <DeleteForeverOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => onDeleteCareer(item)}
                      />
                    </Grid>
                  </div >
                ))
              }
              </Grid>
            </Grid>
          ) : null
        }
      </Grid>
      <ResumeHeader title={'학력'} url={'/profile/user/school'} />
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        {
          account.user.schoolList&& account.user.schoolList.length > 0  ? (
            <Grid item xs={12}> 
              <Grid container spacing={2} style={{padding:10}} >
              {
                account.user.schoolList.map((item, idx) => (
                  <div key={idx} style={{display:'flex', alignItems:'center', width:'100%', border:'1px solid #eee', padding:10, marginTop:10}}>
                    <Grid item xs={6} >
                      <Typography>{item.name}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <EditOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/profile/user/school', {
                          state: {
                            school: item 
                          }
                        })}
                      />
                      &nbsp;
                      <DeleteForeverOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => onDeleteSchool(item)}
                      />
                    </Grid>
                  </div >
                ))
              }
              </Grid>
            </Grid>
          ) : null
        }
      </Grid>

      <ResumeHeader title={'기술'} url={'/profile/user/skill'} />
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        {
          account.user.skillList && account.user.skillList.length > 0  ? (
            <Grid item xs={12}> 
              <Grid container spacing={2} style={{padding:10}} >
              {
                account.user.skillList.map((item, idx) => (
                  <div key={idx} style={{display:'flex', alignItems:'center', width:'100%', border:'1px solid #eee', padding:10, marginTop:10}}>
                    <Grid item xs={6} >
                      <Typography>{item.name }</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <EditOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/profile/user/skill', {
                          state: {
                            skill: item 
                          }
                        })}
                      />
                      &nbsp;
                      <DeleteForeverOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => onDeleteSkill(item)}
                      />
                    </Grid>
                  </div >
                ))
              }
              </Grid>
            </Grid>
          ) : null
        }
      </Grid>
      <ResumeHeader title={'면허증/자격증'} url={'/profile/user/license'} />
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        {
          account.user.licenseList && account.user.licenseList.length > 0  ? (
            <Grid item xs={12}> 
              <Grid container spacing={2} style={{padding:10}} >
              {
                account.user.licenseList.map((item, idx) => (
                  <div key={idx} style={{display:'flex', alignItems:'center', width:'100%', border:'1px solid #eee', padding:10, marginTop:10}}>
                    <Grid item xs={6} >
                      <Typography>{item.name}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <EditOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/profile/user/license', {
                          state: {
                            license: item 
                          }
                        })}
                      />
                      &nbsp;
                      <DeleteForeverOutlinedIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => onDeleteLicense(item)}
                      />
                    </Grid>
                  </div >
                ))
              }
              </Grid>
            </Grid>
          ) : null
        }
      </Grid>
      <Grid container spacing={2} style={{marginTop:10}}>
        <Grid item xs={12} style={{display:'flex', cursor:'pointer' }} onClick={() => {
          set_folded(!folded)
        }} >
          {
            folded ? (
              <KeyboardArrowDownIcon /> 
            ) : (
              <KeyboardArrowUpIcon/> 
            )
          }
          &nbsp;
          <Typography variant='h5' style={{fontWeight:'bold'}}>
            섹션 추가
          </Typography>
        </Grid>
        <Grid itm xs={12}></Grid>
      </Grid>
      <div>
        {renderSnackbar()}
        {renderIndicator()}
        {renderAlert()}
      </div>
    </Container>
  )
}

export default ProfileUserPartResume
