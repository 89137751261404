import React, { useState } from "react";
import {
    Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import Settings from '@mui/icons-material/Settings'
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from '@mui/material/styles';
import {GlobalStyle as gs} from '@src/GlobalStyle'

const useStyles = makeStyles(()=>({
    link:{
        textDecoration:"none",
        color: '#000',
        fontSize: "20px",
    },
    icon:{
        color: "#000"
    },
    right: {
      backgroundColor: gs.colors.bar
    }
}));


function DrawerComponent() {
  const classes = useStyles();
  const theme = useTheme()
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer
        open={openDrawer}
        anchor={'right'}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          className: classes.right,
          sx:{backgroundColor:null}
        }}
      >
        <Toolbar />
        <List >
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/profile/user" className={classes.link}>프로필</Link>
            </ListItemText>
          </ListItem>
          <Divider/>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/profile/interest" className={classes.link}>관심 취업정보</Link>
            </ListItemText>
          </ListItem>
          <Divider/>
          {/* <ListItem onClick={() => setOpenDrawer(false)}> */}
          {/*   <ListItemText> */}
          {/*     <Link to="/profile" className={classes.link}>설정</Link> */}
          {/*   </ListItemText> */}
          {/* </ListItem> */}
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/company/index" className={classes.link}>기업회원</Link>
            </ListItemText>
          </ListItem>
          <Divider/>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/logout" className={classes.link}>로그아웃</Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon}>
        <Settings sx={{color:gs.colors.main}}/>
      </IconButton>
    </>
  );
}
export default DrawerComponent;
