import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'
import { check } from 'prettier'
import { setLoading } from '@context/LoadingContext'

const boldFont = {
  fontWeight: 'bold',
}

const MyBasketPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const curLocationRef = useRef(location)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const { alert, renderAlert } = useAlert()
  const dgRef = useRef()
  const [allChecked, set_allChecked] = useState(false)
  const [html, set_html] = useState('')

  const handleAllChecked = (e) => {
    const checked = e.target.checked
    console.log('checked=>', checked)
    records.forEach((item) => {
      item.checked = checked
    })
    set_records([...records])
    set_allChecked(checked)
  }

  const handleEachChecked = (item, e) => {
    const checked = e.target.checked
    item.checked = checked
    const filtered = records.filter((r) => r.checked === checked)

    if (checked) {
      console.log('filtered length:', filtered.length)
      if (filtered.length === records.length) {
        console.log('ok all checked')
        set_allChecked(true)
      }
    } else {
      if (filtered.length !== records.length) {
        set_allChecked(false)
      }
    }

    set_records([...records])
  }

  const fetchBaskets = async () => {
    const url = `/basket/list`
    const resp = await WebService.get(url)
    console.log('resp=>', resp)
    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    records.forEach((item) => {
      item.checked = false
    })
    return records
  }

  const Minwon = () => {
    return (
      <div>
        <div style={{ height: '100vh' }} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    )
  }

  const onOrder = async () => {
    const docs = records.filter(r => r.checked).flatMap(r => r.docs)
    navigate('/order', {
        state: {
          docs: docs,
          pack: {
            url: window.location.pathname,
          },
        },
      })

    /*
    if (!account?.user?.id) {
      navigate('/login', {
        state: {
          doc: record,
          pack: {
            url:window.location.pathname,
            data: record,
            furl: '/order'
          } 
        },
      })
    } else {
      navigate('/order', {
        state: {
          docs: [ret],
          pack: {
            url: window.location.pathname,
            data: ret,
          },
        },
      })
    }
    */
  }


  const onDelete = async () => {
    const url = '/basket/ids'
    const data = {
      ids: records.filter(r => r.checked).map(r => ''+r.id)
    }
    console.log('data=>', data)
    setLoading(true)
    const resp = await axios.request({
      url: url,
      method: 'delete',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    setLoading(false)
    if(resp.data.repCode !== 'ack') {
      alert(resp.data.repMessage)
      return
    }
    
    onLoad()

  }
  const onLoad = async () => {
    const records = await fetchBaskets()
    if (!records) {
      return
    }

    console.log('records2=>', records)
    set_records(records)
  }

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    if (!account?.user?.id) {
      navigate('/login')
    } else {
      onLoad()
    }
  }, [])

  return (
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
          paddingBottom: 50,
          minHeight: '75vh',
        }}
      >
        {renderAlert()}
        <MyDialog2 title={'Test'} ref={dgRef} component={<Minwon />} windowWidth='md' />
        <Container>
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
            >
              <Typography style={boldFont}>민원바구니</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={1} md={1}>
                  <FormControlLabel label='' control={<Checkbox checked={allChecked} onChange={handleAllChecked} />} />
                </Grid>
                <Grid item xs md>
                  <Typography style={boldFont}>민원종류</Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Typography style={boldFont}>수량</Typography>
                </Grid>
                <Grid item xs md>
                  <Typography style={boldFont}>금액</Typography>
                </Grid>
                <Grid item xs md>
                  <Typography style={boldFont}>최종수정일시</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {records.length > 0
              ? records.map((item, idx) => (
                  <>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        key={idx}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Grid item xs={1} md={1}>
                          <FormControlLabel
                            label=''
                            control={
                              <Checkbox
                                checked={item.checked}
                                onChange={(e) => {
                                  handleEachChecked(item, e)
                                }}
                              />
                            }
                          />
                        </Grid>

                        <Grid item xs md>
                          <Grid
                            container
                            spacing={1}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <Grid item xs={12}>
                              {item.docs.map((doc, idx2) => (
                                <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant='body2'
                                      style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                      onClick={() => {
                                        navigate('/my/basket/view', {
                                          state: {
                                            basket: item,
                                          },
                                        })
                                      }}
                                    >
                                      {docCodes.find((r) => r.code === doc.docCode).name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1} md={1}>
                          <Typography variant='body2'>{item.docs.length}</Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>{comma(item.amount)}</Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>
                            {moment(item.uptTime).format('YYYY-MM-DD')} <br />
                            {moment(item.uptTime).format('HH:mm:ss')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                ))
              : null}
          </Grid>
        </Container>
        <Container
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 30,
          }}
        >
          <Button variant='contained' style={{ margin: 5 }} disabled={!records.find((r) => r.checked)} 
            onClick={onOrder}
          >
            주문하기
          </Button>
          <Button
            variant='contained'
            color='error'
            style={{ margin: 5 }}
            disabled={!records.find((r) => r.checked)}
            onClick={onDelete}
          >
            삭제
          </Button>
        </Container>
      </div>
    </>
  )
}

export default MyBasketPage
