
import React, {useState, useEffect, useMemo, Fragment, useRef, forwardRef, useImperativeHandle} from 'react';
import {
  Grid, Divider, Typography, useMediaQuery, useTheme, Container, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button } from '@mui/material'
import DaumPostcodeEmbed, {useDaumPostcodePopup} from 'react-daum-postcode';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
const BuildingGuide = forwardRef((props, ref) => {
  const {title, component, windowWidth, callback} = props
  const [open, set_open] = useState(false)
  const addrRef= useRef('')  
  const handleOpen = () => {
    set_open(true)
  };

  const handleClose = () => {
    if(callback) {
      callback(addrRef.current)
    }
    addrRef.current = ''
    set_open(false)
  }

  useImperativeHandle(ref, () =>({
    open: () => {
      handleOpen()
    },
    close: () => {
      handleClose()
    },
    addr: () => {
      return addrRef.current
    }
  }))
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth= {windowWidth ?? 'xl' }
      >
        <DialogTitle style={{justifyContent:'center', alignItems:'center'}}>
          <Typography variant='h5' align='center' style={{fontWeight:'bold'}}>
            {title}
          </Typography>
          <Divider sx={{mt:1}}/>
        </DialogTitle>
        <DialogContent>
          <div>
건축물대장은 일반건축물대장과 집합건축물대장으로 나뉩니다. <br/>
아래 안내물 참고하여 신청하시려는 지번의 건축물 구분이 집합인지, 일반인지 확인하시기 바랍니다. <br/> <br/>

<Typography style={{fontWeight:'bold'}}>1. 대장구분(일반/집합)안내</Typography>  <br/>
일반 : 건물의 소유주가 개인이거나 홀길동 외 0명 등으로 대표자가 있는경우. <br/>
집합 : 건물의 소유주가 여러명이며, 각각의 이름이 명시되는 경우 <br/>
※일반적으로 아파트, 연립주택, 다세대주택 등은 집합건축물에 해당됨. <br/><br/>
<Typography style={{fontWeight:'bold'}}>2.대장종류 안내</Typography><br/>
(1) 대장구분에서 일반을 선택 <br/>
총괄 : 신청하시는 주소지의 지번에 있는 모든 건물들을 표시. <br/>
       (해당 지번위에 건물이 2개동 이상 있을경우) <br/>
일반 : 본인 건물만 표시. <br/>
(2)대장구분에서 집합을 선택 <br/>
총괄 : 해당 지번에 있는 모든 건축물 표시 <br/>
표제부 : 해당 지번에 있는 동 표시 <br/>
전유부 : 해당 지번에 있는 동의 호수 표시 <br/><br/>
(예시) <br/>
1. 월드컵아파트 2002동 2호의 건축물 대장을 신청 할 경우. <br/>
대장구분 - 집합, 대장종류 - 전유부 선택 <br/>
2. 단독주택의 건축물대장을 신청 할 경우. <br/>
대장종류 - 일반, 대장종류 - 일반선택 <br/>
          </div> 
        </DialogContent>
        <DialogActions style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})

export default BuildingGuide
