import axios from 'axios'
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'

import { FormControl, InputLabel, Input, FormHelperText, Alert, Paper } from '@mui/material'
import WebService from '@util/webService'
import { encodeParams, getAction, isStringNullOrEmpty, printVariable, searchCompany } from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux'
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton'
import useSnackbar from '@hooks/useSnackbar'
import MyTableHead from '@components/MyTableHead'
import useIndicator from '@hooks/useIndicator'
import Indicator from '@components/Indicator'
import MyDialog from '@components/MyDialog'
import Box from '@mui/material/Box'
import useDelegate from '@hooks/useDelegate'
// pagination
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Avatar, Grid, Container } from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { searchSchool } from '@util/helper'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery } from '@mui/material'
// import { navigate } from 'react-big-calendar/lib/utils/constants';
import { useNavigate } from 'react-router'
import { emailRegex, nameRegex, passwordRegex } from '@util/helper'
import useAlert from '@hooks/useAlert'
import RegisterUserPartAgmt from './register-user.part.agmt'
import logo from '@images/logo.png'
import { ArrowBackOutlined, InsertCommentOutlined } from '@mui/icons-material'
import NumericTextField from '@components/NumericTextField'

const Boxs = styled(Box)`
  padding-bottom: 40px !important;
`

const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds % 60).padStart(2, '0')
  return `${formattedMinutes}:${formattedSeconds}`
}

const WAIT_SEC = 60 * 5
// const WAIT_SEC = 10

const RegisterUserPage = () => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'
  const fields = [
    { field: 'id', placeholder: '', type: 'text', value: -1, visible: false, canSrch: false, selList: [] },
    { field: 'name', placeholder: '이름', type: 'text', value: '', visible: true, canSrch: true, selList: [] },
    { field: 'statCode', placeholder: '', type: 'text', value: 'S000', visible: false, canSrch: false, selList: [] },
    { field: 'phone', placeholder: '전화', type: 'number', value: '', visible: true, canSrch: true, selList: [] },
    { field: 'phoneDial', placeholder: '전화', type: 'number', value: '010', visible: true, canSrch: true, selList: [] },
    { field: 'phone1', placeholder: '전화', type: 'number', value: '', visible: true, canSrch: true, selList: [] },
    { field: 'phone2', placeholder: '전화', type: 'number', value: '', visible: true, canSrch: true, selList: [] },
    { field: 'loginId', placeholder: '로그인ID', type: 'text', value: '', visible: true, canSrch: false, selList: [] },
    { field: 'password', placeholder: '패스워드', type: 'text', value: '', visible: true, canSrch: false, selList: [] },
    {
      field: 'password2',
      placeholder: '패스워드확인',
      type: 'text',
      value: '',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'phoneCertYn',
      placeholder: '폰인증여부',
      type: 'text',
      value: 'N',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'emailCertYn',
      placeholder: '이메일인증여부',
      type: 'text',
      value: 'N',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'deviceToken',
      placeholder: '디바이스토큰',
      type: 'text',
      value: '',
      visible: false,
      canSrch: false,
      selList: [],
    },
    { field: 'email', placeholder: '이메일', type: 'text', value: '', visible: true, canSrch: false, selList: [] },
    {
      field: 'emailCode',
      placeholder: '이메일 인증코드',
      type: 'text',
      value: '',
      visible: true,
      canSrch: false,
      selList: [],
    },
    {
      field: 'openYn',
      placeholder: '이메일 인증코드',
      type: 'text',
      value: 'N',
      visible: true,
      canSrch: false,
      selList: [],
    },
    { field: 'address', placeholder: '주소', type: 'text', value: '', visible: false, canSrch: false, selList: [] },
    { field: 'address2', placeholder: '주소', type: 'text', value: '', visible: false, canSrch: false, selList: [] },
    { field: 'zipCode', placeholder: '주소', type: 'text', value: '', visible: false, canSrch: false, selList: [] },
    { field: 'phone', placeholder: '휴대폰', type: 'text', value: '', visible: false, canSrch: false, selList: [] },
    { field: 'certNum', placeholder: '인증번호', type: 'text', value: '', visible: true, canSrch: false, selList: [] },
    { field: 'regTime', placeholder: '등록일시', type: 'text', value: '', visible: false, canSrch: false, selList: [] },
    { field: 'uptTime', placeholder: '수정일시', type: 'text', value: '', visible: false, canSrch: false, selList: [] },
    { field: 'agmt', placeholder: '약관', type: 'text', value: '', visible: false, canSrch: false, selList: [] },
    { field: 'certified', placeholder: '인증', type: 'text', value: '', visible: false, canSrch: false, selList: [] },
    { field: 'openYn', placeholder: '정보공개', type: 'text', value: 'N', visible: false, canSrch: false, selList: [] },
  ]

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const visibleColumns = fields.filter((col) => col.visible)
  const defRecord = {}
  fields.forEach((d) => {
    defRecord[d.field] = d.value
  })
  defRecord.schoolList = []
  const defTerms = {}
  fields
    .filter((col) => col.canSrch)
    .map((d) => d.field)
    .forEach((d) => (defTerms[d] = ''))
  const [record, set_record] = useState(defRecord)
  const [dataList, set_dataList] = useState([])
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const dgHandler = useState(false)
  const [terms, _set_terms] = useState(defTerms)
  const [sms, set_sms] = useState(null)
  const termsRef = useRef(terms)
  const navigate = useNavigate()
  const [emailNoti, set_emailNoti] = useState(false)
  const [agmtList, set_agmtList] = useState([])
  const [allChecked, set_allChecked] = useState(false)
  // redux
  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const schools = useSelector((state) => state.schoolReducer)
  const certRef = useRef(false)
  const [timer, set_timer] = useState({})
  const [remainingTime, set_remainingTime] = useState(0)

  const { alert, renderAlert } = useAlert()

  const agmtRef = useRef()
  const EMAIL_MSG = `본인 소유의 이메일 여부를 확인하기 위해 이메일이 발송됩니다. 회원가입 완료 후 24시간 이내에 이메일을 확인하여 인증해 주세요. 이메일 인증이 완료되지 않을 경우 이용이 제한됩니다`

  const set_terms = (data) => {
    termsRef.current = data
    _set_terms(data)
  }

  useEffect(() => {
    onLoad()
    onCreate()
  }, [])

  const onLoad = async () => {
    openIndicator()
    const url = '/public/agmt'
    const resp = await WebService.get(url)
    if (resp.repCode === 'ack') {
      const repMessage = JSON.parse(resp.repMessage)
      const records = JSON.parse(repMessage.records)
      console.log('agmgt records=>', records)
      records.forEach((r) => {
        if (r.mandYn === 'Y') {
          r.name = `[필수]${r.name}`
        } else {
          r.name = `[선택]${r.name}`
        }
        r.checked = false
      })
      set_agmtList(records)
    }
    closeIndicator()
  }

  const onCreate = () => {
    set_record(defRecord)
  }

  const onSmsRequest = async () => {}

  const onSmsConfirm = async () => {}

  const onChangeAllCheckbox = (e) => {
    const newAgmtList = [...agmtList]
    if (e.target.checked) {
      newAgmtList.forEach((r) => (r.checked = true))
      set_allChecked(true)

      //
      const event = { target: { name: 'agmt', value: '' } }
      handleChange(event)
    } else {
      newAgmtList.forEach((r) => (r.checked = false))
      set_allChecked(false)
    }
    set_agmtList(newAgmtList)
  }

  const onChangeCheckbox = (agmt, e) => {
    const newAgmtList = [...agmtList]
    const idx = agmtList.indexOf(agmt)
    agmtList[idx].checked = e.target.checked
    set_agmtList(newAgmtList)

    if (!e.target.checked) {
      set_allChecked(false)
    } else {
      const idx = newAgmtList.findIndex((r) => !r.checked)
      if (idx < 0) {
        set_allChecked(true)

        //
        const event = { target: { name: 'agmt', value: '' } }
        handleChange(event)
      }
    }

    if (errors.agmt) {
      const uncheckd = newAgmtList.filter((r) => r.mandYn === 'Y' && !r.checked)
      printVariable('uncheckd', uncheckd)
      if (uncheckd.length === 0) {
        const event = { target: { name: 'agmt', value: '' } }
        handleChange(event)
      }
    }
  }

  ////
  const onSubmit = async () => {
    console.log('submit...')
    openIndicator()
    const url = `/public/user/subscribe`
    const now = moment().format(dateFormat)
    const user = {
      ...activeRow,
      phone: activeRow.phoneDial + activeRow.phone1 + activeRow.phone2, 
      email: activeRow.loginId,
      phoneCertYn: 'N',
      emailCertYn: 'N',
      uptTime: now,
      regTime: now,
    }
    const checkedList = agmtList.filter((r) => r.checked)
    const data = { user: user, agmtList: JSON.stringify(checkedList) }

    const resp = await WebService.post(url, data)
    if (resp.repCode === 'ack') {
      alert('가입을 환영합니다. 로그인 후 이메일 인증을 완료해 주세요.', () => navigate('/login'))
    } else {
      alert(resp.repMessage)
    }
    closeIndicator()
  }

  const checkEmail = async (loginId) => {
    const url = `/public/email/${loginId}`
    const resp = await WebService.get(url)

    if (resp.repCode !== 'ack') {
      const err = resp.repMessage
      return err
    }
    return ''
  }

  const checkPhone = async (phone) => {
    const url = `/public/phone/${phone}`
    const resp = await WebService.get(url)

    if (resp.repCode !== 'ack') {
      const err = resp.repMessage
      return err
    }
    return ''
  }

  const validate = async (name, value) => {
    let err = ''
    if (value === undefined || value === null) {
      return err
    }

    if ('' + value !== ('' + value).trim()) {
      const event = { target: { name: name.trim(), value: ('' + value).trim() } }
      await handleChange(event)
      return
    }

    if (name === 'loginId') {
      const regex = /([\w.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      if (!regex.test(value)) {
        err = '정상적인 이메일 형식이 아닙니다.'
      } else {
        err = await checkEmail(value)
      }

      if (err === '') {
        set_emailNoti(true)
      } else {
        set_emailNoti(false)
      }
    }
    if (name === 'password') {
      const regex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
      if (!regex.test(value)) {
        err = '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요.'
      }
    }

    if (name === 'password2') {
      if (value === '') {
        err = '패스워드가 일치하는지 확인해 주세요.'
      }
      if (factor.password !== value) {
        err = '패스워드가 일치하는지 확인해 주세요(2).'
      }
    }
    if (name === 'name') {
      // const regex = /^[가-힣a-zA-Z]+$/
      const regex = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/
      if (!regex.test(value)) {
        err = '올바른 이름을 입력해주세요.'
      }
    }
    
    if(name === 'phone1') {
      if(value.length != 4) {
        err = '전화번호얖 4자리가 아닙니다.' 
      }
    }
    if(name === 'phone2') {
      if(value.length != 4) {
        err = '전화번호뒤 4자리가 아닙니다.' 
      }
    }

    if (name === 'agmt') {
      agmtList.forEach((agmt) => {
        if (agmt.mandYn === 'Y') {
          if (!agmt.checked) {
            err = '약관 동의 [필수] 항목을 체크하지 않으면 서비스 이용이 제한됩니다.'
          }
        }
      })
    }

    return err
  }

  useEffect(() => {
    if (remainingTime > 0) {
      if (timer) {
        clearTimeout(timer)
      }

      const newTimer = setTimeout(() => {
        set_remainingTime(remainingTime - 1)
      }, 1000)
      set_timer(newTimer)
    } else {
      if (timer) {
        clearTimeout(timer)
        set_timer(null)
      }
      certRef.current = false
      set_sms(null)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
        set_timer(null)
      }
    }
  }, [remainingTime])

  const { activeRow, errors, updateState, handleChange, handleSubmit, factor } = useDelegate({
    initialValues: { ...record },
    onSubmit: onSubmit,
    validate: validate,
  })

  return (
    <Container component='main' maxWidth='sm'>
      <CssBaseline />
      {/* <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:50, marginBottom:25, }}> */}
      {/*   <img src={logo} alt='logo' style={{width: '10%' }} /> */}
      {/* </div >  */}
      <div style={{ marginTop: 100 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
        <Typography style={{ margin: 10, textAlign: 'center', fontWeight: 'bold' }} variant='h4'>
          <img src={logo} alt='logo' style={{ height: 20 }} /> 회원가입을 진행합니다.
        </Typography>
      </div>
      <FormControl component='fieldset' variant='standard'>
        <Paper fullWidth elevation={10} sx={{ padding: 5 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size='small'
                autoComplete='new-password'
                required
                fullWidth
                label={'아이디(이메일)'}
                name={'loginId'}
                // defaultValue={activeRow['loginId']}
                onChange={handleChange}
                error={errors['loginId']}
                helperText={emailNoti ? EMAIL_MSG : null}
              />
            </Grid>
            <FormHelperTexts>{errors['loginId']}</FormHelperTexts>
            <Grid item xs={12}>
              <TextField
                size='small'
                required
                fullWidth
                type='password'
                label={'패스워드'}
                name={'password'}
                // defaultValue={activeRow['password']}
                onChange={handleChange}
                error={errors['password']}
              />
            </Grid>
            <FormHelperTexts>{errors['password']}</FormHelperTexts>
            <Grid item xs={12}>
              <TextField
                size='small'
                required
                fullWidth
                type='password'
                label={'패스워드 확인'}
                name={'password2'}
                // defaultValue={activeRow['password2']}
                onChange={handleChange}
                error={errors['password2']}
              />
            </Grid>
            <FormHelperTexts>{errors['password2']}</FormHelperTexts>
            <Grid item xs={12}>
              <TextField
                size='small'
                required
                fullWidth
                label={'이름'}
                name={'name'}
                // defaultValue={activeRow['name']}
                onChange={handleChange}
                error={errors['name']}
              />
            </Grid>
            <FormHelperTexts>{errors['name']}</FormHelperTexts>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <NumericTextField
                    size='small'
                    required
                    fullWidth
                    label={'국번'}
                    name={'phoneDial'}
                    value={activeRow['phoneDial']}
                    onChange={handleChange}
                    error={errors['phoneDial']}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericTextField
                    size='small'
                    required
                    fullWidth
                    label={'앞자리'}
                    name={'phone1'}
                    value={activeRow['phone1']}
                    maxLength={4}
                    onChange={(e) => {
                      const event = { target: { name: 'phone1', value: e } }
                      handleChange(event, () => {
                        updateState()
                      })
                    }}
                    error={errors['phone1']}
                  />
                  <FormHelperTexts>{errors['phone1']}</FormHelperTexts>
                </Grid>
                <Grid item xs={4}>
                  <NumericTextField
                    size='small'
                    required
                    fullWidth
                    label={'뒷자리'}
                    name={'phone2'}
                    value={activeRow['phone2']}
                    maxLength={4}
                    onChange={(e) => {
                      const event = { target: { name: 'phone2', value: e } }
                      handleChange(event, () => {
                        updateState()
                      })
                    }}
                    error={errors['phone2']}
                  />
                  <FormHelperTexts>{errors['phone2']}</FormHelperTexts>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                checked={allChecked}
                onChange={(e) => {
                  onChangeAllCheckbox(e)
                }}
              ></Checkbox>{' '}
              전체약관동의
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ p: 2, border: '1px solid #ccc', fontSize: '0.7em' }}>
                {agmtList.map((agmt, index) => {
                  return (
                    <Grid container sx={{ alignItems: 'center' }} onMouseEnter={() => (agmtRef.current = agmt)}>
                      <Grid item xs={11}>
                        <Checkbox
                          checked={agmt.checked}
                          onChange={(e) => {
                            onChangeCheckbox(agmt, e)
                          }}
                        ></Checkbox>
                        {agmt.name}
                      </Grid>
                      <Grid item xs={1}>
                        {agmt.file && agmt.file.length > 0 ? (
                          <ArrowForwardIosIcon
                            fontSize='0.7em'
                            onClick={() => dgHandler[1](true)}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  )
                })}
              </Box>
              <FormHelperTexts>{errors['agmt']}</FormHelperTexts>
            </Grid>
          </Grid>
        </Paper>

        <Grid container>
          <Grid item xs={12}>
            <Button
              variant='contained'
              fullWidth
              color='primary'
              size='md'
              onClick={handleSubmit}
              sx={{ mt: 2, fontSize: '1em' }}
            >
              가입
            </Button>
          </Grid>
        </Grid>
      </FormControl>
      <div>
        {dgHandler[0] ? <RegisterUserPartAgmt record={agmtRef.current} dialogHandler={dgHandler} /> : null}
        {renderSnackbar()}
        {renderIndicator()}
        {renderAlert()}
      </div>
    </Container>
  )
}

export default RegisterUserPage
