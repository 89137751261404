import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import useAlert from "@hooks/useAlert";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';
import WebService from '../util/webService';
import useSnackbar from '../hooks/useSnackbar';
import { setAccount } from '../redux/actions/accountAction';
import { isAdmin, isStringNullOrEmpty } from '../util/helper';
import useIndicator from "@hooks/useIndicator";
import { useTheme } from '@mui/material/styles';
import { isExpired, decodeToken } from "react-jwt";
// mui의 css 우선순위가 높기때문에 important를 설정 - 실무하다 보면 종종 발생 우선순위 문제
const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;

//
const Boxs = styled(Box)`
  padding-bottom: 40px !important;
`;


const LogoutPage = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const [loginId, set_loginId] = useState('')
  const [password, set_password] = useState('')
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const dispatch = useDispatch()
  const {alert, renderAlert} = useAlert()
	const account = useSelector((state) => state.accountReducer)

  const postLogout = async ()=> {

    const deviceId = localStorage.getItem('deviceId')
    if(account?.user?.id && deviceId) {
      const url = encodeURI(`/logout/${account.user.id}`)
      const params = {
        deviceId: deviceId
      }
      const resp = await WebService.post(url, {}, params)

      if(resp.repCode !== 'ack') {
        console.log('logout failed', resp.repMessage)
      }

      localStorage.removeItem('account')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('deviceId')
    }
    navigate('/welcome/index')
  }
  
  const onLogout = async() => {
    dispatch(setAccount({}))
    postLogout()
  }

  useEffect(() => {
    onLogout()
  },[])


  return (
		<>
      
		</>
	)
};

export default LogoutPage;
