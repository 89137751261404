import React, { Fragment } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ReactLogger from 'react-terminal-logger/console-logger'
import { CssBaseline } from '@mui/material'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'
import { LoadingProvider } from '@context/LoadingContext'
import Indicator from '@components/Indicator'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { Persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Store } from './redux/store'
import { SnackbarProvider } from '@context/SnackbarContext'
// logging to terminal
// ReactLogger.start()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Fragment>
    <ScopedCssBaseline>
      <LoadingProvider>
        <SnackbarProvider>
          <Provider store={Store}>
            <PersistGate loading={null} persistor={Persistor}>
              <App />
            </PersistGate>
          </Provider>
        </SnackbarProvider>
        <Indicator />
      </LoadingProvider>
    </ScopedCssBaseline>
  </Fragment>

  // React.StrictMode 를 사용하면 하면 useEffect 두 번 실행 됨
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
)

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.getRegistrations().then(function (registrations) {
//     for (let registration of registrations) {
//       registration.unregister().then(function (boolean) {
//         if (boolean) {
//           console.log('Service Worker unregistered successfully.');
//         } else {
//           console.log('Service Worker not unregistered.');
//         }
//       });
//     }
//   });
// }

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope)
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error)
    })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
