import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import DrawerComponent from './Drawer';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { useTheme } from '@mui/material/styles';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
// redux
import { useSelector, useDispatch } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import { isRepManager } from '@util/helper';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import UpcomingOutlinedIcon from '@mui/icons-material/UpcomingOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ChairAltOutlinedIcon from '@mui/icons-material/ChairAltOutlined';
// import logo from '@images/logo-tns.png'
import logo from '@images/logo_white.png'
import Avatar from '@mui/material/Avatar';
import useStyles from '@hooks/useStyles'
import LeftDrawer from '@components/LeftDrawer';
import {GlobalStyle as gs} from '@src/GlobalStyle'
const mainColor = '#33c058'

const drawerWidth = 240;


export default function PublicDrawer2({sideMenu, title, barBgColor, sideBgColor}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
  // redux
  const codeGroups = useSelector(state => state.codeGroupReducer)
  const codes = useSelector(state => state.codeReducer)
  const schools = useSelector(state => state.schoolReducer)
  const account = useSelector(state => state.accountReducer)
  const schola = useSelector(state => state.scholaReducer)

  const [activeMenu, set_activeMenu] = React.useState({})
  const [activeIdx, set_activeIdx] = React.useState(1)

  const defIcon = <LabelOutlinedIcon size='small' sx={{color:'#212121'}}/>
  const defSx = {color:'#212121', fontSize:20}
  const primaryTypographyProps = (index) => {
    return {
      style: {
        fontSize:14, 
        fontFamily: ['JejuGothic', 'sans-serif'],
        color: activeIdx === index ? theme.palette.primary.main : '#212121',
        fontWeight:activeIdx === index ? 'bold' : 'normal'
      }

    }
  }


  const styledIcon = (menu, index, activeIdx) => {
    const icon = menu.icon ? menu.icon : defIcon
    const newIcon = React.cloneElement(icon, {
      style: {
        ...icon.props.style, 
        color: index === activeIdx ? theme.palette.primary.main : '#212121'
      }
    })

    return (
      newIcon
    )
  }



  return (
    <Box sx={{ display: 'flex',}}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: barBgColor ?? theme.palette.primary.bar }} elevation={0}>
        <Toolbar sx={{ backgroundColor:barBgColor ?? theme.palette.primary.bar}}>
          <div style={{flex:0.5, display:'flex', justifyContent:'flex-start' }}>
            {
              isMobile && sideMenu ? (
                <LeftDrawer sideMenu={sideMenu}/>
              ) :  null

            }
            <a href='https://www.schoolmanager.kr' target='_blank' className={classes.logo} style={{display:'flex', justifyContent:'center', alignItems:'center', }}>
              <img src={logo} alt='logo' style={{width: isMobile ? 20 : 25, marginRight:10, }} />
              {
                isMobile ? null :(
                  <Typography noWrap className={classes.logo} sx={{fontSize:18}} >
                    블록체인 채용 플랫폼
                  </Typography>
                )
              }
            </a>
          </div>
          
          <Typography noWrap component="div" className={classes.schoolName} style={{textAlign:'center', fontWeight:'bold', flex:1,}}>
            {schola?.name?? ''}
          </Typography>
          {
            account?.user?.id ? (
              isMobile ? (
                <div style={{display:'flex', flex:0.5, justifyContent:'flex-end' }}>
                  <DrawerComponent />
                </div> 
              ) : (
                <div style={{flex:0.5, display:'flex', justifyContent:'flex-end'}}>
                  <div className={classes.logo}></div>
                  <div className={classes.navlinks}>
                    {
                      account?.user?.name? (
                        <Link to="#" className={classes.userName} >
                          {account.user.name}님
                        </Link>
                      ) : null
                    }

                    <Link to="/profile" className={classes.link} >
                       설정
                    </Link>
                    <Link to="/logout" className={classes.link} sx={{display:'flex', alignItems:'center'}} >
                      <LogoutIcon size='small' sx={{fontSize:20}}/>
                    </Link>
                  </div>
                </div>
              )
            ) : <div style={{flex:0.5,}} /> 
          }
        </Toolbar>
      </AppBar>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
