import { SET_SCHOLA } from "../actions/scholaAction"
const initState = {}

const scholaReducer = (state = initState, action) => {
  switch(action.type) {
    case SET_SCHOLA:
      return action.payload
    default:
      return state
  }
}

export default scholaReducer