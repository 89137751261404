import React, { createContext, useContext, useRef, useState } from 'react';
import MySnackbar from '@components/MySnackbar';
import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "@util/constant";
import { publish } from "@util/event";
const SnackbarContext = createContext();

export const SnackbarProvider = ({ children}) => {
  const messageRef = useRef({msg:'', bgColor:null})

  const contextValue = {
    showSnackbar
  };

  SnackbarContext.showSnackbar= (msg, bgColor) => {
    messageRef.current.msg = msg
    messageRef.current.bgColor = bgColor?? null
    publish(SHOW_SNACKBAR)
  }

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <MySnackbar messageRef={messageRef} />
    </SnackbarContext.Provider>
  );
};

export const showSnackbar = (msg, bgColor) => {
  SnackbarContext.showSnackbar(msg, bgColor)
}
