import { useState, useEffect, Fragment } from "react";
import { Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import { Snackbar } from '@material-ui/core';
import {Snackbar} from "@mui/material";
import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "@util/constant";
import { subscribe, unsubscribe } from "@util/event";

const MySnackbar = ({messageRef}) => {
  const [open, set_open]  = useState(false)
  const [bgColor, set_bgColor] = useState(null)
  const autoHideDuration = 3000
  
  const showSnackbar = () => {
    // if(messageRef && messageRef.current && messageRef.current.msg.length> 0) {
    if(messageRef?.current?.msg?.length> 0) {
      set_open(true)
    }
    const bgColor = messageRef.current.bgColor?? null
    set_bgColor(bgColor)
  }

  const hideSnackbar = () =>{
    messageRef.current.msg = ''
    set_open(false)
  }

  const closeAction = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={hideSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  )
 
  useEffect(() => {
    if(open) {
      setTimeout(() => {
        set_open(false)
      }, autoHideDuration)
    }
  }, [open])

  useEffect(() => {
    subscribe(SHOW_SNACKBAR, showSnackbar)
    subscribe(HIDE_SNACKBAR, hideSnackbar)

    return () => {
      unsubscribe(SHOW_SNACKBAR, showSnackbar)
      unsubscribe(HIDE_SNACKBAR, hideSnackbar)
    }
  },[]) 



  return(
    <Snackbar
      open={open}
      ContentProps={{
        sx:{backgroundColor:bgColor}
      }}
      message={messageRef.current?.msg}
      action={closeAction}
    />
  )
}

export default MySnackbar
