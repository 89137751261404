import * as React from 'react';
import TextField from '@mui/material/TextField';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {useSelector} from "react-redux";
import WebService from "@util/webService";
import useAlert from "@hooks/useAlert";
import useSnackbar from "@hooks/useSnackbar";
import useIndicator from "@hooks/useIndicator";
import MyDialog from "@components/MyDialog";
import Button from "@mui/material/Button";
import MUITable from "@mui/material/Table";
import MyTableHead from "@components/MyTableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Fragment, useRef, useState} from "react";
import FlatButton from "@components/FlatButton";
import TableBody from "@mui/material/TableBody";
import {TablePagination, Typography} from "@mui/material";
import TableFooter from "@mui/material/TableFooter";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import {findCode} from "@util/helper";
import {Grid, Container} from '@mui/material';
import ConfirmDialog from "@components/ConfirmDialog";
import { useNavigate } from 'react-router-dom';

const dateFormat = 'YYYY-MM-DD HH:mm:ss'

export default function ProfileLeavePage() {
  // redux
  const account = useSelector(state => state.accountReducer);
  const [openSnackbar, renderSnackbar] = useSnackbar();
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator();
  const {alert, renderAlert} = useAlert()
  const navigate = useNavigate()
    
  const onLeaveRequest = async () => {
    const url = `/user/leave/${account?.user?.id}`
    openIndicator()
    const resp = await WebService.post(url)
    closeIndicator() 

    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
     
    alert('처리되었습니다.', () => {
      navigate('/login')
    }) 
  }

  return (
    <div >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}>
        <div style={{ paddingRight: 10 }}>
          <h1>회원 탈퇴</h1>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
      </div>

      <div style={{ paddingTop: 20 }}>
        <Container component="main" maxWidth="md">
          <Grid container spacing={2} >
            <Grid item xs={12} >
              <Typography textAlign={'center'} variant='body2' style={{fontSize:'1em'}}>
                블록체인 채용 플랫폼 탈퇴 후에는 더 이상 로그인 및 다른 기능을 사용할 수 없습니다.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <ConfirmDialog
                  btn={<Button color={'error'} variant='contained' >탈퇴하기</Button>}
                  title={'주의'}
                  content={'회원 탈퇴 하시겠습니까?'}
                  bc={'error'}
                  preHandle={ async () => {
                    return true
                  }}
                  perform={() => {
                    onLeaveRequest() 
                  }}
              />
            </Grid>
          </Grid>
        </Container>
        {renderSnackbar()}
        {renderIndicator()}
        {renderAlert()}
      </div>
    </div>
  );
}
