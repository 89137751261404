import React, {useState, useEffect, useMemo, Fragment} from 'react';

import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button'

const FlatButton = withStyles(theme => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize:'1em',
  }
}))(Button);

export default FlatButton
