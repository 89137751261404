import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useNavigate } from "react-router-dom";

// redux
import { useSelector, useDispatch } from 'react-redux';
import WebService from '@util/webService';
import { getAction, printVariable } from '@util/helper';
import { setSchola } from '@redux/actions/scholaAction';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { useParams } from 'react-router-dom';
import '@scss/App.scss'
import { isAdmin } from '@util/helper';
import { setAccount } from '@redux/actions/accountAction';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  borderBottom: '1 solid #ccc',
  boxShadow:{},
  color: theme.palette.text.secondary,
}));

// 발송된 이메일에 링크가 있는 경우 사용되는 페이지, 필요 없이 모바일의 경우 앱과 혼동 우려가 있어서 앞으로 사용하지 않음
const WelcomeEmailPage = () => {
  const dispatch = useDispatch()
  const account = useSelector(state => state.accountReducer)
  const navigate = useNavigate()
  const [certed, set_certed] = useState(false)
  
  const {emailCertId, certNum} = useParams()

  const onLoad = async() => {
    
    const url = '/public/email/cert'
    const params = {emailCertId: emailCertId, certNum: certNum}
    const resp = await WebService.get(url, params)
    if(resp.repCode === 'ack') {
      set_certed(true)
      const repMessage = JSON.parse(resp.repMessage)
      const user = JSON.parse(repMessage.record)
      if(account.user) {
        if(+account.user.id === +user.id) {
          const newAccount = {...account}
          newAccount.user = user
          dispatch(setAccount(newAccount))
          setTimeout(() => {
            navigate('/welcome/index')
          }, 1000)
        }
      }
      else {
        setTimeout(() => {
          navigate('/login')
        }, 1000)
      }
    }
  }
  useEffect(() => {
    onLoad()
  },[])

  return (
    <Box sx={{minHeight: "100vh", display:'flex',flexGrow: 1, justifyContent:'center', alignItems:'center', }}>
      {certed?
        <div>이메일 인증되었습니다.</div>
        : 
        <div>이메일 인증에 문제가 발생했습니다.</div>
      }
    </Box>
  )
}

export default WelcomeEmailPage
