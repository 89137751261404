import React, { createContext, useContext, useRef, useState } from 'react';
import useIndicator from '@hooks/useIndicator';
const LoadingContext = createContext();

export const LoadingProvider = ({ children}) => {
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const contextValue = {
    setLoading
  };

  LoadingContext.setLoading = (v) => {
    if(v) {
      openIndicator()
    }
    else {
      closeIndicator()
    }
  }

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const setLoading = (v) => {
  LoadingContext.setLoading(v)
}
