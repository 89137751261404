import * as React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Link, useNavigate } from 'react-router-dom'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import TextField from '@mui/material/TextField'
// redux
import { useSelector, useDispatch } from 'react-redux'
import WebService from '@util/webService'
import { emailRegex, getAction, printVariable } from '@util/helper'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'

import '@scss/App.scss'
import { isAdmin } from '@util/helper'
import { Avatar, CardHeader, Container, FormHelperText, IconButton, Divider } from '@mui/material'
import ConfirmDialog from '@components/ConfirmDialog'
import useIndicator from '@hooks/useIndicator'
import useAlert from '@hooks/useAlert'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { setActiveSideIndex } from '@redux/actions/activeSideIndexAction'
import { companyPhone } from '@util/string'
import { showSnackbar } from '@context/SnackbarContext'
import { setCodes } from '@redux/actions/codeAction'
import { genMenu } from '@util/helper'
import ArticleOutlined from '@mui/icons-material/ArticleOutlined'
import { prss } from '@util/string'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  borderBottom: '1 solid #ccc',
  boxShadow: {},
  color: theme.palette.text.secondary,
}))

const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const CardFace = ({ title }) => {
  const theme = useTheme()
  return (
    <CardContent
      style={{
        backgroundColor: gs.colors.cardBg,
        display: 'flex',
        justifyContent: 'center',
        borderBottom: '1px solid silver',
      }}
    >
      <Typography color='#616161' style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
    </CardContent>
  )
}

const CircleButton = ({ Icon, text, onClick }) => {
  const theme = useTheme()
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Button
        onClick={onClick}
        variant='outlined'
        color='primary'
        startIcon={
          // <Icon
          <ArticleOutlined
            style={{
              fontSize: 40,
              borderWidth: 1,
              color: theme.palette.secondary.light,
            }}
          />
        }
        sx={{
          borderRadius: '50%',
          width: 65,
          height: 65,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          '& .MuiButton-startIcon': {
            margin: 'auto', // Center the start icon horizontally and vertically
          },
          borderWidth: 3,
          '&:hover': {
            borderWidth: 3, // Border width when focused
          },
        }}
      />
      <Typography style={{ fontSize: '0.9em' }}>{text}</Typography>
    </div>
  )
}

const WelcomeIndexPage = () => {
  const [tabValue, set_tabValue] = useState('1')

  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const handleTabChange = (event, newValue) => {
    set_tabValue(newValue)
  }
  const [semuMenu, set_semuMenu] = useState([])
  const [restMenu, set_restMenu] = useState([])
  const [orders, set_orders] = useState([])
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const statCodes = codes.filter(r => r.codeGroupId === 'C002')
  const fetchCodes = async () => {
    const url = '/code'
    const resp = await WebService.get(url)
    if (resp.repCode != 'ack') {
      console.log('error=>', resp.repMessage)
    }
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    records.forEach((item) => {
      if (item.code === 'DG08') {
        item.prss1 = prss.long
        item.prss2 = prss.long
      } else if (item.code === 'DG11') {
        item.prss1 = prss.am
        item.prss2 = prss.pm
      } else {
        if (item.code.startsWith('DS')) {
          item.prss1 = prss.am
          item.prss2 = prss.pm
        } else {
          item.prss1 = prss.short
          item.prss2 = prss.short
        }
      }
    })
    return records
  }

  const fetchOrders = async () => {
    const url = `/order/admin`
    const resp = await WebService.get(url)
    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    return records.take(10)
  }

  useEffect(() => {
    if (account && account?.user?.emailCertYn === 'N') {
      console.log('navigate account=>', account)
      navigate('/welcome/ecert')
    }
  }, [account])

  const onLoad = async () => {
    const codes = await fetchCodes()
    if(codes) {
      dispatch(setCodes(codes))
    }

    const orders = await fetchOrders()
    if(orders) {
      set_orders(orders)
    }
  }

  useEffect(() => {
    const semuMenu = genMenu(codes, 'D001', 'DS', `/ask/semu`)
    const restMenu = genMenu(codes, 'D001', 'DG', `/ask/rest`)
    set_semuMenu(semuMenu)
    set_restMenu(restMenu)
  }, [codes])

  useEffect(() => {
    onLoad()
  }, [])

  return (
    <>
      <div style={{ backgroundColor: '#eee', paddingTop: isMobile ? 50 : 100, paddingBottom: 50 }}>
        <Container maxWidth='lg' style={{ display: isMobile && tabValue === '2' ? 'none' : null }}>
          <Grid container spacing={2} style={{}}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {semuMenu.map((item, idx) => (
                  <Grid item xs={3} md={2} key={idx}>
                    <CircleButton
                      Icon={item.icon}
                      text={item.label}
                      onClick={() => {
                        dispatch(setActiveSideIndex(item.id))
                        navigate(item.to)
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {restMenu.map((item, idx) => (
                  <Grid item xs={3} md={2} key={idx}>
                    <CircleButton
                      Icon={item.icon}
                      text={item.label}
                      onClick={() => {
                        dispatch(setActiveSideIndex(item.id))
                        navigate(item.to)
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth={'lg'} style={{ marginTop: 25 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            {/* <Card> */}
            {/*   <CardFace title='민원 처리결과' /> */}
            {/*   <CardContent> */}
            {/*     <TextField fullWidth label='신청인 성명'></TextField> */}
            {/*     <TextField fullWidth label='비밀번호' style={{ marginTop: 10 }}></TextField> */}
            {/*     <Button fullWidth variant='contained' size='large' style={{ marginTop: 10 }}> */}
            {/*       확인 */}
            {/*     </Button> */}
            {/*   </CardContent> */}
            {/* </Card> */}
            {/* <Card style={{ marginTop: 10 }}> */}
            {/*   <CardFace title='업무시간' /> */}
            {/*   <CardContent> */}
            {/*     <Typography>요금 및 처리시간</Typography> */}
            {/*   </CardContent> */}
            {/* </Card> */}
            <Card style={{ marginTop: 10 }}>
              <CardFace title='무통장입금 계좌안내' />
              <CardContent>
                <Typography>예금주: 손미희</Typography>
                <Typography>케이뱅크 100-210-735873</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 'bold' }}>실시간 신청 내역</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md>
                {orders.map((item, idx) => (
                  <Grid container spacing={1}>
                    <Grid item xs={5} md={5}>
                      {item.orderNo}
                    </Grid>
                    <Grid item xs={3} md={3}>
                      {docCodes.find((r) => r.code === item.docs[0].docCode).name}
                    </Grid>
                    <Grid item xs md>
                      {item.name.slice(0, -1) + 'X'}
                    </Grid>
                    <Grid item xs md>
                      {statCodes.find((r) => r.code === item.docs[0].statCode).name}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card>
              <CardFace title='콜센터' />
              <CardContent>
                <Typography>전화 {companyPhone}</Typography>
                <Typography>평일 09:00 ~ 18:00</Typography>
                <Typography>공휴일 휴무</Typography>
              </CardContent>
            </Card>
            {/* <Card style={{ marginTop: 10 }}> */}
            {/*   <CardFace title='민원처리 결과' /> */}
            {/*   <CardContent> */}
            {/*     <Typography>신청하신 민원을 확인 하실 수 있습니다.</Typography> */}
            {/*   </CardContent> */}
            {/* </Card> */}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default WelcomeIndexPage
