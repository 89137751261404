import * as React from 'react';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
// import { Backdrop } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import { withStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { useLoading } from '@context/LoadingContext';
// import { withStyles } from '@material-ui/core';
import {subscribe, unsubscribe} from '@util/event'
import { SHOW_LOADING, HIDE_LOADING } from '@util/constant';
import { useTheme } from '@mui/material/styles';

const MyBackdrop = withStyles(theme => ({
  root: {
    zIndex: 5000,
    // backgroundColor:'rgba(0,0,0,0)'
    backgroundColor:'transparent !important'
  }
}))(Backdrop);


const Indicator = () => {
  // const {loading} = useLoading()
  const [open, set_open] = useState(false)
  const theme = useTheme()
  const showLoading = () => {
    set_open(true)
  }
  const hideLoading = () => {
    set_open(false)
  }

  React.useEffect(() => {
    subscribe(SHOW_LOADING, showLoading) 
    subscribe(HIDE_LOADING, hideLoading)

    return () =>  {
      unsubscribe(SHOW_LOADING, showLoading) 
      unsubscribe(HIDE_LOADING, hideLoading)
    }

  },[])
  

  return (
    <MyBackdrop
      open={open}
    >
      <Stack gap={1} justifyContent="center" alignItems="center">
        <CircularProgress style={{color:theme.palette.primary}} />
      </Stack>
    </MyBackdrop>
  );
}

export default Indicator
