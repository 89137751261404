import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Fragment} from "react";
import {printVariable} from "@util/helper";

const ConfirmDialog = React.memo(({btn, title='주의', content, bc='warning', preHandle, perform}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    if(preHandle) {
      const r = await preHandle()
      if(r === true) {
        setOpen(true);
      }
    }
    else {
      setOpen(true)
    }
  };


  const handleClose = (event, reason)=> {
    if(reason && reason === "backdropClick") {
      return
    }
    else {
      setOpen(false)
    }
  }


  const BtnWithOnClick = () => {
    return btn ? (
      React.cloneElement(btn, {
        onClick: () => {
          if(btn.props.onClick) {
            btn.props.onClick()
          }
          handleClickOpen()
        }
      })
    ) : (
      <Button variant="contained" size="small" color="warning" onClick={handleClickOpen}>
        {'...'}
      </Button>
    );
  };

  return (
    <Fragment>
      <BtnWithOnClick />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'xs'}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{whiteSpace:'pre-line'}}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>닫기</Button>
          <Button variant='contained' color={bc} onClick={() => {
            if(perform) {
              perform()
            }
            handleClose()
          }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
})

export default ConfirmDialog
